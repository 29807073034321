// ***************************************************************************************
// Twitter Bootstrap 3
@import (reference) "../../../../libs/bootstrap-3.4.1/less/variables.less";
@import (reference) "../../../../libs/bootstrap-3.4.1/less/mixins.less";

// Shared Variables and Mixins
@import (reference) "../../shared/_variables.less";
@import (reference) "../../shared/_mixins.less";

// Hubs-Shared Variables and Mixins
@import (reference) "../../hubs/_variables.less";
@import (reference) "../../hubs/_mixins.less";

// Backend-Shared Variables and Mixins
@import (reference) "../_variables.less";
@import (reference) "../_mixins.less";

// ***************************************************************************************

.mgmt-container .content-container.hubs-section-item-editor {
  margin-bottom: 60px;

  // Title Edit

  .title-edit {
    position: relative;
    .size(100%, 40px);

    padding: 5px 1%;
    margin-top: 10px;
    font-size: 26px;
  }

  .item-edit-url {
    max-width: 74%;
  }
  // Links beside URL (below Title edit)

  .item-edit-links {
    a:link,
    a:visited,
    a:active,
    a:hover,
    a:focus {
      margin-left: 23px;
      color: @gray;
      position: relative;

      .icon-embed,
      .icon-share-prong {
        margin-top: 2px;
      }

      .halflings {
        top: 2px;
        opacity: 0.6;
      }

      .glyphicons {
        top: 50%;
        position: absolute;
        right: ~"calc(100% + 2px)"; // Less is weird about calc, it wants to add the numbers - just print them as is instead.
        transform: translateY(-50%);

        &.glyphicons-embed {
          right: ~"calc(100% + 5px)";
        }

        &:before {
          padding: 0;
        }
      }
    }

    a:hover {
      color: @gray-darker;
    }
  }

  // Social User Avatar/Name

  .social-user {
    padding: 10px;
    margin-top: 10px;

    > img.avatar {
      float: left;
      .size(auto, 48px);

      margin-right: 5px;
      .border-radius(4px);
    }

    > div.user-name {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 24px;
      font-weight: normal;
      line-height: 25px;
      .text-overflow();
    }
  }

  .tab-content {
    .tab-pane {
      // Panel Containers

      .panel-container {
        margin-top: 0;

        .panel-body {
          min-height: 350px;

          .halflings {
            color: @gray;
            font-size: @font-size-base;
          }

          > .panel-body-group {
            clear: none;
            min-width: 400px;

            > label:not(.btn) {
              width: 30%;

              span {
                padding-left: 8px;
                font-weight: normal;
                color: @uberGrayLight; // #999;
                letter-spacing: 0;
              }
            }

            .btn[data-action='image-manager'] {
              margin-top: -6px;
            }

            .panel-input-wrapper {
              display: inline-block;
              width: 100%;

              &.short {
                max-width: 350px;
              }

              > input[type=text] {
                width: 100%;
              }
            }

            &.with-btn {
              .panel-input-wrapper {
                width: 88%;
              }
            }
          }
        }
      }

      .tile-img-actions {
        margin-top: 10px;
      }

      .tile-img-upload-container {
        display: inline-block;
        margin: 2px 0 0;
        vertical-align: top;

        .tile-img-input {
          visibility: hidden;
          width: 1px;
          height: 0;
        }

        .tile-img-upload {
          span.halflings {
            color: #fff;
          }
        }
      }

      .tile-img-actions {
        display: none;
      }
    }

    .item-tags-wrapper {
      .tagsinput {
        border-radius: @input-border-radius;
      }
    }
  }

  #single-item-performance-tab {
    iframe {
      height: 800px;
      width: 100%;
      border: none;
      margin-top: -40px;
    }
  }

  // Hamburger Menu beside Tabs

  .edit-menu {
    .dropdown-toggle {
      position: relative;
      display: block;
      .size(32px, 26px);

      padding: 0;
      .border-radius(0);
      .box-shadow(none);

      .glyphicons {
        font-size: 18px;
      }
    }

    &.open .glyphicons {
      color: @uberBlue;
    }

    .dropdown-menu {
      background-color: @white;
      border: 1px solid darken(@gray-lighter, 13.5%);  // #ccc
      .box-shadow(none);
      .border-radius(0);

      li {
        a:link,
        a:visited,
        a:active,
        a:hover {
          opacity: 0.8;

          &.disabled {
            opacity: 0.3;
          }
        }

        a:hover {
          color: @white;
          background-color: @uberBlueLight;
          opacity: 1;

          .icon-gray { display: inline-block; }
        }
      }
    }
  }

  input.datefield {
    width: 160px;
  }

  textarea {
    width: 100%;
    height: 60px;
  }

  label.btn {
    margin: 0;
  }

  .item-group {
    display: block;
    float: none;
    margin: 15px auto 0 auto;
  }

  .panel-body {
    &.checkered {
      overflow: hidden;

      > div.tile.single {
        margin: 10px auto !important;
        float: none !important;
        pointer-events: none;
        cursor: default;
        z-index: 0;

        &.cropping {
          pointer-events: initial;
          display: none;
        }
      }
    }
  }

  .custom-script-wrapper {
    margin-bottom: 0;

    .nav-block-icon {
      margin-bottom: 0;
    }
  }

  .toggle-custom-script-wrapper {
    margin-bottom: 20px;

    label {
      display: inline-block;
    }
  }

  .item-editor-footer {
    position: relative;
    .size(100%, 46px);

    padding: 9px 0;
    margin-bottom: 0;
    background-color: lighten(@gray, 6.5%);
    text-align: center;

    .item-editor-footer-inner {
      .btn {
        margin-right: 20px;
      }

      .draft-status {
        .pos-abs(15px, 20px);   // T, R, B, L

        color: @gray-lighter;
        font-size: 14px;

        img,
        .icon-white,
        .halflings {
          margin: 0 7px 0 0;
        }

        .status-saved,
        .status-saving,
        .status-error { display: none; }

        &.saving {
          .status-saving { display: block; }
        }

        &.saved {
          .status-saved { display: block; }
        }

        &.error {
          .status-error { display: block; }
        }
      }
    }

    &.fixed {
      .pos-fix(auto, auto, 0, 0);     // T, R, B, L

      z-index: 8;

      .item-editor-footer-inner {
        margin-left: @sidemenuWidthOpened;

        #published-at-opt {
          display: inline-block;
          width: 300px;

          #update-item-publish-date {
            float: left;
            display: block;
            margin-right: 5px;
          }

          label {
            margin: 4px 0 0 0;
            color: white;
            font-weight: normal;
            font-size: 12px;
          }
        }
      }
    }
  }

  .media-alert {
    background: darken(@gray-lighter, 13.5%);
    color: @white;

    > div {
      width: 80%;
      padding: 6px;
      margin: 10px auto;
      background: lighten(@gray-light, 13.2%);
      text-align: center;
      .border-radius(20px);
    }
  }
}

.author-avatar-tiny {
  display: inline-block;
  .square(30px);
  .border-radius(50%);

  margin-right: 5px;
  background-size: cover;
  vertical-align: middle;
}

.select2-chosen > div.author-avatar-tiny {
  .square(21px);

  margin-top: -3px;
}

.atomic-reach {
  .panel-container > .panel-body {
    max-height: 744px;
    min-height: 744px;
    overflow: auto;
  }
}

/* .hub-item-url {
    width: auto;
    padding-bottom: 30px;

    > span {
        display: inline-block;
        position: relative;
        top: 5px;
        max-width: 72%;
        margin: 0 10px;
 }
 } */

.btn-custom-code.disabled {
  cursor: not-allowed;

  #add-custom-code.disabled {
    pointer-events: none;
  }
}

.disabled,
table.table.api-dt tbody > tr td.api-data-table-counts.inline-edit-text.disabled span.priority-text,
.disabled-btn-wrapper {
  cursor: not-allowed;
}

.uf-toggle-switch-container.disabled {
  cursor: not-allowed;

  .uf-toggle-switch {
    pointer-events: none;
  }
}

