.truncate {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.focus-outline {
  outline: 0;
  box-shadow: 0 0 8px #5bc0de;
}
.absolute-center {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.fixed-center {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.off-screen {
  position: absolute;
  top: -1000px;
  left: -1000px;
  right: auto;
  bottom: auto;
}
.reset-box-sizing,
.reset-box-sizing *,
.reset-box-sizing *:before,
.reset-box-sizing *:after,
.reset-box-sizing:before,
.reset-box-sizing:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
@supports (overflow:-webkit-marquee) and (justify-content: inherit) {
  .overlay-scroller {
    -webkit-overflow-scrolling: auto !important;
  }
}
.hardware-accelerate {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -o-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -o-perspective: 1000;
  -ms-perspective: 1000;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  perspective: 1000;
}
html.layout-bs3 {
  /* Items in Carousel should have a fixed size */
  /* Hub/Stream Embed Tiles */
}
html.layout-bs3 .share-hub,
html.layout-bs3 .share-item {
  display: none;
  position: fixed;
  top: 61px;
  right: 40px;
  padding: 0;
  margin: 0 128px 0 0;
  min-width: 140px;
  max-width: 230px;
  background-color: #fff;
  text-transform: uppercase;
  -webkit-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}
html.layout-bs3 .share-hub.hover,
html.layout-bs3 .share-item.hover {
  display: block;
}
html.layout-bs3 .share-hub:before,
html.layout-bs3 .share-item:before {
  display: block;
  position: absolute;
  top: -20px;
  right: 10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #e5e5e5;
  border-left: 10px solid transparent;
  content: "";
}
html.layout-bs3 .share-hub li,
html.layout-bs3 .share-item li {
  display: block;
  float: left;
  color: #aaa;
  text-shadow: 1px 1px 2px #fff;
  font-size: 16px;
  height: 100%;
  line-height: 40px;
  text-align: center;
}
html.layout-bs3 .share-hub li a,
html.layout-bs3 .share-item li a {
  display: block;
  width: 55px;
  height: 100%;
  background-image: url("/img/hubs/sprite-1x.png");
  text-indent: -9999px;
}
html.layout-bs3 .share-hub li a.facebook,
html.layout-bs3 .share-item li a.facebook {
  background-position: -55px -333px;
}
html.layout-bs3 .share-hub li a.facebook:hover,
html.layout-bs3 .share-item li a.facebook:hover,
html.layout-bs3 .share-hub li a.facebook.on,
html.layout-bs3 .share-item li a.facebook.on {
  background-position: -55px -374px;
}
html.layout-bs3 .share-hub li a.twitter,
html.layout-bs3 .share-item li a.twitter {
  background-position: -110px -333px;
}
html.layout-bs3 .share-hub li a.twitter:hover,
html.layout-bs3 .share-item li a.twitter:hover,
html.layout-bs3 .share-hub li a.twitter.on,
html.layout-bs3 .share-item li a.twitter.on {
  background-position: -110px -374px;
}
html.layout-bs3 .share-hub li a.email,
html.layout-bs3 .share-item li a.email {
  background-position: -165px -333px;
}
html.layout-bs3 .share-hub li a.email:hover,
html.layout-bs3 .share-item li a.email:hover,
html.layout-bs3 .share-hub li a.email.on,
html.layout-bs3 .share-item li a.email.on {
  background-position: -165px -374px;
}
html.layout-bs3 .share-hub li a.linkedin,
html.layout-bs3 .share-item li a.linkedin {
  background-position: -275px -333px;
}
html.layout-bs3 .share-hub li a.linkedin:hover,
html.layout-bs3 .share-item li a.linkedin:hover,
html.layout-bs3 .share-hub li a.linkedin.on,
html.layout-bs3 .share-item li a.linkedin.on {
  background-position: -275px -374px;
}
html.layout-bs3 .share-hub li a.on:hover,
html.layout-bs3 .share-item li a.on:hover {
  background-color: #efefef;
}
html.layout-bs3 .share-hub li:first-child,
html.layout-bs3 .share-item li:first-child {
  display: block;
  width: 100%;
  padding: 0px !important;
  border: 0 none;
  background: #e5e5e5;
}
html.layout-bs3 .share-social,
html.layout-bs3 .share-twitter,
html.layout-bs3 .share-slideshare {
  position: absolute;
  top: 11px;
  right: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  background-color: #f9f9f9;
  border: 1px solid #e1e1e1;
}
html.layout-bs3 .share-social li,
html.layout-bs3 .share-twitter li,
html.layout-bs3 .share-slideshare li {
  border-right: 1px solid #eee;
  border-left: 1px solid #fff;
  color: #a2a2a2;
  display: block;
  float: left;
  font-size: 16px;
  line-height: 40px;
  height: 100%;
  text-align: center;
  padding: 0;
  margin: 0;
}
html.layout-bs3 .share-social li a,
html.layout-bs3 .share-twitter li a,
html.layout-bs3 .share-slideshare li a {
  background-image: url("/img/hubs/sprite-1x.png");
  display: block;
  height: 100%;
  text-indent: -9999px;
  width: 55px;
}
html.layout-bs3 .share-social li a.facebook,
html.layout-bs3 .share-twitter li a.facebook,
html.layout-bs3 .share-slideshare li a.facebook {
  background-position: -55px -333px;
}
html.layout-bs3 .share-social li a.facebook:hover,
html.layout-bs3 .share-twitter li a.facebook:hover,
html.layout-bs3 .share-slideshare li a.facebook:hover {
  background-position: -55px -373px;
}
html.layout-bs3 .share-social li a.twitter,
html.layout-bs3 .share-twitter li a.twitter,
html.layout-bs3 .share-slideshare li a.twitter {
  background-position: -110px -333px;
}
html.layout-bs3 .share-social li a.twitter:hover,
html.layout-bs3 .share-twitter li a.twitter:hover,
html.layout-bs3 .share-slideshare li a.twitter:hover {
  background-position: -110px -373px;
}
html.layout-bs3 .share-social li a.email,
html.layout-bs3 .share-twitter li a.email,
html.layout-bs3 .share-slideshare li a.email {
  background-position: -165px -333px;
}
html.layout-bs3 .share-social li a.email:hover,
html.layout-bs3 .share-twitter li a.email:hover,
html.layout-bs3 .share-slideshare li a.email:hover {
  background-position: -165px -373px;
}
html.layout-bs3 .share-social li a.linkedin,
html.layout-bs3 .share-twitter li a.linkedin,
html.layout-bs3 .share-slideshare li a.linkedin {
  background-position: -275px -333px;
}
html.layout-bs3 .share-social li a.linkedin:hover,
html.layout-bs3 .share-twitter li a.linkedin:hover,
html.layout-bs3 .share-slideshare li a.linkedin:hover {
  background-position: -275px -373px;
}
html.layout-bs3 .share-social li.share-text,
html.layout-bs3 .share-twitter li.share-text,
html.layout-bs3 .share-slideshare li.share-text,
html.layout-bs3 .share-social li:first-child,
html.layout-bs3 .share-twitter li:first-child,
html.layout-bs3 .share-slideshare li:first-child {
  border-left: 0;
  padding: 0px 14px;
}
html.layout-bs3 .share-social li:last-child,
html.layout-bs3 .share-twitter li:last-child,
html.layout-bs3 .share-slideshare li:last-child {
  border-right: 0;
}
html.layout-bs3 .share-social a,
html.layout-bs3 .share-twitter a {
  color: #a2a2a2;
  background-image: url("/img/hubs/sprite-1x.png");
}
html.layout-bs3 .share-social a.view,
html.layout-bs3 .share-twitter a.view {
  text-indent: 0;
  background-image: none;
  width: auto;
}
html.layout-bs3 .share-social a.reply,
html.layout-bs3 .share-twitter a.reply {
  background-position: 0px -254px;
}
html.layout-bs3 .share-social a.reply:hover,
html.layout-bs3 .share-twitter a.reply:hover {
  background-position: 0px -295px;
}
html.layout-bs3 .share-social a.retweet,
html.layout-bs3 .share-twitter a.retweet {
  background-position: -57px -254px;
}
html.layout-bs3 .share-social a.retweet:hover,
html.layout-bs3 .share-twitter a.retweet:hover {
  background-position: -57px -293px;
}
html.layout-bs3 .share-social a.fav,
html.layout-bs3 .share-twitter a.fav {
  background-position: -114px -256px;
}
html.layout-bs3 .share-social a.fav:hover,
html.layout-bs3 .share-twitter a.fav:hover {
  background-position: -114px -295px;
}
html.layout-bs3 .share-single {
  display: block;
  position: absolute !important;
  width: 88% !important;
  height: 40px !important;
  bottom: 55px !important;
  margin: 0 6% !important;
  padding: 0px !important;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
html.layout-bs3 .share-single.four {
  width: 74% !important;
  margin: 0 13% !important;
}
html.layout-bs3 .share-single.four li {
  width: 25% !important;
}
html.layout-bs3 .share-single.three {
  width: 60% !important;
  margin: 0 20% !important;
}
html.layout-bs3 .share-single.three li {
  width: 33.33333333333% !important;
}
html.layout-bs3 .share-single.two {
  width: 40% !important;
  margin: 0 30% !important;
}
html.layout-bs3 .share-single.two li {
  width: 50% !important;
}
html.layout-bs3 .share-single.one {
  width: 20% !important;
  margin: 0 40% !important;
}
html.layout-bs3 .share-single.one li {
  width: 100% !important;
}
html.layout-bs3 .share-single.zero {
  display: none;
}
html.layout-bs3 .share-single li {
  display: block !important;
  float: left !important;
  height: 100% !important;
  width: 20% !important;
  padding: 0px !important;
  margin: 0 !important;
  text-indent: -9999px !important;
  background: #fff !important;
  border-right: 1px solid #eee;
  border-left: 1px solid white;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html.layout-bs3 .share-single li:first-child {
  border-left: 1px solid #eee;
}
html.layout-bs3 .share-single li:last-child {
  border-right: 1px solid #eee;
}
html.layout-bs3 .share-single li a {
  display: block !important;
  height: 100% !important;
  text-indent: -9999px !important;
}
html.layout-bs3 .share-single li a.facebook {
  background: url("/img/hubs/button-facebook.png") no-repeat center 0;
}
html.layout-bs3 .share-single li a.facebook:hover {
  background-position: center -40px;
}
html.layout-bs3 .share-single li a.twitter {
  background: url("/img/hubs/button-twitter.png") no-repeat center 0;
}
html.layout-bs3 .share-single li a.twitter:hover {
  background-position: center -40px;
}
html.layout-bs3 .share-single li a.email {
  background: url("/img/hubs/button-email.png") no-repeat center 0;
}
html.layout-bs3 .share-single li a.email:hover {
  background-position: center -40px;
}
html.layout-bs3 .share-single li a.linkedin {
  background: url("/img/hubs/button-linkedin.png") no-repeat center 0;
}
html.layout-bs3 .share-single li a.linkedin:hover {
  background-position: center -40px;
}
html.layout-bs3 .share-single li a.reply {
  background: url("/img/hubs/button-reply.png") no-repeat center 0;
}
html.layout-bs3 .share-single li a.reply:hover {
  background-position: center -41px;
}
html.layout-bs3 .share-single li a.retweet {
  background: url("/img/hubs/button-retweet.png") no-repeat center 0;
}
html.layout-bs3 .share-single li a.retweet:hover {
  background-position: center -41px;
}
html.layout-bs3 .share-single li a.fav {
  background: url("/img/hubs/button-fav.png") no-repeat center 0;
}
html.layout-bs3 .share-single li a.fav:hover {
  background-position: center -41px;
}
html.layout-bs3 .icon,
html.layout-bs3 .left-nav > li > a {
  background-image: url("/img/hubs/sprite-1x.png");
}
html.layout-bs3 .left-nav > li.home > a,
html.layout-bs3 .left-nav > li.menu-home > a {
  background-position: -224px 0;
}
html.layout-bs3 .tile.docs .icon,
html.layout-bs3 .left-nav > li.docs > a,
html.layout-bs3 .left-nav > li.menu-docs > a {
  background-position: -224px -59px;
}
html.layout-bs3 .tile.blogs .icon,
html.layout-bs3 .left-nav > li.blogs > a,
html.layout-bs3 .left-nav > li.menu-blogs > a {
  background-position: -224px -116px;
}
html.layout-bs3 .tile.videos .icon,
html.layout-bs3 .left-nav > li.videos > a,
html.layout-bs3 .left-nav > li.menu-videos > a {
  background-position: -224px -176px;
}
html.layout-bs3 .tile.social .icon,
html.layout-bs3 .left-nav > li.social > a,
html.layout-bs3 .left-nav > li.menu-social > a {
  background-position: -224px -233px;
}
html.layout-bs3 .tile.custom .icon,
html.layout-bs3 .left-nav > li.custom > a,
html.layout-bs3 .left-nav > li.menu-custom > a {
  background-position: -331px 0px;
}
html.layout-bs3 .related-container .tile.single .icon {
  display: none;
}
html.layout-bs3 .tile.uberflip .icon,
html.layout-bs3 .left-nav > li.uberflip > a {
  background-position: -224px -59px;
}
html.layout-bs3 .tile.blogpost .icon,
html.layout-bs3 .left-nav > li.blogpost > a {
  background-position: -224px -116px;
}
html.layout-bs3 .tile.vimeo .icon,
html.layout-bs3 .left-nav > li.vimeo > a {
  background-position: -224px -176px;
}
html.layout-bs3 .tile.youtube .icon,
html.layout-bs3 .left-nav > li.youtube > a {
  background-position: -224px -176px;
}
html.layout-bs3 .tile.vidyard .icon,
html.layout-bs3 .left-nav > li.youtube > a {
  background-position: -224px -176px;
}
html.layout-bs3 .tile.twitter .icon,
html.layout-bs3 .left-nav > li.twitter > a {
  background-position: -224px -233px;
}
html.layout-bs3 .tile.instagram .icon,
html.layout-bs3 .left-nav > li.instagram > a {
  background-position: -224px -233px;
}
html.layout-bs3 .tile .icon.star {
  background-position: -282px -284px;
  opacity: 0.7;
  border-radius: 50%;
}
html.layout-bs3 div.tile.single > a.view,
html.layout-bs3 li.tile.single > a.view {
  padding-left: 45px;
  background-image: url("/img/hubs/sprite-1x.png");
}
html.layout-bs3 .tile.single.uberflip > a.view {
  background-position: -385px -5px;
}
html.layout-bs3 .tile.single.twitter > a.view {
  background-position: -385px -55px;
}
html.layout-bs3 .tile.single.instagram > a.view {
  background-position: -385px -505px;
}
html.layout-bs3 .tile.single.youtube > a.view {
  background-position: -800px -800px;
}
html.layout-bs3 .tile.single.vimeo > a.view {
  background-position: -385px -255px;
}
html.layout-bs3 .tile.single.vidyard > a.view {
  background-position: -385px -753px;
}
html.layout-bs3 .tile.single.brightcove > a.view {
  background-position: -385px -753px;
}
html.layout-bs3 .tile.single.blogpost > a.view {
  background-position: -385px -305px;
}
html.layout-bs3 .tile.single.pinterest > a.view {
  background-position: -385px -455px;
}
html.layout-bs3 .tile.single.slideshare > a.view {
  background-position: -385px -587px;
}
html.layout-bs3 .tile.single.facebook > a.view {
  background-position: -385px -105px;
}
html.layout-bs3 .tile.single.wistiar > a.view {
  background-position: -385px -753px;
}
html.layout-bs3 .tile {
  position: relative ;
  float: left ;
  width: 250px;
  height: 330px;
  padding: 0px ;
  margin: 0 ;
  margin-right: 20px ;
  margin-bottom: 20px ;
  line-height: 1.5em ;
  background: #fff;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden ;
}
html.layout-bs3 .tile .img {
  float: none ;
  position: absolute ;
  left: 0 ;
  top: 0 ;
  width: 100% ;
  height: 41.09947644% ;
  padding: 0px ;
  margin: 0 ;
  opacity: 0;
  -webkit-transition: opacity 400ms;
  -o-transition: opacity 400ms;
  transition: opacity 400ms;
}
html.layout-bs3 .tile .img.visible {
  opacity: 1;
}
html.layout-bs3 .tile .img .duration {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: #000000 transparent;
  background: rgba(0, 0, 0, 0.6);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#99000000', endColorstr='#99000000');
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000)";
  border-radius: 4px;
  color: white;
  font-size: 13px;
  padding: 5px;
  line-height: 6px;
}
html.layout-bs3 .tile .img img {
  float: none ;
  width: 100% ;
  height: auto ;
  max-width: none;
  padding: 0px ;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0 ;
  margin-bottom: 0 ;
}
html.layout-bs3 .tile .img img.shorter {
  height: 100% ;
  width: auto ;
}
html.layout-bs3 .tile .img-for-croppie {
  position: relative;
  width: 100%;
  height: 41.09947644%;
}
html.layout-bs3 .tile.single .icon {
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 48px;
  height: 48px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
}
html.layout-bs3 .tile.single > a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent url(data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==) no-repeat 0 0;
}
html.layout-bs3 .tile.single > a.view {
  top: auto;
  bottom: 0;
  width: 100%;
  height: 40px;
  color: #333;
  font-size: 16px;
  line-height: 40px;
  padding-left: 20px;
  background-color: #fcfcfc;
  border-top: 1px solid #eee;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html.layout-bs3 .tile.single > a.view:before {
  display: block;
  width: 100%;
  height: 20px;
  margin-left: -40px;
  padding-right: 20px;
  margin-top: -21px;
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#80ffffff', GradientType=0);
  background-repeat: repeat-x;
  background-color: transparent;
  content: "";
  z-index: 1;
}
html.layout-bs3 .tile.single > a.view:after {
  display: block;
  position: absolute;
  top: 13px;
  right: 17px;
  width: 16px;
  height: 16px;
  background: transparent url("/img/hubs/sprite-1x.png") left top no-repeat;
  content: "";
}
html.layout-bs3 .tile.single .description {
  background-color: #fff ;
  height: 58.90052356% ;
}
html.layout-bs3 .tile.single .description h3 {
  color: #555 ;
  font-weight: bold ;
  margin: 5px 20px 5px 20px ;
  font-size: 19px ;
}
html.layout-bs3 .tile.single .description h3.no-timestamp {
  padding-top: 12px;
}
html.layout-bs3 .tile.single .description h3.no-timestamp.avatar {
  padding-top: 0;
}
html.layout-bs3 .tile.single .description h4 {
  color: #999 ;
  font-size: 18px ;
  font-weight: 400 ;
  margin: 20px ;
}
html.layout-bs3 .tile.single .description h4 em {
  font-style: normal ;
}
html.layout-bs3 .tile.single .description h3 + h4 {
  margin: 0 20px ;
  font-weight: 300 ;
}
html.layout-bs3 .tile.single .description div.h3like + h4 {
  margin: 0 20px ;
  font-weight: 300 ;
}
html.layout-bs3 .tile.single .description div.h3like {
  color: #555 ;
  font-weight: bold ;
  margin: 5px 20px 5px 20px ;
  font-size: 19px ;
  line-height: 1.3 ;
}
html.layout-bs3 .tile.single .description div.h3like.no-timestamp {
  padding-top: 12px;
}
html.layout-bs3 .tile.single .description div.h3like.no-timestamp.avatar {
  padding-top: 0;
}
html.layout-bs3 .tile.single .description div.h3like.avatar {
  margin-top: 10px ;
  margin-bottom: 10px ;
  font-size: 18px ;
  font-weight: normal ;
  line-height: 25px ;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
html.layout-bs3 .tile.single .description .friendly-timestamp {
  color: #bbb;
  margin-left: 20px;
  margin-top: 10px;
  font-size: 11px;
  font-weight: normal;
}
html.layout-bs3 .tile.single .description img.avatar {
  float: left;
  width: auto;
  height: 25px;
  margin-left: 20px;
  margin-top: 10px;
  margin-right: 5px;
  border-radius: 4px;
}
html.layout-bs3 .tile.single .description h3.avatar {
  margin-top: 10px ;
  margin-bottom: 10px ;
  font-size: 18px ;
  font-weight: normal ;
  line-height: 25px ;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
}
html.layout-bs3 .tile.single .description h4.avatar {
  font-size: 16px ;
  line-height: 18px ;
}
html.layout-bs3 .tile.single.no-img .description {
  height: 100% ;
}
html.layout-bs3 .tile.single.no-img .description h3 {
  font-size: 24px ;
}
html.layout-bs3 .tile.single.no-img .description h3.avatar {
  font-size: 18px ;
}
html.layout-bs3 .tile.single.no-img .description h4 {
  color: #666666 ;
  font-size: 20px ;
  line-height: 24px ;
}
html.layout-bs3 .tile.single.no-img.social h4 {
  font-size: 26px ;
  font-weight: 300 ;
  line-height: 30px ;
}
html.layout-bs3 .tile.single.with-img .description h4.long-h3 {
  display: none;
}
html.layout-bs3 .tile.single.uberflip.portrait:not(.list) .description {
  height: 110px ;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  background: rgba(255, 255, 255, 0.95);
  -webkit-box-shadow: 0 -2px 30px rgba(150, 150, 150, 0.1);
  box-shadow: 0 -2px 30px rgba(150, 150, 150, 0.1);
}
html.layout-bs3 .tile.single.uberflip.portrait:not(.list) .description .friendly-timestamp {
  font-size: 11px ;
  height: 15px ;
}
html.layout-bs3 .tile.single.uberflip.portrait:not(.list) .description .friendly-timestamp.blank {
  height: 5px;
}
html.layout-bs3 .tile.single.uberflip.portrait:not(.list) .description h3 {
  font-size: 16px ;
  line-height: 20px ;
  font-weight: 600 ;
  color: #555 ;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
html.layout-bs3 .tile.single.uberflip.portrait:not(.list) .description .share-single {
  bottom: 60px;
}
html.layout-bs3 .tile.single.uberflip.portrait:not(.list) .img {
  height: 88% ;
}
html.layout-bs3 .tile.single.uberflip.portrait:not(.list) .img img.taller {
  height: auto ;
  width: 100% ;
}
html.layout-bs3 .tile.single.uberflip.portrait:not(.list) a.view:before {
  background: none;
}
html.layout-bs3 .tile.single.youtube .img:before,
html.layout-bs3 .tile.single.vimeo .img:before,
html.layout-bs3 .tile.single.instagram.media-type-video .img:before,
html.layout-bs3 .tile.single.wistiar .img:before,
html.layout-bs3 .tile.single.vidyard .img:before,
html.layout-bs3 .tile.single.brightcove .img:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("/img/hubs/video-play.png") no-repeat center;
  background-size: 47px;
  content: "";
}
html.layout-bs3 .tile.single.instagram:not(.list) .description,
html.layout-bs3 .tile.single.pinterest:not(.list) .description {
  height: 44% ;
}
html.layout-bs3 .tile.single.instagram:not(.list) .img,
html.layout-bs3 .tile.single.pinterest:not(.list) .img {
  height: 56% ;
}
html.layout-bs3 .tile.single.slideshare > .img.visible:after {
  width: 100%;
  height: 50px;
  margin-top: -40px;
  position: relative;
  display: block;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.07) 100%);
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.07) 100%);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.07) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#12000000', GradientType=0);
  background-repeat: repeat-x;
  background-color: transparent;
  content: "";
}
html.layout-bs3 .tile.single .hidden-cta-fields.fields-revealed .cta-field-sprite-container {
  top: initial ;
  bottom: 13px;
}
html.layout-bs3 .tile.single .hidden-cta-fields.fields-revealed.fields-revealed .cta-field-sprite-container {
  bottom: 6px;
}
html.layout-bs3 .tile.single:hover .description h3 {
  color: #000 ;
}
html.layout-bs3 .tile.single:hover > a.view {
  text-decoration: underline;
  background-color: #f8f8f8;
}
html.layout-bs3 .tile.single:hover > a.view:after {
  background-position: -16px 0;
}
html.layout-bs3 .tile.links > .description > h4 {
  position: absolute;
  width: 85%;
  width: calc(100% - 40px);
  z-index: 5;
}
html.layout-bs3 .tile.list {
  width: 100%;
  float: none;
  height: 110px;
  overflow: hidden;
  margin-bottom: 15px;
  background: #fdfdfd;
}
html.layout-bs3 .tile.list > div.img {
  width: 25%;
  float: left;
  clear: none;
  height: 100% !important;
  overflow: hidden;
  background-color: #ddd;
  position: relative;
}
html.layout-bs3 .tile.list > div.img > img {
  width: 100%;
  height: auto;
}
html.layout-bs3 .tile.list > div.img span.duration {
  font-size: 11px;
  bottom: 6px;
  right: 6px;
}
html.layout-bs3 .tile.list > div.description {
  float: left;
  clear: none;
  width: 75%;
  overflow: hidden;
  height: 100%;
  position: relative;
}
html.layout-bs3 .tile.list > div.description > .friendly-timestamp {
  margin-top: 5px;
  margin-left: 35px;
}
html.layout-bs3 .tile.list > div.description > img.avatar {
  margin-top: 2px;
}
html.layout-bs3 .tile.list > div.description h3,
html.layout-bs3 .tile.list > div.description .h3like {
  margin-right: 45px;
  margin-bottom: 2px;
}
html.layout-bs3 .tile.list > div.description h3.avatar,
html.layout-bs3 .tile.list > div.description .h3like.avatar {
  margin-top: 2px;
  margin-bottom: 2px;
}
html.layout-bs3 .tile.list > div.description h4 {
  margin-right: 45px;
  font-size: 16px;
  line-height: 21px;
  max-height: 42px;
  overflow: hidden;
}
html.layout-bs3 .tile.list > div.description h4.avatar {
  line-height: 18px;
  max-height: 36px;
}
html.layout-bs3 .tile.list > div.description:after {
  display: block;
  height: 100%;
  width: 50px;
  position: absolute;
  right: 12px;
  top: 0;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAACACAYAAAD03Gy6AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAsdJREFUeNrs3G1u2kAUheEQdRtdhXfUtLtIC7TbqPqxIC+hW6k7SEaKohLA4/E59847kuUoCCy9Ty6Df4TdNE0PS9c4jof5x8NDp2sYhqrnv6uMv3/xq24RatbjSvH3AGw0ASX+sZy+/OehPZPQeALeiM8ktAa4IT4IrQDuiA/C2ntAif+1nD4veG32hNoJqIjPJNQCrBAfhKUAK8YH4d49oMT/Vk7PDa7FnnBtAhrGZxKuAWwQH4RLAPN7/vOG1wXh1QT8Kcffja8NwhlgGIbf5fQBBOEeAILBpyAQDG7EQDC4EwZBDACCAQAIBgAgGACAYAAAggEACAYAIBgAvEB4AkEEMCP8AkEIAIIBwLxAEAOAYAAAggEACAYAIBgAgGAAAIIBAAgGACAYAJwRPoKgAzitnyBoAUAwAADBAAAEAwAQDABAMADoHuHRZBK7RXAB6BbBCaBLBDeA7hAcAc4In8oxZUdwBTitH/MkpEZwBugCwR0gPUIEgNQIUQDSIkQCSIkQDSAdQkSAVAhRAdIgRAZIgRAdIDxCBoDQKwPA6T82v5djt/F1j0xA8PjRAcLHjwyQIn5UgDTxIwKkih8NIF38SAAp40cBSBs/AkDq+O4A6eM7A3QR3xWgm/iOAF3FdwPoLr4TQJfxXQC6je8A0HV8NUD38ZUAxBcCEF8IQHwhAPGFAMQXAhBfCEB8IQDxhQDEFwIQXwhAfCEA8YUAxBcCEF8FMI4j8VUAxBcCEF8IQHwhAPGFAMQXAhBfCEB8IQDxhQDEFwIQXwhAfCEA8YUAxBcCEF8/Ae+JLwQYhuE4ByG+ag8oCIeNEIh/6VPQBgjEv3Yf0BCB+LfeCTdAIP49ACsjEH8JwEoIxK8BqEQg/hoACxGIvybAnQjEbwFwIwLxWwJcQSD+FgAXEIi/cO2mafnX7o/jeI7ebfzyx1j1/H8CDAARKo7z21s+vgAAAABJRU5ErkJggg==");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 50%;
  content: "";
  opacity: 0.3;
}
html.layout-bs3 .tile.list.with-img > div.description h4.long-h3 {
  display: block;
}
html.layout-bs3 .tile.list.no-img .description {
  width: 100%;
}
html.layout-bs3 .tile.list.no-img .description > h3,
html.layout-bs3 .tile.list.no-img .description > .h3like {
  font-size: 19px;
}
html.layout-bs3 .tile.list.no-img .description > h4 {
  font-size: 16px;
  line-height: 18px;
  color: #999;
  max-height: 36px;
  overflow: hidden;
}
html.layout-bs3 .tile.list.no-img a.item-link.view {
  left: 0;
}
html.layout-bs3 .tile.list a.item-link.view {
  text-indent: -9999px;
  width: 45px;
  border: 0;
  background-color: transparent;
  left: 25%;
  top: -3px;
  opacity: 0.5;
  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -o-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
}
html.layout-bs3 .tile.list a.item-link.view:before {
  display: none;
}
html.layout-bs3 .tile.list a.item-link.view:after {
  display: none;
}
html.layout-bs3 .tile.list a.item-link:not(.view) {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
}
html.layout-bs3 .tile.list.links > .description > h4 {
  width: 87%;
  width: calc(100% - 65px);
}
html.layout-bs3 .tile.list:hover {
  -webkit-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}
html.layout-bs3 .tile.list:hover > .description h4 {
  color: #333;
}
html.layout-bs3 .tile.cta .update-cta-placement-wrapper {
  display: none;
  position: absolute;
  bottom: 0;
  z-index: 1000;
  background: white;
  height: 100px;
  font-size: 11px;
  width: 100%;
}
html.layout-bs3 .tile.cta .update-cta-placement-wrapper .done-cta-update {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
html.layout-bs3 .tile.cta .update-cta-placement-wrapper .repeat-after-active-wrapper {
  position: absolute;
  top: 15px;
  left: 10px;
  display: block;
  width: 135px;
  height: 30px;
}
html.layout-bs3 .tile.cta .update-cta-placement-wrapper .repeat-after-active-wrapper input[name='repeat-after-active'] {
  position: absolute;
}
html.layout-bs3 .tile.cta .update-cta-placement-wrapper .repeat-after-active-wrapper label[for='repeat-after-active'] {
  position: absolute;
  left: 20px;
  top: 3px;
  width: 135px;
  display: block;
}
html.layout-bs3 .tile.cta .update-cta-placement-wrapper .repeat-after-wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
}
html.layout-bs3 .tile.cta .update-cta-placement-wrapper .repeat-after-wrapper .repeat-after {
  width: 28px;
  font-size: 13px;
}
html.layout-bs3 .tile.cta .update-cta-placement-wrapper .repeat-after-wrapper .decrease-repeat-after,
html.layout-bs3 .tile.cta .update-cta-placement-wrapper .repeat-after-wrapper .increase-repeat-after {
  padding: 5px 5px;
  font-size: 13px;
  cursor: pointer;
  width: 20px;
  height: 30px;
  display: block;
}
html.layout-bs3 html.silk .tile {
  margin: 20px 0 0 10px;
}
html.layout-bs3 div.description {
  position: absolute ;
  bottom: 0 ;
  width: 100% ;
  min-height: 70px;
  background-color: #a8a8a8;
}
html.layout-bs3 div.description h3 {
  color: #fff ;
  font-size: 18px ;
  font-weight: 400 ;
  margin: 17px 10px 3px 65px ;
  line-height: 1.3 ;
  word-wrap: break-word ;
  text-transform: none ;
}
html.layout-bs3 div.description h4 {
  color: rgba(255, 255, 255, 0.5) ;
  font-weight: 300 ;
  font-size: 16px ;
  line-height: 1.2 ;
  margin: 0 10px 15px 65px ;
  word-wrap: break-word ;
  text-transform: none ;
}
html.layout-bs3 div.description .icon {
  background-color: #a8a8a8;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 48px;
  width: 48px;
  margin: 15px 10px;
}
html.layout-bs3 div.description .icon.spacer {
  display: none;
}
html.layout-bs3 .no-img div.description .icon.spacer {
  display: block;
  position: relative;
  float: right;
  margin: 0;
  width: 80px;
  height: 80px;
  background: transparent ;
}
html.layout-bs3 .carousel-inner .tile,
html.layout-bs3 .carousel-inner .tile.highlight {
  width: 250px !important;
  height: 330px !important;
  margin-right: 20px !important;
  margin-bottom: 0 !important;
}
html.layout-bs3 #embed-tile.tile {
  -webkit-box-shadow: none;
  box-shadow: none;
}
html.layout-bs3 #embed-tile.tile.single {
  width: 248px;
  height: 303px;
  border: 1px solid lightgrey;
}
html.layout-bs3 #embed-tile .tile-description {
  position: absolute;
  width: 230px;
  height: 30px;
  padding: 20px 10px 20px 10px;
  font-size: 14px;
}
html.layout-bs3 #embed-tile .tile-description h5 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: grey;
  font-weight: 500;
  line-height: normal;
}
html.layout-bs3 #embed-tile .tile-img {
  position: absolute;
  top: 25%;
  width: 248px;
  height: 230px;
  background-size: cover;
  background-position: center;
}
html.layout-bs3 .layout-bs3 .new-cta-title-input {
  color: #555;
  width: 450px;
  height: 36px;
  font-size: 1.5em;
  padding-left: 15px;
  margin: 0 auto;
}
html.layout-bs3 .page-width.item-level .blocking-cta,
html.layout-bs3 #collection-items.blocking-cta {
  opacity: 0.2;
  filter: blur(4px);
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: progid:DXImageTransform.Microsoft.blur(pixelradius=3);
}
html.layout-bs3 html.mobile .page-width.item-level .blocking-cta,
html.layout-bs3 html.mobile #collection-items.blocking-cta {
  -webkit-filter: none;
  -moz-filter: none;
  -o-filter: none;
  -ms-filter: none;
  filter: none;
}
html.layout-bs3 .prevent-interaction {
  display: none;
}
html.layout-bs3 .blocking-cta.prevent-interaction {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  position: absolute;
  overflow: hidden;
  -o-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
html.layout-bs3 .cta-item-container {
  float: left;
  width: auto;
  padding-top: 0px;
  padding-left: 20px;
}
html.layout-bs3 .cta-item-container .cta {
  position: fixed !important;
}
html.layout-bs3 .cta {
  margin-bottom: 20px;
}
html.layout-bs3 .cta .submission-loader-container {
  display: inline;
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
}
html.layout-bs3 .cta .submission-loader-container .loader-background {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  width: 52px;
  height: 52px;
  margin: -26px 0 0 -26px;
  background-color: #1a3d18;
  border-radius: 25px;
  opacity: 0.5;
}
html.layout-bs3 .cta .submission-loader-container .backdrop {
  position: absolute;
  top: 0;
  left: auto;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background-color: #1a3d18;
}
html.layout-bs3 .cta .submission-loader-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  width: 32px;
  height: 32px;
  margin: -16px 0 0 -16px;
}
html.layout-bs3 .cta .submission-loader-container.hidden {
  display: none;
}
html.layout-bs3 .cta.full-screen-cta {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 0;
  overflow: hidden;
  -o-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
html.layout-bs3 .cta.full-screen-cta .hidden-cta-fields {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  margin: 0 auto;
  padding: 0;
  max-width: 400px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -o-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
html.layout-bs3 .cta.full-screen-cta .hidden-cta-fields .cta-field-section {
  height: auto;
  padding-top: 5px;
}
html.layout-bs3 .cta.full-screen-cta .hidden-cta-fields .cta-field-section select {
  width: 96.5%;
}
html.layout-bs3 .cta.full-screen-cta .hidden-cta-fields .cta-field-section.one-line {
  padding-top: 10px;
}
html.layout-bs3 .cta.full-screen-cta .hidden-cta-fields .cta-button-container {
  width: 88%;
}
html.layout-bs3 .cta.full-screen-cta .hidden-cta-fields .cta-button-container > input.cta-button {
  width: 96.5%;
}
html.layout-bs3 .cta.full-screen-cta .run-away {
  position: absolute;
  top: 0;
  left: auto;
  right: auto;
  bottom: auto;
}
html.layout-bs3 .cta.tile.single:before {
  background-color: inherit;
}
html.layout-bs3 .cta:before {
  background-color: inherit;
  content: "";
  opacity: 1;
}
html.layout-bs3 .cta .cta-sprite {
  background: url("/img/hubs/hubs-cta-spritesheet.png") no-repeat;
  display: inline-block;
}
html.layout-bs3 .cta .cta-sprite-check {
  background-position: 0px 0;
  width: 94px;
  height: 94px;
}
html.layout-bs3 .cta .cta-sprite-exclaim {
  background-position: -98px 0;
  width: 94px;
  height: 94px;
}
html.layout-bs3 .cta .cta-sprite-close {
  background-position: -29px -99px;
  width: 23px;
  height: 23px;
}
html.layout-bs3 .cta .cta-sprite-email {
  background-position: -56px -99px;
  width: 24px;
  height: 24px;
}
html.layout-bs3 .cta .cta-sprite-left-half-body {
  background-position: -84px -99px;
  width: 24px;
  height: 24px;
}
html.layout-bs3 .cta .cta-sprite-right-half-body {
  background-position: -112px -99px;
  width: 24px;
  height: 24px;
}
html.layout-bs3 .cta .cta-sprite-company {
  background-position: -140px -99px;
  width: 24px;
  height: 24px;
}
html.layout-bs3 .cta .cta-sprite-small-exclaim {
  background-position: -2px -99px;
  width: 24px;
  height: 24px;
}
html.layout-bs3 .cta .hide-preview-cancel {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
  height: 25px;
  line-height: 25px;
}
html.layout-bs3 .cta .hide-preview-cancel .cta-close {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  text-align: center;
  width: 25px;
}
html.layout-bs3 .cta .run-away {
  height: 74%;
  width: 100%;
  top: 0;
  left: 0;
  border-bottom: 1px solid;
  position: absolute;
}
html.layout-bs3 .cta .run-away.cta-transition {
  -webkit-transition: top 0.25s linear;
  -o-transition: top 0.25s linear;
  transition: top 0.25s linear;
}
html.layout-bs3 .cta .hidden-cta-fields {
  height: 95%;
  width: 100%;
  top: 75%;
  position: absolute;
  margin: auto;
  overflow: hidden;
  padding-top: 3%;
}
html.layout-bs3 .cta .hidden-cta-fields input {
  margin-bottom: 14px;
}
html.layout-bs3 .cta .hidden-cta-fields.fields-revealed {
  height: 92%;
}
html.layout-bs3 .cta .hidden-cta-fields.fields-revealed .cta-field-section {
  padding-bottom: 0px;
}
html.layout-bs3 .cta .hidden-cta-fields.fields-revealed .cta-field-section input {
  margin-bottom: 6px;
}
html.layout-bs3 .cta .hidden-cta-fields.fields-revealed .cta-button-container input {
  margin-bottom: 0px;
}
html.layout-bs3 .cta .hidden-cta-fields.fields-revealed .cta-field-section.one-line {
  padding-top: 10px;
}
html.layout-bs3 .cta .hidden-cta-fields form {
  margin: 0 0 20px;
}
html.layout-bs3 .cta .hidden-cta-fields.cta-transition {
  -webkit-transition: top 0.25s linear;
  -o-transition: top 0.25s linear;
  transition: top 0.25s linear;
}
html.layout-bs3 .cta .hidden-cta-fields .cta-field-section {
  height: 25%;
  position: relative;
  width: 88%;
  margin: 0 auto;
  padding-bottom: 15px;
}
html.layout-bs3 .cta .hidden-cta-fields .cta-field-section.smaller .cta-field-name {
  font-size: 14px;
  padding-bottom: 0;
}
html.layout-bs3 .cta .hidden-cta-fields .cta-field-section.smaller .cta-field-sprite-container.cta-front {
  top: 27px !important;
}
html.layout-bs3 .cta .hidden-cta-fields .cta-field-section.smaller input {
  height: 19px;
  margin-bottom: 0px;
}
html.layout-bs3 .cta .hidden-cta-fields .cta-field-section.smaller select {
  height: 27px !important;
  line-height: 32px !important;
  margin: 0px;
}
html.layout-bs3 .cta .hidden-cta-fields .cta-field-section .cta-field-name {
  display: inline-block;
  zoom: 1;
  *display: inline;
  font-size: 16px;
  padding-bottom: 4px;
  color: #fff;
}
html.layout-bs3 .cta .hidden-cta-fields .cta-field-section .cta-field-name.label-only-text {
  font-size: 11px;
  line-height: 1.5em;
}
html.layout-bs3 .cta .hidden-cta-fields .cta-field-section .cta-field-name-optional {
  font-size: 80%;
  opacity: 0.75;
}
html.layout-bs3 .cta .hidden-cta-fields .cta-field-section .cta-field-sprite-container {
  position: absolute;
  background-color: transparent;
  top: 36px;
  left: 8px;
}
html.layout-bs3 .cta .hidden-cta-fields .cta-field-section .preview-form-field {
  display: inline-block;
  zoom: 1;
  *display: inline;
  height: 25px;
}
html.layout-bs3 .cta .hidden-cta-fields .cta-field-section .preview-form-field::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
html.layout-bs3 .cta .hidden-cta-fields .cta-field-section .preview-form-field:-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
html.layout-bs3 .cta .hidden-cta-fields .cta-field-section .preview-form-field::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
html.layout-bs3 .cta .hidden-cta-fields .cta-field-section .preview-form-field:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
html.layout-bs3 .cta .hidden-cta-fields .cta-field-section .preview-form-field.error {
  border-color: #fff;
  background-color: #f5aaaa;
}
html.layout-bs3 .cta .hidden-cta-fields .cta-field-section input {
  width: 100%;
}
html.layout-bs3 .cta .hidden-cta-fields .cta-field-section select {
  width: 100%;
  height: 35px !important;
  line-height: 32px !important;
  margin-bottom: 6px;
}
html.layout-bs3 .cta .hidden-cta-fields .cta-field-section.one-line {
  padding-top: 30px;
  padding-bottom: 10px;
}
html.layout-bs3 .cta .hidden-cta-fields .cta-field-section.one-line .opt-in-required {
  display: none;
  float: left;
  background: #f5aaaa;
  border: 1px solid #fff;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  text-align: center;
  color: #000;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-right: 5px;
}
html.layout-bs3 .cta .hidden-cta-fields .cta-field-section.one-line .opt-in-required.show-required-text {
  display: inline-block;
}
html.layout-bs3 .cta .hidden-cta-fields .cta-field-section.one-line input {
  width: auto;
  margin: 3px 8px 0px 3px;
  vertical-align: top;
  height: auto;
  text-indent: auto;
  display: inline;
}
html.layout-bs3 .cta .hidden-cta-fields .cta-field-section.one-line span {
  display: inline;
}
html.layout-bs3 .cta .hidden-cta-fields .cta-button-container {
  height: 22%;
  padding-top: 14px;
  width: 88%;
  margin: 0 auto;
}
html.layout-bs3 .cta .hidden-cta-fields .cta-activate-button-container {
  padding-top: 0;
  width: 100%;
}
html.layout-bs3 .cta .cta-form-response {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
html.layout-bs3 .cta .cta-form-response.cta-form-error {
  background-color: white;
}
html.layout-bs3 .cta .cta-form-response .cta-form-message-container {
  width: 75%;
  margin: 0 auto;
  margin-top: 28%;
  text-align: center;
}
html.layout-bs3 .cta .cta-form-response .cta-form-message-container .cta-form-message-sprite {
  display: block;
  margin: 0 auto;
}
html.layout-bs3 .cta .cta-form-response .cta-form-message-container .cta-form-message {
  padding-top: 30px;
}
html.layout-bs3 .cta .cta-form-response .cta-form-message-container .cta-form-message span {
  font-size: 22px;
}
html.layout-bs3 .cta .cta-form-response .cta-button-container {
  width: 90%;
  margin-left: 5%;
  position: absolute;
  bottom: 15px;
}
html.layout-bs3 .cta .cta-button {
  font-family: Droid Sans, sans-serif;
  line-height: 40px;
  height: 40px;
  vertical-align: middle;
  display: block;
  font-size: 17px;
  text-align: center;
  width: 100%;
  border: 0;
}
html.layout-bs3 .cta .cta-button:hover {
  -webkit-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
}
html.layout-bs3 .cta > img {
  width: 100%;
  position: absolute;
}
html.layout-bs3 .cta p {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  padding: 20px;
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  line-height: normal;
}
html.layout-bs3 .cta > a,
html.layout-bs3 .cta.tile.single > a {
  position: absolute;
  top: auto;
  left: 0;
  bottom: 0;
  width: 68%;
  height: 1em;
  margin: 20px 8.5%;
  text-align: center;
  box-sizing: content-box;
}
html.layout-bs3 .cta > a.accent-button {
  background-color: #a8a8a8;
  color: #fff;
  display: block;
  font-size: 20px;
  line-height: 1;
  margin-top: 20px;
  padding: 20px;
  text-decoration: none;
}
html.layout-bs3 .cta > a.accent-button:hover {
  color: #fff;
}
html.layout-bs3 .cta .ps-container:not(:hover) .ps-scrollbar-y-rail {
  opacity: 0.3;
}
html.layout-bs3 .cta .ps-container .ps-scrollbar-y-rail,
html.layout-bs3 .cta .ps-container .ps-scrollbar-y-rail.hover {
  background: transparent;
}
html.layout-bs3 .cta .ps-container .ps-scrollbar-y-rail .ps-scrollbar-y,
html.layout-bs3 .cta .ps-container .ps-scrollbar-y-rail.hover .ps-scrollbar-y {
  background-color: #fff;
}
html.layout-bs3 .cta.setValues {
  background-image: none !important;
}
html.layout-bs3 .cta.setValues > .run-away {
  top: -300px;
}
html.layout-bs3 .cta.setValues > .fields-revealed {
  top: 25px;
}
html.layout-bs3 .cta.setValues > .hide-preview-cancel {
  display: block;
}
html.layout-bs3 abbr[title] {
  border: 0;
}
html.layout-bs3 ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
html.layout-bs3 #reset-display-order-modal .reset-sort-field,
html.layout-bs3 #reset-display-order-modal .reset-sort-order {
  padding: 10px 0 10px 30px;
}
html.layout-bs3 #reset-display-order-modal h3 {
  font-size: 16px;
}
html.layout-bs3 .stream-owner-avatar {
  float: left;
  width: 90px;
  height: 90px;
  margin: 0 20px 0 0;
  border-radius: 50%;
  box-sizing: border-box;
}
html.layout-bs3 .stream-owner-avatar.item-level {
  width: 35px;
  height: 35px;
  margin: -6px 10px 0 0;
}
html.layout-bs3 .stream-owner-avatar.pic {
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
}
html.layout-bs3 .stream-owner-avatar.pic.item-level {
  background-size: 35px auto;
}
html.layout-bs3 .stream-owner-avatar.text {
  line-height: 90px;
  font-size: 32px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  color: #fff;
}
html.layout-bs3 .stream-owner-avatar.text.item-level {
  line-height: 35px;
  font-size: 17px;
  font-weight: 300;
}
html.layout-bs3 body.hubs-embedded-iframe.cta-embed {
  overflow: hidden;
  background: transparent;
}
html.layout-bs3 body.hubs-embedded-iframe.cta-embed > .main {
  margin-top: 0 !important;
}
html.layout-bs3 body.hubs-embedded-iframe.cta-embed > .main div.tile.cta {
  margin: 0;
  float: none;
  height: 330px;
  max-height: 330px;
}
html.layout-bs3 .accent-button {
  position: relative;
}
html.layout-bs3 .accent-button:hover {
  -webkit-box-shadow: 0px 2px 2px rgba(50, 50, 50, 0.3);
  box-shadow: 0px 2px 2px rgba(50, 50, 50, 0.3);
  text-shadow: 0px 1px 3px rbga(0, 0, 0, 0.9);
}
html.layout-bs3 .accent-button:hover:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
  background-repeat: repeat-x;
  content: "";
  opacity: 0.1;
  z-index: -1;
}
html.layout-bs3 .tile.single .icon {
  z-index: 1;
}
html.layout-bs3 .tile.single > a {
  z-index: 4;
}
html.layout-bs3 .tile.single > a.view {
  z-index: 6;
}
html.layout-bs3 .cta-item-container .cta {
  z-index: 1;
}
html.layout-bs3 .cta .hidden-cta-fields {
  z-index: 5;
}
html.layout-bs3 .cta .cta-form-response {
  z-index: 5;
}
html.layout-bs3 .cta .run-away {
  z-index: 6;
}
html.layout-bs3 .cta .submission-loader-container {
  z-index: 12;
}
html.layout-bs3 .cta .submission-loader-container img {
  z-index: 1;
}
html.layout-bs3 .blocking-cta.prevent-interaction {
  z-index: 2;
}
html.layout-bs3 .share-single,
html.layout-bs3 .share-item.type-single {
  z-index: 7;
}
html.layout-bs3 .search-results-overlay .mobile-search-header {
  z-index: 1;
}
html.layout-bs3 .share-social,
html.layout-bs3 .share-twitter,
html.layout-bs3 .share-item {
  z-index: 401;
}
html.layout-bs3 .top-nav {
  z-index: 1000;
}
html.layout-bs3 .top-nav .secondary-logo {
  z-index: 1100;
}
html.layout-bs3 .top-nav .left-nav.desktop {
  z-index: 1200;
}
html.layout-bs3 .top-nav .nav-back,
html.layout-bs3 .top-nav .nav-toggle,
html.layout-bs3 .top-nav .right-side-btns {
  z-index: 1300;
}
html.layout-bs3 .large-header {
  z-index: 2000;
}
html.layout-bs3 .left-nav.desktop {
  z-index: 3000;
}
html.layout-bs3 .left-nav > li ul {
  z-index: 36;
}
html.layout-bs3 .left-nav > li .sub-menu-arrow {
  z-index: 41;
}
html.layout-bs3 .search-results-backdrop {
  z-index: 4200;
}
html.layout-bs3 .search-results-overlay {
  z-index: 4250;
}
html.layout-bs3 .search-drop-down {
  z-index: 4300;
}
html.layout-bs3 .share-hub,
html.layout-bs3 .share-item {
  z-index: 4400;
}
html.layout-bs3 .mobile-nav,
html.layout-bs3 .mobile-share {
  z-index: 4500;
}
html.layout-bs3 #colorbox,
html.layout-bs3 #cboxOverlay,
html.layout-bs3 #cboxWrapper {
  z-index: 4700;
}
html.layout-bs3 .cta.full-screen-cta {
  z-index: 7000;
}
html.layout-bs3 .cta.full-screen-cta .run-away {
  z-index: 10;
}
html.layout-bs3 .cta.full-screen-cta .hidden-cta-fields {
  z-index: 9;
}
html.layout-bs3 .document-container {
  z-index: 7100;
}
html.layout-bs3 .document-container .document-control {
  z-index: 7200;
}
html.layout-bs3 #loading-overlay {
  z-index: 8000;
}
html.layout-bs3 body.lab_sticky_footer > footer {
  z-index: 999;
}
html.layout-bs3 body.lab_sticky_footer .expired-trial {
  z-index: 1000;
}
html.layout-bs3 body.lab_permanent_header .search-results-backdrop {
  z-index: 5200;
}
html.layout-bs3 body.lab_permanent_header .search-results-overlay {
  z-index: 5250;
}
html.layout-bs3 body.lab_permanent_header .search-drop-down {
  z-index: 5300;
}
html.layout-bs3 body.lab_permanent_header.search-results-opened .top-nav {
  z-index: 6000;
}
html.layout-bs3 body.lab_permanent_header.search-results-opened .share-hub,
html.layout-bs3 body.lab_permanent_header.search-results-opened .share-item,
html.layout-bs3 body.lab_permanent_header.search-results-opened .search-drop-down,
html.layout-bs3 body.lab_permanent_header.search-results-opened .mobile-nav,
html.layout-bs3 body.lab_permanent_header.search-results-opened .mobile-share {
  z-index: 6100;
}
html.layout-bs3 body.lab_permanent_header.search-results-opened .search-results-backdrop {
  z-index: 6500;
}
html.layout-bs3 body.lab_permanent_header.search-results-opened .search-results-overlay {
  z-index: 6600;
}
html.layout-bs3 body.lab_permanent_header.search-results-opened .search-drop-down {
  z-index: 6700;
}
html.layout-bs3 body.lab_nav_always_top .top-nav {
  z-index: 6000;
}
html.layout-bs3 body.lab_nav_always_top .share-hub,
html.layout-bs3 body.lab_nav_always_top .share-item,
html.layout-bs3 body.lab_nav_always_top .search-drop-down,
html.layout-bs3 body.lab_nav_always_top .mobile-nav,
html.layout-bs3 body.lab_nav_always_top .mobile-share {
  z-index: 6100;
}
html.layout-bs3 body.lab_nav_always_top .search-results-backdrop {
  z-index: 6500;
}
html.layout-bs3 body.lab_nav_always_top .search-results-overlay {
  z-index: 6600;
}
html.layout-bs3 body.lab_nav_always_top .search-drop-down {
  z-index: 6700;
}
html.layout-bs3 .KeepNavAtTop .top-nav {
  z-index: 6000;
}
html.layout-bs3 .KeepNavAtTop .share-hub,
html.layout-bs3 .KeepNavAtTop .share-item,
html.layout-bs3 .KeepNavAtTop .search-drop-down,
html.layout-bs3 .KeepNavAtTop .mobile-nav,
html.layout-bs3 .KeepNavAtTop .mobile-share {
  z-index: 6100;
}
html.layout-bs3 .KeepNavAtTop .search-results-backdrop {
  z-index: 6500;
}
html.layout-bs3 .KeepNavAtTop .search-results-overlay {
  z-index: 6600;
}
html.layout-bs3 .KeepNavAtTop .search-drop-down {
  z-index: 6700;
}
.mgmt-container .content-container .alert {
  margin-left: 20px;
}
.mgmt-container .content-container .alert.hubs-collection-hidden-note {
  margin-bottom: 40px;
}
.mgmt-container .content-container.hubs-section-manage-content {
  padding: 0 20px 38px 20px;
}
.mgmt-container .content-container div.upgrade-bubble {
  margin: 0 auto;
  text-align: center;
  width: 400px;
  border: 1px solid #ccc;
  padding: 20px;
}
.mgmt-container .content-container .navbar {
  width: auto;
}
.mgmt-container .content-container .navbar .container {
  text-align: center;
}
.mgmt-container .content-container .navbar .container .nav > li {
  display: inline-block;
  float: none;
  text-align: left;
}
.mgmt-container .content-container .create-hub-btn {
  margin-top: 20px;
}
.mgmt-container .content-container .domain-notice {
  margin-bottom: 15px;
}
.mgmt-container .content-container .custom-script-wrapper {
  margin: 0 auto 100px auto;
  max-width: 1400px;
}
.mgmt-container .content-container .custom-script-wrapper label {
  line-height: 1.2em;
}
.mgmt-container .content-container .custom-script-wrapper label.radio input[type="radio"],
.mgmt-container .content-container .custom-script-wrapper label.checkbox input[type="checkbox"] {
  float: none;
  display: inline-block;
}
.mgmt-container .content-container .custom-script-wrapper .ace-editor {
  height: 422px;
  padding: 10px 4.5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
.mgmt-container .content-container .custom-script-wrapper .alert-info {
  margin-left: 0;
}
.mgmt-container .content-container .custom-script-wrapper .alert-info a {
  color: #2f96b4;
  text-decoration: underline;
}
.mgmt-container .content-container .custom-script-wrapper .toggle-custom-script-wrapper {
  margin-bottom: 40px;
}
.mgmt-container .content-container .custom-script-wrapper .toggle-custom-script-wrapper label {
  display: inline-block;
}
.mgmt-container .content-container .custom-script-wrapper .custom-script-container {
  margin-top: 40px;
}
.mgmt-container .content-container .custom-script-wrapper .custom-script-container .custom-script-header {
  padding: 10px;
  background: #999;
  color: #fff;
}
.mgmt-container .content-container .custom-script-wrapper .custom-script-container .custom-script-header:before,
.mgmt-container .content-container .custom-script-wrapper .custom-script-container .custom-script-header:after {
  display: table;
  content: " ";
}
.mgmt-container .content-container .custom-script-wrapper .custom-script-container .custom-script-header:after {
  clear: both;
}
.mgmt-container .content-container .custom-script-wrapper .custom-script-container .custom-script-header .dev-note > strong {
  font-size: 14px;
}
.mgmt-container .content-container .custom-script-wrapper .custom-script-container .custom-script-header .dev-note > span {
  padding-left: 15px;
  color: #fff;
}
.mgmt-container .content-container .custom-script-wrapper .custom-script-container .custom-script-header .badge {
  padding: 1px 9px 0;
  margin-right: 6px;
  background: #888;
}
.mgmt-container .content-container .custom-script-wrapper .custom-script-container .custom-script-tag {
  background: #ddd;
  border: 1px solid #ccc;
  border-width: 0 1px;
}
.mgmt-container .content-container .custom-script-wrapper .custom-script-container .custom-script-tag > span {
  display: block;
  padding: 8px;
  color: #888;
  font-size: 14px;
}
.mgmt-container .content-container .custom-script-wrapper .custom-script-container textarea {
  width: 100%;
  height: 430px;
  color: #dddddd;
  font-size: 11.5px;
  line-height: 11px;
  background: #121212;
}
.mgmt-container .content-container .custom-script-wrapper .custom-script-container .custom-save {
  padding: 12px;
  margin-top: 0;
  border: 1px solid #ccc;
  border-top: none;
  background-color: #eeeeee;
}
.mgmt-container .content-container .custom-script-wrapper .custom-script-container div.note {
  margin-bottom: 7px;
}
.mgmt-container .content-container .custom-script-wrapper .custom-script-container div.note:hover {
  background-color: #eeeeee;
}
.mgmt-container .content-container .custom-script-wrapper .custom-script-container div.note a:hover {
  color: #2f96b4;
}
.mgmt-container .content-container .custom-script-wrapper .custom-css-support {
  width: 19%;
  background: #f9f9f9;
  height: 480px;
}
.mgmt-container .content-container .custom-script-wrapper .custom-css-support > div {
  padding: 0px 20px;
}
.mgmt-container .content-container .custom-script-wrapper .custom-css-support > div > h3 {
  color: #ce0058;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 17px;
  line-height: 20px;
  margin: 20px 0 10px;
}
.mgmt-container .content-container .custom-script-wrapper .custom-css-support > div > p {
  font-size: 12px;
  line-height: 18px;
}
.mgmt-container .content-container .custom-script-wrapper .custom-css-support > div > p > a {
  color: #2f96b4;
  font-weight: bold;
}
.mgmt-container .content-container .custom-script-wrapper .editor-note {
  margin-bottom: 10px;
}
.mgmt-container .content-container .custom-script-wrapper .custom-script-container.ace-editor-full-screen {
  height: auto;
  width: auto;
  position: fixed !important;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  border: 0;
}
.mgmt-container .content-container .nav-block-icon.nav-code {
  width: 280px;
  margin: 0 auto;
}
.mgmt-container .content-container .nav-block-icon.nav-code > li a {
  padding: 0;
  color: #fff;
  line-height: 80px;
  text-align: center;
  font-size: 16px;
}
.mgmt-container .content-container .nav-block-icon.nav-code > li a > span {
  display: inline;
  border: 1px solid #fff;
  padding: 2px 7px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-transition: all 500ms;
  -o-transition: all 500ms;
  transition: all 500ms;
}
.mgmt-container .content-container .nav-block-icon.nav-code > li a.unsaved > span {
  background: #5bc0de;
  -webkit-box-shadow: 0 0 15px rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.6);
}
.mgmt-container .content-container .nav-block-icon.nav-code > li.active a > span,
.mgmt-container .content-container .nav-block-icon.nav-code > li.active a.unsaved > span {
  background: rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.icon-share-new,
.icon-cta,
.icon-embed-new {
  background-image: url("/img/hubs/backend/spritesheet.png");
}
.icon-share-new {
  background-position: -414px -174px;
}
.icon-embed-new {
  background-position: -51px -453px;
  width: 19px;
}
.icon-cta {
  background-position: -452px -173px;
  margin-right: 2px;
}
.autosuggest {
  text-align: left;
}
.layout-bs3 .import-container .import-title,
.layout-bs3 .import-container .import-action {
  line-height: 24px;
}
.layout-bs3 .import-container .import-title {
  padding: 23px 0 23px 15px;
}
.layout-bs3 .import-container .import-title .halflings {
  top: 3px;
  margin-right: 10px;
}
.layout-bs3 .import-container .import-action {
  padding: 23px 15px 23px 0;
}
.layout-bs3 .import-type-container .checkbox label {
  margin-top: 30px;
}
.layout-bs3 .exclaim-container .halflings {
  top: 2px;
  opacity: 0.5;
}
table.table.standard-data-table {
  margin-top: 20px;
}
table.table.standard-data-table thead tr th {
  padding: 6px 18px 10px 10px;
  white-space: nowrap;
}
table.table.standard-data-table thead tr th.sorting_asc,
table.table.standard-data-table thead tr th.sorting_desc {
  -webkit-filter: grayscale(100%);
}
table.table.standard-data-table thead tr th:last-child {
  width: 80px;
  max-width: 80px;
  text-align: right;
}
table.table.standard-data-table thead tr th.no-sort {
  background: transparent;
  cursor: default;
}
table.table.standard-data-table tbody td {
  vertical-align: middle;
}
table.table.standard-data-table tbody td.no-wrap {
  white-space: nowrap;
}
table.dataTable.standard-data-table tbody tr.odd,
table.dataTable.standard-data-table tbody tr.even {
  background-color: #fff;
}
table.dataTable.standard-data-table tbody tr:hover > td {
  background: #eee;
}
.dataTables_wrapper .row {
  width: auto;
  padding: 0;
}
.dataTables_wrapper .row:last-child {
  margin-top: 20px;
}
.dataTables_wrapper .row label {
  color: #666;
  font-weight: normal;
}
.dataTables_wrapper .dataTables_filter input {
  margin-left: 6px;
}
.dataTables_wrapper div.dataTables_info {
  padding-top: 8px;
}
.dataTables_wrapper .dataTables_empty {
  padding: 10px;
}
ul.hubs-dropdown li a {
  color: #5e5e5e;
}
ul.hubs-dropdown li a .icon-white {
  display: none;
}
ul.hubs-dropdown li.active a,
ul.hubs-dropdown li.active a:hover {
  color: #ce0058 !important;
  background: #f9f9f9 !important;
}
ul.hubs-dropdown > li > a:hover {
  color: #2f96b4;
  background: #f9f9f9;
}
.collection-search-container {
  float: right;
  width: auto;
}
.collection-search-container .collection-search-title,
.collection-search-container .collection-search-progress,
.collection-search-container .input-group {
  float: right;
}
.collection-search-container .input-group {
  width: 220px;
}
.collection-search-container .input-group a {
  color: #333333;
}
.collection-search-container .input-group input {
  padding-right: 24px;
}
.collection-search-container .input-group .close-float {
  position: absolute;
  top: 0;
  left: auto;
  right: 45px;
  bottom: auto;
  font-size: 20px;
  cursor: pointer;
  z-index: 4;
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}
.collection-search-title {
  padding: 2px 10px 0 0;
  font-size: 10px;
  font-weight: normal;
  line-height: 14px;
}
.collection-search-title:before,
.collection-search-title:after {
  display: table;
  content: " ";
}
.collection-search-title:after {
  clear: both;
}
.collection-search-title .section-tally {
  float: left;
}
.collection-search-title .section-tally .badge {
  padding: 1px 9px;
  font-size: 10px;
  border-radius: 2px;
}
.collection-search-title .section-tally .caption {
  display: block;
  text-align: center;
}
.collection-search-title .result-tally {
  float: left;
  padding: 0px 0 0 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 25px;
}
.collection-search-progress {
  display: none;
  padding: 3px 8px;
}
.collection-search-progress img {
  width: 20px;
}
.hubs_load_more {
  clear: both;
  margin: 8px;
  padding-top: 10px;
  text-align: center;
}
.hubs_load_more .loading-lock-state {
  display: none;
}
.hubs_load_more .locked .load-more-text {
  display: none;
}
.hubs_load_more .locked .loading-lock-state {
  display: inline;
}
.collection-item-holder:before,
.collection-item-holder:after {
  display: table;
  content: " ";
}
.collection-item-holder:after {
  clear: both;
}
.collection-item-holder .no-deleted-items {
  text-align: center;
  font-size: 20px;
}
.toggle .hubs_section_header.hover {
  cursor: pointer;
}
.toggle .hubs_section_header.hover .header_section_buttons {
  background: #fff;
}
.hubs_section_wrapper {
  margin-bottom: 30px;
  margin-top: 30px;
}
.hubs_section_wrapper .hubs_section_header {
  height: 46px;
  margin: 0;
}
.hubs_section_wrapper .hubs_section_header:before,
.hubs_section_wrapper .hubs_section_header:after {
  display: table;
  content: " ";
}
.hubs_section_wrapper .hubs_section_header:after {
  clear: both;
}
.hubs_section_wrapper .hubs_section_header .hubs_section_header_title {
  float: left;
  margin-top: 16px;
  margin-left: 15px;
}
.hubs_section_wrapper .hubs_section_header .section_title {
  color: #ce0058;
  font-weight: bold;
  font-size: 1.2em;
}
.hubs_section_wrapper .hubs_section_header .hubs_section_tally {
  color: #cbcbcb;
  font-size: 1.2em;
}
.hubs_section_wrapper .hubs_section_header .hubs_section_description {
  color: #666666;
  font-size: 9px;
  margin-left: 8px;
}
.hubs_section_wrapper .hubs_section_header .header_section_buttons {
  height: 100%;
}
.hubs_section_wrapper .hubs_section_header .header_section_buttons span.btn-group {
  display: inline-block;
  vertical-align: bottom;
}
.hubs_section_wrapper .hubs_section_header .header_section_buttons span.btn-group .caret {
  margin: 6px 0 0 3px;
}
.hubs_section_wrapper .hubs_section_header input.inline_edit_input {
  width: 240px;
  height: 16px;
  margin: -8px 0 0 -8px;
}
.hubs_section_wrapper .empty-group {
  margin: 30px 0;
  padding: 25px;
  text-align: center;
  background: #f9f9f9;
}
.hubs_section_wrapper .empty-group .empty-group-text {
  width: 80%;
  margin: 15px auto;
  font-size: 1.25em;
  text-align: center;
}
.hubs_section_wrapper .hubs-collection-hidden-note {
  margin: 0 0 40px 0 !important;
}
.hubs_section_block,
.hubs_featured_section_block,
.hubs_collection_section_block {
  min-width: 500px;
  background-color: #fff !important;
}
.hubs_section_block:before,
.hubs_featured_section_block:before,
.hubs_collection_section_block:before,
.hubs_section_block:after,
.hubs_featured_section_block:after,
.hubs_collection_section_block:after {
  display: table;
  content: " ";
}
.hubs_section_block:after,
.hubs_featured_section_block:after,
.hubs_collection_section_block:after {
  clear: both;
}
.hubs_section_block.limit-height {
  max-height: 220px;
  overflow-y: auto;
  overflow-x: hidden;
}
.hubs_collection_section_block {
  padding: 0px 0 15px 0;
}
.hubs_collection_section_block .hub-collection-options {
  position: relative;
  margin: 40px 0 0 0;
}
.hubs_collection_section_block .hub-collection-options .manage-deleted-items {
  position: absolute;
  right: 0;
}
.hubs_collection_section_block.list-view .item-cta {
  display: none !important;
}
.hubs_collection_section_block .item-cta:not(.cta-15) .cta {
  height: 293px;
}
.hubs_collection_section_block .item-cta:not(.first-cta) .cta {
  height: 293px;
}
.hubs_collection_section_block .item-cta:not(.first-cta) .cta .update-cta-placement-wrapper {
  display: none;
}
.hubs_collection_section_block .item-cta:not(.first-cta) .collection-item-controls {
  display: none;
}
.hubs_featured_section_block.limit-height {
  max-height: 350px;
  overflow: auto;
}
.collection-wrapper.hidden .hubs_collection_section_block,
.hubs_section_wrapper.hidden .hubs_collection_section_block {
  opacity: 0.75;
}
.item-selector-item-container .item-group {
  float: left;
  clear: none;
  margin: 20px 0 10px 15px;
}
.item-selector-item-container .item-group.item-hidden {
  opacity: 0.5;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
}
.item-selector-item-container .item-group.item-hidden:hover {
  opacity: 0.8;
}
.item-selector-item-container .item-group.item-hidden:before {
  content: "Hidden Item";
  background: #fff;
  background: rgba(255, 255, 255, 0.8);
  padding: 1px 6px;
  position: absolute;
  font-size: 10px;
  margin: 5px;
  border-radius: 3px;
}
.item-selector-item-container .item-group:hover {
  -webkit-box-shadow: 0 0 10px #888888;
  box-shadow: 0 0 10px #888888;
}
.collection-item-controls {
  height: 33px;
  background-color: #fff;
  border: 1px solid #cccccc;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.collection-item-controls a {
  color: #5e5e5e;
  background: #fafafa;
  font-size: 0.9em;
  line-height: 0.9em;
  font-weight: normal;
  border-bottom-width: 1px;
}
.collection-item-controls a:hover,
.collection-item-controls a:focus {
  color: inherit;
}
.collection-item-controls .left {
  display: inline-block;
  margin: 5px 0 0 5px;
}
.collection-item-controls .right {
  display: inline-block;
  margin: 5px 5px 0 0;
}
.collection-item-controls [class^="icon-"],
.collection-item-controls [class*=" icon-"],
.collection-item-controls .halflings {
  height: 14px;
  margin-top: -1px;
  font-size: 12px;
}
.collection-item-controls .dropdown-menu li a {
  background: transparent;
}
.collection-item-controls .dropdown-menu li a:hover {
  color: #666666;
  background: #eeeeee;
}
.collection-group {
  display: inline-block;
  width: 228px;
  margin: 15px 0 0 0.75%;
  vertical-align: top;
  font-weight: bold;
  border: 1px solid #ddd;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.collection-group.add-collection-bt {
  text-align: center;
  background: rgba(255, 255, 255, 0.5);
}
.collection-group.add-collection-bt a.btn {
  margin: 67px auto;
  font-weight: normal;
}
.collection-group.add-collection-bt:hover {
  background: #ffffff;
}
.collection-group.add-collection-bt.hidden {
  visibility: hidden;
}
.collection-group.hidden {
  opacity: 0.5;
}
.collection-group .collection-item-controls {
  border: none;
}
.collection-group .collection-item-controls a.btn:hover {
  border-color: #ddd;
}
.collection-group:hover {
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
}
.collection-group:hover .collection-placeholder > .tile > div.description {
  background-color: #333;
}
.collection-placeholder {
  width: 100%;
  height: 132px;
  background-color: #c7c7c7;
  cursor: pointer;
  overflow: hidden;
}
.collection-placeholder .tile {
  position: relative;
  width: 100%;
  height: 132px;
  max-height: none;
  min-height: 0;
  max-width: none;
  min-width: 0;
}
.collection-placeholder .tile div.description {
  min-height: 48px;
  background: #666;
}
.collection-placeholder .tile div.description h3 {
  margin: 16px 8px 0px 50px !important;
  font-size: 14px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.collection-placeholder .tile div.description .icon {
  margin: 0;
  background-color: transparent;
  opacity: 0.7;
  -webkit-transform: scale(0.7);
}
.collection-preview-bg {
  position: relative;
  height: 132px;
  margin-top: -132px;
  background: transparent url("/img/hubs/backend/preview_bg.png") no-repeat top right;
  cursor: pointer;
}
.collection-tile {
  position: relative;
  float: left;
  width: 250px;
  height: 330px;
  padding: 0;
  margin: 0;
  line-height: 1.5em;
  background: #fff;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
  overflow: hidden;
}
.collection-tile .collection-tile-service-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.collection-tile.has-thumb .collection-tile-service-bg {
  opacity: 0.75;
}
.collection-tile .collection-tile-service-icon {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  width: 60px;
  height: 60px;
  line-height: 60px;
  margin: -70px 0 0 -30px;
  vertical-align: middle;
  background-color: transparent;
  background-image: url("/img/hubs/servicetiles.png");
  background-repeat: no-repeat;
  background-position: 0px 0;
}
.collection-tile .description {
  position: absolute;
  top: auto;
  left: 0;
  right: auto;
  bottom: 0;
  width: 250px !important;
  height: auto;
  min-height: 50px;
  line-height: 16px;
  padding: 10px 20px;
  border-top: 1px solid #ddd;
  background-color: #fff !important;
}
.collection-tile .description h4 {
  color: #555 !important;
  margin: 10px 0 !important;
  font-weight: bold !important;
}
.collection-tile .description .timeago {
  color: #888;
}
.collection-tile.service-twitter .collection-tile-service-bg {
  background-color: #79caee;
}
.collection-tile.service-twitter .collection-tile-service-icon {
  background-position: -2px -4px;
}
.collection-tile.service-blogpost .collection-tile-service-bg {
  background-color: #f7931d;
}
.collection-tile.service-blogpost .collection-tile-service-icon {
  background-position: -72px -4px;
}
.collection-tile.service-facebook .collection-tile-service-bg {
  background-color: #415f9f;
}
.collection-tile.service-facebook .collection-tile-service-icon {
  background-position: -142px -4px;
}
.collection-tile.service-youtube .collection-tile-service-bg {
  background-color: #f00;
}
.collection-tile.service-youtube .collection-tile-service-icon {
  background-position: -212px -4px;
}
.collection-tile.service-uberflip .collection-tile-service-bg,
.collection-tile.service-uberflip-ui .collection-tile-service-bg {
  background-color: #cd1c5a;
}
.collection-tile.service-uberflip .collection-tile-service-icon,
.collection-tile.service-uberflip-ui .collection-tile-service-icon {
  background-position: -282px -4px;
}
.collection-tile.service-vimeo .collection-tile-service-bg {
  background-color: #7bcfdb;
}
.collection-tile.service-vimeo .collection-tile-service-icon {
  background-position: -352px -4px;
}
.collection-tile.service-instagram .collection-tile-service-bg {
  background-color: #e4405f;
}
.collection-tile.service-instagram .collection-tile-service-icon {
  background-position: -424px -4px;
}
.collection-tile.service-slideshare .collection-tile-service-bg {
  background-color: #00a7a9;
}
.collection-tile.service-slideshare .collection-tile-service-icon {
  background-position: -492px -4px;
}
.collection-tile.service-wistiar .collection-tile-service-bg {
  background-color: #1678be;
}
.collection-tile.service-wistiar .collection-tile-service-icon {
  background-position: -562px -4px;
}
.collection-tile.service-vidyard .collection-tile-service-bg {
  background-color: #499744;
}
.collection-tile.service-vidyard .collection-tile-service-icon {
  background-position: -632px -4px;
}
.collection-tile.service-custom .collection-tile-service-bg {
  background-color: #cd1c5a;
}
.collection-tile.service-custom .collection-tile-service-icon {
  background-position: -701px -4px;
}
.collection-tile.service-brightcove .collection-tile-service-bg {
  background-color: #f7931d;
}
.collection-tile.service-brightcove .collection-tile-service-icon {
  background-position: -775px -4px;
}
.item-group {
  display: inline-block;
  float: left;
  clear: none;
  width: 225px;
  margin: 20px 0 10px 15px;
  font-weight: bold;
  position: relative;
}
.item-group .item-copy {
  margin: 0 20px 0 0;
  border-bottom: 1px solid #cccccc;
}
.item-group .item-copy:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.item-group .circle-icon,
.item-group .select-item {
  display: none;
}
.item-group .publish-status {
  display: inline-block;
  padding: 1px 5px;
  margin: 10px 0 5px 20px;
  font-size: 10px;
  line-height: 16px;
  color: #fff;
  background: #999999;
  border-radius: 2px;
}
.item-group .publish-status.draft {
  text-transform: uppercase;
}
.item-group .publish-status.scheduled .icon-time {
  margin-top: -1px;
}
.item-group .top-padding-25 {
  padding-top: 25px;
}
.item-group .left-margin-35 {
  margin-left: 35px;
}
.item-group .knob-container {
  position: absolute;
  top: 5px;
  right: 5px;
  opacity: 0;
  -webkit-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;
  cursor: pointer;
}
.item-group .knob-container.active {
  opacity: 1;
}
.item-group .knob-container .knob-overlay {
  position: absolute;
  top: 3px;
  right: 2px;
  width: 27px;
  height: 26px;
  border-radius: 99px;
  background-color: black;
  opacity: 0.6;
}
.item-group .knob-container .dial .circle-text {
  font-size: 12px !important;
  height: 13px !important;
  margin-left: -3px !important;
  margin-top: -2px !important;
  color: #87ceeb;
}
.item-group .knob-container input {
  cursor: pointer;
  top: -17px;
  right: 5px;
}
.item-group.item-hidden {
  opacity: 0.5;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
}
.item-group.item-hidden .item-placeholder.tile.single.no-img .description .friendly-timestamp {
  margin-top: 20px;
}
.item-group.item-hidden .item-placeholder.tile.single.no-img .description .publish-status {
  margin-top: 30px;
}
.item-group.item-hidden .item-feature-link {
  display: none;
}
.item-group.item-hidden .item-feature-link-new {
  display: none;
}
.item-group.item-hidden:hover {
  opacity: 0.8;
}
.item-group.item-hidden:before {
  content: "Hidden Item";
  background: #fff;
  background: rgba(255, 255, 255, 0.8);
  padding: 0px 6px;
  position: absolute;
  font-size: 10px;
  margin: 1px;
  border: 1px solid #ccc;
  border-top: 0;
  border-left: 0;
}
.item-group.item-hidden.item-cta {
  opacity: 0.5;
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  filter: grayscale(0%);
}
.item-group.item-hidden.item-cta:before {
  content: "Hidden CTA Placement";
}
.item-group.sortable-moving {
  opacity: 0.5;
}
.item-group .collection-item-controls.deleted-toolbar {
  display: none;
}
.item-group.item-deleted .collection-item-controls.alive-toolbar {
  display: none;
}
.item-group.item-deleted .collection-item-controls.deleted-toolbar {
  display: block;
}
.item-group .collection-item-controls {
  background-color: #ddd;
  border-color: #ddd;
  position: relative;
}
.item-group .collection-item-controls a {
  background: transparent;
  border-color: transparent;
  font-weight: normal;
  padding: 2px;
  text-shadow: none;
}
.item-group .collection-item-controls a:hover {
  background: #555;
}
.item-group .collection-item-controls a:hover,
.item-group .collection-item-controls a:focus {
  color: #fff;
}
.item-group .collection-item-controls i.nb-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-image: url("/img/hubs/backend/spritesheet.png");
}
.item-group .collection-item-controls i.nb-icon.nb-integrations {
  position: relative;
  top: 3px;
  left: auto;
  right: auto;
  bottom: auto;
  width: 15px;
  height: 15px;
  margin: 0 6px 0 5px;
  background-position: -133px -453px;
}
.item-group .collection-item-controls .more-item-options {
  display: inline-block;
  height: 0;
}
.item-group .collection-item-controls .more-item-options ul {
  position: absolute;
  display: none;
  left: 0;
  bottom: 100%;
  width: 100%;
  list-style: none;
  background: #999;
}
.item-group .collection-item-controls .more-item-options ul li {
  line-height: 33px;
}
.item-group .collection-item-controls .more-item-options ul li:hover {
  background-color: #aaa;
}
.item-group .collection-item-controls .more-item-options ul li:hover a {
  color: #555;
  text-decoration: none;
  background-color: #aaa;
}
.item-group .collection-item-controls .more-item-options ul li:hover a i {
  background-image: url("/img/glyphicons-halflings.png");
  opacity: 0.6;
}
.item-group .collection-item-controls .more-item-options ul li:hover a i.nb-icon {
  background-image: url("/img/hubs/backend/spritesheet.png");
  background-position: -172px -452px;
}
.item-group .collection-item-controls .more-item-options ul li a {
  color: white;
  font-size: 12px;
  line-height: 33px;
  width: 100%;
  padding: 0;
  display: inline-block;
}
.item-group .collection-item-controls .more-item-options ul li a span {
  padding: 7px;
}
.item-group .collection-item-controls .more-item-options ul li a span i {
  margin-right: 7px;
}
.item-group .collection-item-controls .more-item-options .up-arrow {
  position: relative;
  display: none;
  top: -26px;
  left: 5px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #999;
}
.item-group .collection-item-controls .more-item-options.open ul {
  display: block;
}
.item-group .collection-item-controls .more-item-options.open .up-arrow {
  display: block;
}
.item-group:hover {
  -webkit-box-shadow: 0 0 10px #888888;
  box-shadow: 0 0 10px #888888;
}
.item-group:hover .collection-item-controls {
  background: #333;
  border-color: #333;
}
.item-group:hover .collection-item-controls a:hover {
  background: #666;
  border-color: #666;
}
.item-group > a.add-custom-item-link,
.item-group > a.add-items-to-section-link {
  display: block;
  width: 225px;
  height: 291px;
  text-align: center;
  background: #fff url("/img/icon_128/plus.png") no-repeat center center;
  background-size: 100px;
  border: 1px solid #ccc;
  color: #fff;
  line-height: 423px;
  opacity: 0.6;
  -webkit-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}
.item-group > a.add-custom-item-link:hover,
.item-group > a.add-items-to-section-link:hover {
  opacity: 1;
  color: #666;
  background-position: center 90px;
}
.sortable-item-helper {
  width: 225px;
  height: 293px;
  border: 2px solid rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
}
@-moz-document url-prefix() {
  .item-group .knob-container input {
    top: -16px;
  }
}
.reorder-stream-sections.reordering {
  background: #2f96b4;
  border-color: #2f96b4;
}
.reorder-stream-sections.reordering:not(.disabled):hover,
.reorder-stream-sections.reordering:active {
  background: #0e90d2 !important;
  border-color: #0e90d2 !important;
}
.collection-section .collection-section-header {
  float: left;
  width: 100%;
  padding: 6px 0 0 15px;
  font-size: 17px;
  font-weight: bold;
}
.collection-section .collection-section-header .collection-section-name {
  display: inline-block;
  padding: 6px;
}
.collection-section .collection-section-header .collection-section-name-input {
  font-size: 17px;
}
.collection-section .collection-section-header .edit-collection-section-name,
.collection-section .collection-section-header .delete-collection-section {
  background: none;
  border: none;
}
.collection-section .collection-section-header .edit-collection-section-name span,
.collection-section .collection-section-header .delete-collection-section span {
  color: #ccc;
}
.collection-section .collection-section-header .edit-collection-section-name span:hover,
.collection-section .collection-section-header .delete-collection-section span:hover {
  color: #333;
}
.collection-section .collection-section-header hr {
  border-top: 1px solid #cccccc;
  margin: 6px 0;
}
.collection-section .collection-section-items {
  float: left;
  width: 100%;
}
.reordering-sections .collection-section .collection-section-header {
  cursor: move;
}
.reordering-sections .collection-section .collection-section-items {
  display: none;
}
.collection-section::after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}
.hubs_collection_item_placeholder {
  display: inline-block;
  width: 24%;
  height: 172px;
  margin: 15px 0 0 0.75%;
  visibility: visible;
  border: 2px dashed #cccccc;
  background: #fafafa;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.hubs_item_placeholder {
  float: left;
  display: inline-block;
  width: 225px;
  height: 293px;
  margin: 20px 0 10px 15px;
  visibility: visible;
  border: 2px dashed #cccccc;
  background: #fafafa;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.stream_section_placeholder {
  display: inline-block;
  height: 42px;
  width: 100%;
  margin: 10px 0 0 10px;
  visibility: visible;
  border: 2px dashed #cccccc;
  background: #fafafa;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ui-sortable .item-group.rearranging,
.ui-sortable .collection-group.rearranging {
  cursor: move;
}
.collection-wrapper {
  display: none;
}
.collection-wrapper.active {
  display: block;
}
.collection_service_data {
  margin: 50px 0 0 0;
  padding: 15px;
  color: #666666;
  background: #fafafa;
  border: 1px solid #eeeeee;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.collection_service_data span.info-sign {
  position: relative;
  top: -12px;
  left: -12px;
  right: auto;
  bottom: auto;
  color: #999999;
}
.collection_service_data .small {
  margin-top: 8px;
  padding-top: 4px;
  border-top: 1px solid #dddddd;
}
.collection_service_data a {
  color: #444444 !important;
  text-decoration: underline;
}
.item-group .overlay-options {
  display: none;
}
.item-group.with-options .item-action-controls {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
}
.item-group.with-options .item-action-controls .circle-icon {
  position: relative;
  float: left;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin: 7px 0 0 7px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  text-align: center;
  line-height: 25px;
  background-color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.item-group.with-options .item-action-controls .circle-icon.is-gated {
  background-color: #66be63;
  border-color: #fff;
}
.item-group.with-options .item-action-controls .circle-icon:hover {
  background-color: #fff;
}
.item-group.with-options .item-action-controls .circle-icon:hover .bh-icon-lock-cta.icon-white {
  background-position: -534px -574px;
}
.item-group.with-options .item-action-controls .knob-container ~ .circle-icon {
  right: 40px;
}
.item-group.with-options .item-action-controls .select-item {
  display: inline-block;
  position: relative;
  float: right;
  height: 25px;
  width: 25px;
  margin: 7px;
  background: #fff;
  border-radius: 50%;
  border: 2px solid #666;
  line-height: 25px;
  cursor: pointer;
}
.item-group.with-options .item-action-controls .select-item .halflings-ok {
  opacity: 0;
  margin-left: 4px;
  top: -1px;
}
.item-group.with-options.selected .select-item .halflings-ok {
  opacity: 1;
}
.item-group.with-options .halflings-item-tag {
  display: inline-block;
  background: url("/img/hubs/backend/spritesheet.png") -14px -54px;
  width: 13px;
  height: 13px;
}
.item-group.with-options .halflings-item-tag i {
  display: block;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: absolute;
  right: -7px;
  top: -7px;
  width: 12px;
  height: 12px;
  padding-right: 2px;
  padding-top: 1px;
  font-weight: 800;
  font-size: 9px;
  font-style: normal;
  text-align: center;
  color: #fff;
}
.item-group.with-options .halflings-item-tag.empty {
  background-position: -14px -13px;
}
.item-group.with-options .halflings-item-tag.empty i {
  display: none;
}
.item-group.with-options:hover .item-placeholder.tile.single {
  border: 1px solid #000;
}
.item-group.with-options:hover .overlay-options {
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.7;
}
.item-group.with-options:hover .overlay-options.holding-options {
  background-color: initial;
  opacity: 1;
  color: #fff;
}
.item-group.with-options:hover .overlay-options.holding-options .option-box {
  position: relative;
  top: 125px;
  left: auto;
  right: auto;
  bottom: auto;
  display: block;
  width: 120px;
  margin: 0 auto;
  padding: 10px 0;
  border: 1px solid #fff;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.item-group.with-options:hover .overlay-options.holding-options .option-box:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
.item-group.with-options:hover .overlay-options.holding-options .option-box .option-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.item-placeholder,
.item-placeholder.tile,
.item-placeholder.tile.single {
  float: none !important;
  width: 100%;
  height: 260px;
  min-height: 260px;
  margin: 0 !important;
  overflow: hidden;
  background-color: #ebe8e1;
  border: 1px solid #ddd;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.item-placeholder.tile .img img,
.item-placeholder.tile.single .img img {
  width: 100% !important;
}
.item-placeholder.tile .description h3,
.item-placeholder.tile.single .description h3 {
  font-size: 17px !important;
  font-weight: normal !important;
}
.item-placeholder.tile .description h4,
.item-placeholder.tile.single .description h4 {
  font-size: 12px !important;
  line-height: 17px !important;
}
.item-placeholder.tile .img img.shorter {
  height: 100% !important;
  width: auto !important;
}
.item-placeholder.tile.single .description img.avatar {
  height: 20px !important;
  margin-top: 5px !important;
}
.item-placeholder.tile.single .description h3.avatar {
  margin-top: 0 !important;
}
.item-preview-bg {
  position: relative;
  width: 225px;
  height: 260px;
  margin-top: -260px;
  background: transparent url("/img/hubs/backend/preview_bg.png") no-repeat top right;
}
.lock-item {
  position: relative;
  width: 220px;
  height: 255px;
  margin-top: -255px;
  cursor: pointer;
}
.lock-item.open-lock {
  background: transparent url("/img/lock_open.gif") no-repeat top right;
}
.lock-item.closed-lock {
  background: transparent url("/img/lock_closed.gif") no-repeat top right;
}
.lock-item.fade-lock {
  background: transparent url("/img/lock_closed.gif") no-repeat top right;
  opacity: 0.5;
}
.hubs_share_icon {
  float: left;
  clear: none;
  width: 22px;
  height: 20px;
  background: transparent url("/img/hubs/backend/spritesheet2.png") no-repeat 0 0;
  opacity: 0.5;
}
.hubs_share_icon.facebook {
  background-position: -312px -10px;
}
.hubs_share_icon.twitter {
  background-position: center;
  background-image: url("/img/integrations/twitter_x_logo.svg");
  background-size: contain;
}
.hubs_share_icon.email {
  background-position: -421px -10px;
}
.hubs_share_icon.google {
  background-position: -476px -10px;
}
.hubs_share_icon.linkedin {
  background-position: -531px -10px;
}
.hubs_share_icon.pinterest {
  background-position: -580px -10px;
}
.hub-share-modal .modal-body p {
  width: 100%;
  font-size: 18px;
  text-align: center;
  margin-bottom: 16px;
}
.hub-share-modal .modal-body .share-item {
  display: block;
  position: relative;
  top: auto;
  right: auto;
  padding: 0;
  margin: 0 auto 30px auto;
  background-color: transparent;
  text-transform: uppercase;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.hub-share-modal .modal-body .share-item:before,
.hub-share-modal .modal-body .share-item:after {
  display: table;
  content: " ";
}
.hub-share-modal .modal-body .share-item:after {
  clear: both;
}
.hub-share-modal .modal-body .share-item:before {
  display: none;
}
.hub-share-modal .modal-body .share-item li:first-child {
  width: auto;
  background: transparent;
}
.hub-share-modal .modal-body .share-item li a.on:hover {
  background-color: transparent;
}
.collection-share-modal > .modal-dialog > .modal-content {
  width: 620px;
  border: none;
  background: none;
}
.collection-share-modal > .modal-dialog > .modal-content .modal-dialog {
  margin: 0;
}
#itemShareModal .modal-dialog,
#collectionShareModal .modal-dialog,
.collection-share-modal .modal-dialog {
  width: 620px;
}
#itemShareModal .modal-dialog .modal-body .share-options,
#collectionShareModal .modal-dialog .modal-body .share-options,
.collection-share-modal .modal-dialog .modal-body .share-options {
  margin: 5px auto 0;
  width: 330px;
}
#itemShareModal .modal-dialog .modal-body .cloned-item-holder,
#collectionShareModal .modal-dialog .modal-body .cloned-item-holder,
.collection-share-modal .modal-dialog .modal-body .cloned-item-holder {
  width: 225px;
  display: inline-block;
}
#itemShareModal .modal-dialog .modal-body .cloned-item-holder .circle-icon.gate-item,
#collectionShareModal .modal-dialog .modal-body .cloned-item-holder .circle-icon.gate-item,
.collection-share-modal .modal-dialog .modal-body .cloned-item-holder .circle-icon.gate-item {
  display: none;
}
#itemShareModal .modal-dialog .modal-body .cloned-item-holder + div.share-options,
#collectionShareModal .modal-dialog .modal-body .cloned-item-holder + div.share-options,
.collection-share-modal .modal-dialog .modal-body .cloned-item-holder + div.share-options {
  display: inline-block;
  width: 330px;
  margin-left: 20px;
  margin-top: 27px;
  vertical-align: top;
}
.hubs-block-image {
  display: block;
  width: 180px;
  height: 180px;
  margin: 0 auto 20px auto;
  padding: 12px;
  cursor: pointer;
  background: #fff url("/img/hubs/addons-block-bg.jpg") no-repeat 0 0;
  border: 1px solid #d5d5d5;
  -webkit-box-shadow: 1px 1px 3px #eee;
  box-shadow: 1px 1px 3px #eee;
  border-radius: 5px;
}
.hubs-block-image.google-analytics {
  background-position: 0px -600px;
}
.hubs-block-image.commenting {
  background-position: 0px -800px;
}
.hubs-block-image.sharing {
  background-position: 0px -1000px;
}
.hubs-block-image.hubs-lab {
  background-position: 0px -1200px;
}
.section-icon {
  display: inline-block;
  width: 22px;
  height: 20px;
  vertical-align: text-top;
  line-height: 20px;
  background: transparent url("/img/hubs/backend/spritesheet2.png") no-repeat;
}
.section-icon.home {
  background-position: -494px -156px;
}
.section-icon.docs {
  background-position: -256px -156px;
}
.section-icon.social {
  background-position: -303px -156px;
}
.section-icon.videos {
  background-position: -350px -156px;
}
.section-icon.blogs {
  background-position: -395px -156px;
}
.section-icon.featured {
  background-position: -443px -156px;
}
.section-icon.custom {
  background-position: -549px -156px;
}
.modal .modal-body .custom-collection-list > li > a {
  font-size: 14px;
  padding-left: 5px;
  color: #666666;
}
.modal .modal-body .custom-collection-list > li > a > .check-container {
  padding: 2px 3px;
  margin-right: 6px;
  background: #fff;
  border-radius: 50%;
  border: 2px solid #cccccc;
}
.modal .modal-body .custom-collection-list > li > a > .check-container > .halflings {
  opacity: 0;
}
.modal .modal-body .custom-collection-list > li.active > a {
  color: #2f96b4;
  background: #eeeeee;
}
.modal .modal-body .custom-collection-list > li.active > a > .check-container {
  color: #666666;
  border-color: #666666;
}
.modal .modal-body .custom-collection-list > li.active > a > .check-container > .halflings {
  opacity: 1;
}
form.author-form .uploaded-avatar .uploaded-avatar-inner {
  width: 150px;
  height: 150px;
}
form.author-form .uploaded-avatar .uploaded-avatar-inner img.uploaded-image {
  width: 100%;
  border-radius: 50%;
}
form.author-form .uploaded-avatar .upload-button-container {
  width: 85px;
  margin-top: -35px;
  margin-left: 36px;
  opacity: 0;
  -webkit-transition: opacity 200ms ease-in-out;
  -o-transition: opacity 200ms ease-in-out;
  transition: opacity 200ms ease-in-out;
}
form.author-form .uploaded-avatar:hover .upload-button-container {
  opacity: 1;
}
.ie9 form.author-form .progress {
  background: transparent url("/img/loading_small.gif") no-repeat center;
  opacity: 1;
}
.ie9 form.author-form .progress .bar {
  display: none;
}
@media only screen and (min-width: 1300px) and (max-width: 1350px) {
  .hubs_collection_search input {
    width: 90px;
  }
  .hubs_collection_search input:focus {
    width: 130px;
  }
}
.navigate-period span,
.sort-performing-content span {
  display: inline-block;
  margin: 5px 18px 60px;
  cursor: pointer;
  text-transform: uppercase;
}
.navigate-period span:hover,
.sort-performing-content span:hover {
  border-bottom: 6px solid #eee;
}
.navigate-period span.active,
.sort-performing-content span.active {
  border-bottom: 6px solid #5bc0de;
  font-weight: bold;
}
.navigate-period span.missing-data,
.sort-performing-content span.missing-data {
  color: #ccc;
}
.navigate-period.sales-activity span,
.sort-performing-content.sales-activity span {
  padding: 16px 16px 13px 16px;
  margin: 5px 0 30px;
  text-transform: capitalize;
  color: #333;
}
.navigate-period.sales-activity span:hover:not(.active),
.sort-performing-content.sales-activity span:hover:not(.active) {
  color: #777;
  border-bottom: none;
}
.navigate-period.sales-activity span.active,
.sort-performing-content.sales-activity span.active {
  border-bottom: 3px solid #ce0058;
}
.performing-item-container {
  display: block;
  text-align: left;
}
.performing-item-container .no-data-available {
  width: 819px;
  height: 300px;
  font-size: 32px;
  line-height: 253px;
  color: #333;
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
}
.performing-item-container .performing-item:hover .stat {
  background: #fff;
}
.performing-item-container .performing-item .item-wrapper {
  display: inline-block;
  position: relative;
  float: left;
}
.performing-item-container .performing-item .item-wrapper .no-item-info {
  display: inline-block;
  float: left;
  clear: none;
  width: 223px;
  height: 293px;
  margin: 20px 0 10px 15px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #ccc;
}
.performing-item-container .performing-item .item-wrapper .no-item-info .unknown {
  font-size: 200px;
  font-weight: bold;
  color: #ddd;
}
.performing-item-container .performing-item .item-wrapper .no-item-info .no-item-text {
  margin-top: 85px;
  color: #999;
  font-weight: bold;
}
.performing-item-container .performing-item .item-wrapper .content-score {
  position: absolute;
  display: block;
  top: 20px;
  left: 16px;
  height: 293px;
  width: 225px;
  text-align: center;
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.performing-item-container .performing-item .item-wrapper .content-score input {
  margin-top: 39px !important;
}
.performing-item-container .performing-item .item-wrapper .content-score > div {
  position: absolute;
  top: 38px;
  left: 18px;
}
.performing-item-container .performing-item .item-wrapper .content-score .circle-text {
  color: #5bc0de;
  color: #87ceeb;
  font-size: 30px;
  font-weight: bold;
  top: -11px;
  letter-spacing: 1px;
}
.performing-item-container .performing-item .item-wrapper .content-score .overlay-content {
  position: absolute;
  display: block;
  top: 54px;
  left: 33px;
  height: 160px;
  width: 160px;
  border-radius: 50%;
  background-color: #000;
  opacity: 0.5;
}
.performing-item-container .performing-item .item-wrapper .content-score .enter-dial {
  display: inline-block;
  position: absolute;
  top: 143px;
  right: 31px;
  width: 166px;
  font-size: 18px;
  line-height: 17px;
  color: #fff;
  text-transform: uppercase;
}
.performing-item-container .performing-item .item-wrapper .content-score .enter-dial span {
  display: block;
}
.performing-item-container .performing-item .item-wrapper:hover .content-score {
  opacity: 0;
}
.performing-item-container .performing-item .item-stats {
  float: left;
  margin-top: 20px;
  margin-left: 25px;
  width: 556px;
}
.performing-item-container .performing-item .item-stats > div .stat {
  float: left;
  width: 268px;
  height: 88px;
  padding: 10px;
  margin: 0px 10px 15px 0px;
  border: 1px solid #ccc;
  text-transform: uppercase;
}
.performing-item-container .performing-item .item-stats > div .stat.selected {
  border-color: #5bc0de;
  background: #fff;
}
.performing-item-container .performing-item .item-stats > div .stat .stat-type,
.performing-item-container .performing-item .item-stats > div .stat .stat-number {
  display: inline-block;
  height: 100%;
  padding: 25px 0 10px 7px;
  vertical-align: middle;
  text-align: center;
}
.performing-item-container .performing-item .item-stats > div .stat .stat-img {
  margin: 8px 1px;
  font-size: 39px;
  color: #828282;
  padding: 0px 6px;
}
.performing-item-container .performing-item .item-stats > div .stat .stat-type {
  width: 89px;
  color: #555;
}
.performing-item-container .performing-item .item-stats > div .stat .stat-type.two-lines {
  padding: 15px 0 10px 7px;
}
.performing-item-container .performing-item .item-stats > div .stat .stat-number {
  width: 75px;
  padding: 20px 5px 10px 0;
  text-align: right;
  font-size: 18px;
  color: #5bc0de;
}
.performing-item-container .performing-item .item-stats > div .stat.full {
  width: 524px;
}
.performing-item-container .performing-item .item-stats > div .stat.full .stat-type {
  width: 213px;
  font-size: 21px;
  margin-top: 12px;
  margin-left: 71px;
}
.performing-item-container .performing-item .item-stats > div .stat.full .stat-number {
  font-size: 25px;
  width: 147px;
}
@-moz-document url-prefix() {
  .performing-item-container .performing-item .item-wrapper .content-score input {
    margin-top: 90px !important;
  }
}
.item-feature-link-new {
  display: inline-block;
  position: relative;
  float: left;
  height: 25px;
  width: 25px;
  margin: 7px 0 0 7px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  line-height: 25px;
  background-color: rgba(0, 0, 0, 0.25);
  text-align: left;
  cursor: pointer;
  transition: 0.2s all ease-in;
  color: #fff;
  overflow: hidden;
}
.item-feature-link-new:hover:not(.active-animate) {
  color: #444;
  background-color: #fff;
}
.item-feature-link-new i {
  margin-left: 4.5px;
  margin-top: -2px;
  top: 0;
  transition: 0.1s all ease-out;
}
.item-feature-link-new.active i {
  color: #fbc02d;
  will-change: transform;
  animation: featuredstar 1s cubic-bezier(0.17, 0.89, 0.32, 1.45);
}
.item-feature-link-new.active-animate {
  transition: 1s all;
  animation-iteration-count: 1;
  animation: widen 3s ease-out;
  animation-fill-mode: forwards;
}
.item-feature-link-new span {
  position: relative;
  top: -2px;
  opacity: 0;
  color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.item-feature-link-new span.span-animate {
  animation-iteration-count: 1;
  animation: fadeInOut 3s ease-in-out;
  animation-fill-mode: forwards;
}
@keyframes featuredstar {
  0%,
  17.5% {
    transform: scale(0);
  }
}
@keyframes fadeInOut {
  0% {
    opacity: 0;
    left: 10px;
  }
  10% {
    opacity: 1;
    left: 15px;
  }
  85% {
    opacity: 1;
    left: 15px;
  }
  95% {
    opacity: 0;
    left: 10px;
  }
}
@keyframes widen {
  8% {
    width: 100px;
  }
  90% {
    width: 100px;
  }
  100% {
    width: 25px;
  }
}
.featuring-disabled,
.featuring-disabled:hover {
  color: #cacaca;
  background-color: #999;
  cursor: not-allowed;
}
.item-to-streams-modal-wrapper .selected-item-details {
  display: inline-block;
  margin: 10px 0 20px;
}
.item-to-streams-modal-wrapper .selected-item-details .bg-thumb {
  float: left;
  margin-right: 10px;
}
.item-to-streams-modal-wrapper .selected-item-details .bg-thumb:before,
.item-to-streams-modal-wrapper .selected-item-details .bg-thumb:after {
  display: table;
  content: " ";
}
.item-to-streams-modal-wrapper .selected-item-details .bg-thumb:after {
  clear: both;
}
.item-to-streams-modal-wrapper span.color-box-service-icon {
  margin-right: -3px;
}
.item-to-streams-modal-wrapper span.item-title,
.item-to-streams-modal-wrapper span.source-stream-hub {
  display: block;
  margin-top: 1px;
  font-size: 12px;
  width: 650px;
}
.item-to-streams-modal-wrapper span.item-title {
  font-weight: bold;
}
.item-to-streams-modal-wrapper span.source-stream-hub {
  color: #999;
}
.item-to-streams-modal-wrapper span.source-stream-hub .title {
  font-weight: bold;
}
.item-to-streams-modal-wrapper .inner-addon .halflings {
  position: absolute;
  padding: 10px;
  pointer-events: none;
}
.item-to-streams-modal-wrapper .inner-addon input {
  padding-left: 30px;
  font-size: 14px;
}
.item-to-streams-modal-wrapper .selected-streams-label {
  font-size: 14px;
  font-weight: bold;
}
.item-to-streams-modal-wrapper .stream-list-container,
.item-to-streams-modal-wrapper .stream-list-selected-container {
  width: 100%;
  height: 300px;
  padding: 5px;
  border: 1px solid #ccc;
  overflow-y: auto;
  background-color: #fff;
}
.item-to-streams-modal-wrapper .stream-list-container table,
.item-to-streams-modal-wrapper .stream-list-selected-container table {
  width: 100%;
}
.item-to-streams-modal-wrapper .stream-list-container table tr:hover,
.item-to-streams-modal-wrapper .stream-list-selected-container table tr:hover {
  cursor: pointer;
  background-color: #f2f2f2;
}
.item-to-streams-modal-wrapper .stream-list-container table td,
.item-to-streams-modal-wrapper .stream-list-selected-container table td {
  padding: 5px 0;
}
.item-to-streams-modal-wrapper .stream-list-container table td.thumb,
.item-to-streams-modal-wrapper .stream-list-selected-container table td.thumb {
  width: 36%;
}
.item-to-streams-modal-wrapper .stream-list-container table td.title,
.item-to-streams-modal-wrapper .stream-list-selected-container table td.title {
  padding-left: 5px;
}
.item-to-streams-modal-wrapper .stream-list-container tr .glyphicons-remove {
  display: none;
}
.item-to-streams-modal-wrapper .stream-list-selected-container tr .glyphicons-arrow-right {
  display: none;
}
.item-to-streams-modal-wrapper .item-thumb {
  width: 84px;
  height: 40px;
  vertical-align: middle;
  background-size: cover;
  background-position: 50%;
  display: inline-block;
}
.modal.reco-rules-modal ul,
.modal.delete-stream-widget-error-modal ul {
  padding: 0 20px;
}
.modal.reco-rules-modal ul li,
.modal.delete-stream-widget-error-modal ul li {
  margin-left: 1.1em;
  list-style: initial;
}
.mgmt-container .content-container.hubs-sections-embed .embed-navbar span.nb-icon i {
  background-image: url("/img/hubs/backend/spritesheet.png");
}
.mgmt-container .content-container.hubs-sections-embed .embed-navbar span.nb-icon.nb-embed-hub i {
  background-position: -444px -283px;
}
.mgmt-container .content-container.hubs-sections-embed .embed-navbar span.nb-icon.nb-embed-iframe i {
  background-position: -285px -286px;
}
.mgmt-container .content-container.hubs-sections-embed .embed-navbar span.nb-icon.nb-embed-close i {
  background-position: -126px -283px;
}
.mgmt-container .content-container.hubs-sections-embed .embed-navbar .nav .active > a:not(:hover) .nb-embed-hub i {
  background-position: -444px -321px;
}
.mgmt-container .content-container.hubs-sections-embed .embed-navbar .nav .active > a:not(:hover) .nb-embed-iframe i {
  background-position: -285px -323px;
}
.mgmt-container .content-container.hubs-sections-embed .embed-navbar .nav .active > a:not(:hover) .nb-embed-close i {
  background-position: -127px -322px;
}
.mgmt-container .content-container.hubs-sections-embed .tab-pane {
  margin: 0 auto;
}
.mgmt-container .content-container.hubs-sections-embed .tab-pane#hubs-embed-iframe,
.mgmt-container .content-container.hubs-sections-embed .tab-pane#hubs-embed-collection {
  max-width: 1120px;
}
.mgmt-container .content-container.hubs-sections-embed .tab-pane#hubs-embed-iframe .col-md-5,
.mgmt-container .content-container.hubs-sections-embed .tab-pane#hubs-embed-collection .col-md-5 {
  width: 37%;
}
.mgmt-container .content-container.hubs-sections-embed .tab-pane#hubs-embed-iframe .col-md-7,
.mgmt-container .content-container.hubs-sections-embed .tab-pane#hubs-embed-collection .col-md-7 {
  width: 60%;
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure .embed-header h3,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .embed-header h3 {
  color: #555;
  text-align: center;
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure .embed-header h3 i,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .embed-header h3 i {
  margin-top: 3px;
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure .form-inline,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .form-inline,
.mgmt-container .content-container.hubs-sections-embed .embed-configure .form-horizontal,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .form-horizontal {
  min-height: 290px;
  border: 2px solid #ddd;
  padding: 0px 10px;
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure .form-horizontal .input-lock-to-url,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .form-horizontal .input-lock-to-url {
  display: inline-block;
  width: 365px;
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure .form-horizontal #embedIframeLockBtn,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .form-horizontal #embedIframeLockBtn {
  display: inline-block;
  margin-top: -5px;
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure .embed-body,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .embed-body {
  padding: 10px 0 0 0;
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure .embed-body textarea,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .embed-body textarea {
  width: 100%;
  min-height: 170px;
  margin: 6px 0;
  font-size: 10px;
  -webkit-transition: all linear 0.2s;
  -o-transition: all linear 0.2s;
  transition: all linear 0.2s;
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure .embed-body textarea:focus,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .embed-body textarea:focus {
  background: #f5f5f5;
  border-color: #ddd;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure .embed-body .embedIframeTextarea,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .embed-body .embedIframeTextarea {
  min-height: 187px;
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure .embed-body .longNote,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .embed-body .longNote {
  margin: 1px 0 0 0;
  padding: 1px 6px 1px 24px;
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure .preview-block,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .preview-block {
  width: 100%;
  height: 380px;
  border-width: 2px;
  text-align: center;
  overflow: hidden;
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure .preview-block .mixed-content-help,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .preview-block .mixed-content-help {
  display: none;
  margin: 20px;
  padding: 20px 20px 0 20px;
  background: #f9f9f9;
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure .preview-block.allow-overflow,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .preview-block.allow-overflow {
  overflow: auto;
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure .alert,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .alert {
  margin: 0 0 10px 0;
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure .inline-label,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .inline-label {
  font-weight: normal;
  color: #444;
  font-size: 12.5px;
  padding: 0;
  cursor: pointer;
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure .inline-label input,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .inline-label input {
  position: static;
  margin: 0 10px 0 0;
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure .form-group,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .form-group {
  margin-left: 0;
  margin-right: 0;
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure .form-group:before,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .form-group:before,
.mgmt-container .content-container.hubs-sections-embed .embed-configure .form-group:after,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .form-group:after {
  display: table;
  content: " ";
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure .form-group:after,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .form-group:after {
  clear: both;
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure .form-group > label,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .form-group > label {
  color: #777;
  margin: 10px 0 5px 0;
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure .form-group .control-label,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .form-group .control-label {
  width: 140px;
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure .form-group .note,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .form-group .note {
  margin: 0;
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure .form-group .btn.pull-right,
.mgmt-container .content-container.hubs-sections-embed .embed-preview .form-group .btn.pull-right {
  margin-top: 10px;
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure #heg_form .form-group .control-label,
.mgmt-container .content-container.hubs-sections-embed .embed-preview #heg_form .form-group .control-label {
  width: 120px;
}
.mgmt-container .content-container.hubs-sections-embed .embed-configure #heg_form .form-group .controls,
.mgmt-container .content-container.hubs-sections-embed .embed-preview #heg_form .form-group .controls {
  margin-left: 140px;
}
.mgmt-container .content-container.hubs-sections-embed #hubs-embed-collection .form-horizontal .form-group {
  margin-bottom: 10px;
}
.mgmt-container .content-container.hubs-sections-embed #hubs-embed-collection .form-horizontal .form-group .controls,
.mgmt-container .content-container.hubs-sections-embed #hubs-embed-collection .form-horizontal .form-group .control-label {
  display: inline-block;
  margin: 0;
}
.mgmt-container .content-container.hubs-sections-embed #hubs-embed-iframe .alert {
  margin-left: 0;
}
.mgmt-container .content-container.hubs-sections-embed #hubs-embed-iframe .embed-configure .form-horizontal {
  height: 180px;
}
.mgmt-container .content-container.hubs-sections-embed #hubs-embed-iframe .embed-preview .preview-block {
  height: auto;
}
.mgmt-container .content-container.hubs-sections-embed #hubs-embed-iframe .embed-configure .form-horizontal,
.mgmt-container .content-container.hubs-sections-embed #hubs-embed-iframe .embed-preview .form-horizontal {
  height: 200px;
  margin-bottom: 20px;
}
.mgmt-container .content-container.hubs-sections-embed #hubs-embed-iframe .embed-configure .form-horizontal .embed-body textarea,
.mgmt-container .content-container.hubs-sections-embed #hubs-embed-iframe .embed-preview .form-horizontal .embed-body textarea {
  height: 130px;
}
/*Custom UF icons */
.glyphicons-uf-pipe:before {
  content: "|";
  font-weight: 700;
}
#item-score-modal .modal-dialog {
  width: 852px;
}
#item-score-modal .navigate-period span {
  margin: 20px;
  text-transform: capitalize;
}
#item-score-modal .performing-item-container .item-wrapper .content-score {
  height: 260px;
}
#item-score-modal .performing-item-container .item-group .item-placeholder {
  border-bottom-width: 2px;
}
#item-score-modal .performing-item-container .item-group .collection-item-controls {
  display: none;
}
#item-score-modal .performing-item-container .item-group:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
#item-score-modal .performing-item-container .performing-item .item-stats {
  margin-left: 10px;
}
#item-score-modal .performing-item-container .performing-item .item-stats > div .stat {
  height: 77px;
}
#item-score-modal .performing-item-container .performing-item .item-stats > div .stat .stat-type {
  padding-top: 20px;
}
#item-score-modal .performing-item-container .performing-item .item-stats > div .stat .stat-number {
  padding-top: 15px;
}
#item-score-modal .no-data-available {
  padding: 100px;
  text-align: center;
  font-weight: bold;
  font-size: 17px;
}
#item-score-modal .loading-scores {
  padding: 56px;
  font-size: 20px;
  text-align: center;
  color: #999;
}
#item-score-modal .loading-scores .loading-sprite {
  margin-bottom: 41px;
}
#item-params-modal .typeahead.dropdown-menu a {
  width: 244px;
}
#item-params-modal .knob-container {
  display: none;
}
#item-params-modal .integrations {
  padding-bottom: 10px;
}
#item-params-modal .integrations .integration-setup-sprite {
  display: inline-block;
}
#item-params-modal .item-params-item-container {
  margin-top: 25px;
  padding-top: 25px;
  border-top: 1px solid #eee;
  text-align: center;
}
#item-params-modal .item-params-item-container > .cloned-item-holder {
  display: inline-block;
  text-align: left;
  width: 225px;
}
#item-params-modal .container-fluid {
  text-align: center;
}
#item-params-modal .container-fluid .item-param-note {
  padding-bottom: 20px;
  text-align: left;
}
#item-params-modal .container-fluid .item-param-row {
  width: 420px;
  margin: 0 auto;
  padding-bottom: 5px;
}
#item-params-modal .container-fluid .item-param-row.item-param-new {
  margin-top: 20px;
}
#item-params-modal .container-fluid .item-param-row.item-param-title div {
  font-weight: bold;
}
#item-params-modal .container-fluid .item-param-row .item-param-id {
  display: inline-block;
  width: 130px;
}
#item-params-modal .container-fluid .item-param-row .item-param-id input {
  width: 100%;
}
#item-params-modal .container-fluid .item-param-row .item-param-value {
  padding-left: 15px;
  display: inline-block;
  width: 145px;
}
#item-params-modal .container-fluid .item-param-row .item-param-value input {
  width: 100%;
}
#item-params-modal .container-fluid .item-param-row .item-param-options {
  padding-left: 15px;
  display: inline-block;
  width: 105px;
}
#item-params-modal .container-fluid .item-param-row .item-param-options a {
  width: 74px;
  margin-top: -5px;
}
.mgmt-container .hubs-section-tags .table.collection-table,
.mgmt-container .account-section-upgrade-requests .table.collection-table {
  margin-top: 20px;
}
.mgmt-container .hubs-section-tags .table.collection-table thead > tr > th,
.mgmt-container .account-section-upgrade-requests .table.collection-table thead > tr > th {
  padding: 8px 0;
  white-space: nowrap;
  border-color: #ccc;
}
.mgmt-container .hubs-section-tags .table.collection-table thead > tr > th.sorting_asc,
.mgmt-container .account-section-upgrade-requests .table.collection-table thead > tr > th.sorting_asc,
.mgmt-container .hubs-section-tags .table.collection-table thead > tr > th.sorting_desc,
.mgmt-container .account-section-upgrade-requests .table.collection-table thead > tr > th.sorting_desc {
  -webkit-filter: grayscale(100%);
  color: #48484a;
}
.mgmt-container .hubs-section-tags .table.collection-table thead > tr > th.sorting,
.mgmt-container .account-section-upgrade-requests .table.collection-table thead > tr > th.sorting {
  color: #8c8f91;
}
.mgmt-container .hubs-section-tags .table.collection-table thead > tr > th:last-child,
.mgmt-container .account-section-upgrade-requests .table.collection-table thead > tr > th:last-child {
  min-width: 160px;
}
.mgmt-container .hubs-section-tags .table.collection-table thead > tr > th.no-sort,
.mgmt-container .account-section-upgrade-requests .table.collection-table thead > tr > th.no-sort {
  background: transparent !important;
  cursor: default;
}
.mgmt-container .hubs-section-tags .table.collection-table thead > tr > th.collection-table-items,
.mgmt-container .account-section-upgrade-requests .table.collection-table thead > tr > th.collection-table-items {
  text-align: right;
  padding-right: 20px;
}
.mgmt-container .hubs-section-tags .table.collection-table tbody > tr.odd,
.mgmt-container .account-section-upgrade-requests .table.collection-table tbody > tr.odd {
  background: #fff;
}
.mgmt-container .hubs-section-tags .table.collection-table tbody > tr:hover > td,
.mgmt-container .account-section-upgrade-requests .table.collection-table tbody > tr:hover > td {
  background: #f9f9f9 !important;
  color: #000;
}
.mgmt-container .hubs-section-tags .table.collection-table tbody > tr:hover > td.collection-table-buttons > a,
.mgmt-container .account-section-upgrade-requests .table.collection-table tbody > tr:hover > td.collection-table-buttons > a {
  opacity: 1;
}
.mgmt-container .hubs-section-tags .table.collection-table td,
.mgmt-container .account-section-upgrade-requests .table.collection-table td {
  vertical-align: middle;
  padding: 0;
}
.mgmt-container .hubs-section-tags .table.collection-table td.dataTables_empty,
.mgmt-container .account-section-upgrade-requests .table.collection-table td.dataTables_empty {
  padding: 10px;
}
.mgmt-container .hubs-section-tags .table.collection-table td .halflings,
.mgmt-container .account-section-upgrade-requests .table.collection-table td .halflings,
.mgmt-container .hubs-section-tags .table.collection-table td .glyphicons,
.mgmt-container .account-section-upgrade-requests .table.collection-table td .glyphicons {
  color: #8c8f91;
}
.mgmt-container .hubs-section-tags .table.collection-table td .halflings span,
.mgmt-container .account-section-upgrade-requests .table.collection-table td .halflings span,
.mgmt-container .hubs-section-tags .table.collection-table td .glyphicons span,
.mgmt-container .account-section-upgrade-requests .table.collection-table td .glyphicons span {
  display: none;
}
.mgmt-container .hubs-section-tags .table.collection-table td .glyphicons,
.mgmt-container .account-section-upgrade-requests .table.collection-table td .glyphicons {
  vertical-align: baseline;
}
.mgmt-container .hubs-section-tags .table.collection-table td .glyphicons:before,
.mgmt-container .account-section-upgrade-requests .table.collection-table td .glyphicons:before {
  padding: 0;
}
.mgmt-container .hubs-section-tags .table.collection-table td.collection-table-type,
.mgmt-container .account-section-upgrade-requests .table.collection-table td.collection-table-type {
  text-align: center;
}
.mgmt-container .hubs-section-tags .table.collection-table td.collection-table-type > .section-icon,
.mgmt-container .account-section-upgrade-requests .table.collection-table td.collection-table-type > .section-icon {
  opacity: 0.5;
}
.mgmt-container .hubs-section-tags .table.collection-table td.collection-table-type > div,
.mgmt-container .account-section-upgrade-requests .table.collection-table td.collection-table-type > div {
  display: none;
}
.mgmt-container .hubs-section-tags .table.collection-table td.collection-table-img,
.mgmt-container .account-section-upgrade-requests .table.collection-table td.collection-table-img {
  padding: 3px 20px 3px 3px;
  width: 100px;
  color: #fff;
}
.mgmt-container .hubs-section-tags .table.collection-table td.collection-table-img > div,
.mgmt-container .account-section-upgrade-requests .table.collection-table td.collection-table-img > div {
  width: 100px;
  height: 50px;
  overflow: hidden;
}
.mgmt-container .hubs-section-tags .table.collection-table td.collection-table-img > div > a img,
.mgmt-container .account-section-upgrade-requests .table.collection-table td.collection-table-img > div > a img {
  width: 100%;
}
.mgmt-container .hubs-section-tags .table.collection-table td.stream-hidden > div > a img,
.mgmt-container .account-section-upgrade-requests .table.collection-table td.stream-hidden > div > a img {
  opacity: 0.6;
}
.mgmt-container .hubs-section-tags .table.collection-table td.stream-hidden > div:before,
.mgmt-container .account-section-upgrade-requests .table.collection-table td.stream-hidden > div:before {
  content: "Hidden";
  background: #fff;
  background: rgba(255, 255, 255, 0.8);
  padding: 0px 3px;
  position: absolute;
  z-index: 1;
  font-size: 10px;
  font-weight: 700;
  border: 1px solid #eeeeee;
  color: #555;
  line-height: 13px;
  margin: -1px;
}
.mgmt-container .hubs-section-tags .table.collection-table td.collection-table-title,
.mgmt-container .account-section-upgrade-requests .table.collection-table td.collection-table-title {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  max-width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.mgmt-container .hubs-section-tags .table.collection-table td.collection-table-title.hidden,
.mgmt-container .account-section-upgrade-requests .table.collection-table td.collection-table-title.hidden {
  color: #999999;
}
.mgmt-container .hubs-section-tags .table.collection-table td.collection-table-title span.color-box-service-icon,
.mgmt-container .account-section-upgrade-requests .table.collection-table td.collection-table-title span.color-box-service-icon {
  margin: -3px 8px 0 0;
}
.mgmt-container .hubs-section-tags .table.collection-table td.collection-table-items,
.mgmt-container .account-section-upgrade-requests .table.collection-table td.collection-table-items {
  text-align: right;
  padding-right: 20px;
}
.mgmt-container .hubs-section-tags .table.collection-table td.collection-table-buttons,
.mgmt-container .account-section-upgrade-requests .table.collection-table td.collection-table-buttons {
  width: 180px;
  text-align: center;
}
.mgmt-container .hubs-section-tags .table.collection-table td.collection-table-buttons > a,
.mgmt-container .account-section-upgrade-requests .table.collection-table td.collection-table-buttons > a {
  opacity: 0;
}
.mgmt-container .hubs-section-tags .table.collection-table td.text-center,
.mgmt-container .account-section-upgrade-requests .table.collection-table td.text-center {
  padding-right: 40px;
}
.mgmt-container .hubs-section-tags .table.collection-table td span.l-padding-25,
.mgmt-container .account-section-upgrade-requests .table.collection-table td span.l-padding-25 {
  padding-left: 25px;
}
.mgmt-container .hubs-section-tags .table.collection-table div.loader-container,
.mgmt-container .account-section-upgrade-requests .table.collection-table div.loader-container {
  height: 540px;
  position: relative;
}
.mgmt-container .hubs-section-tags .table.collection-table div.loader-container .ajax-loader,
.mgmt-container .account-section-upgrade-requests .table.collection-table div.loader-container .ajax-loader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.mgmt-container .account-section-upgrade-requests .request-table-img {
  padding: 3px;
  width: 50px;
  height: 50px;
}
.mgmt-container .account-section-upgrade-requests .request-table-img .avatar-wrapper {
  height: 25px;
  padding-top: 8px;
  padding-left: 20px;
}
.mgmt-container .account-section-upgrade-requests .content-element {
  padding-left: 13px;
}
.mgmt-container .account-section-upgrade-requests .request-table-message {
  padding: 5px 0 5px 13px;
}
.mgmt-container .account-section-upgrade-requests table.table.collection-table tr > th.remove-request:last-child {
  min-width: 30px;
}
.mgmt-container #stream-options-tab .options-wrapper table.table.api-dt thead > tr > th.sorting_asc,
.mgmt-container #stream-options-tab .options-wrapper table.table.api-dt thead > tr > th.sorting_desc {
  color: #ce0058;
}
.mgmt-container #stream-options-tab .options-wrapper table.table.api-dt thead > tr > th:after {
  color: #ce0058;
}
.mgmt-container #stream-options-tab .dataTables_controls {
  display: none;
}
.mgmt-container #stream-metadata-tab {
  height: 620px;
}
.mgmt-container #stream-metadata-tab .select-container {
  min-height: 50px;
}
.mgmt-container #stream-metadata-tab .select-container .select2-container {
  width: 100%;
}
.mgmt-container #stream-metadata-tab .select-container .select2-container .select2-choice {
  height: 32px;
  background: #fff;
  padding-top: 3px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border-radius: 3px;
}
.mgmt-container #stream-metadata-tab .select-container .select2-container .select2-choice .select2-arrow {
  background: none;
  border: none;
  padding-top: 2px;
}
.mgmt-container #stream-metadata-tab .select-container .select2-container.select2-dropdown-open .select2-choice {
  border: 1px solid #5897fb;
  border-radius: 3px;
}
.mgmt-container #stream-metadata-tab #collection-metadata .seo-options-fields {
  width: 60%;
}
.mgmt-container #stream-appearance-tab,
.mgmt-container #stream-authentication-tab {
  margin-top: 20px;
}
.mgmt-container #stream-appearance-tab #ufr-dynamic-id-authentication-toggle-banner-ufr-input-container p:last-of-type,
.mgmt-container #stream-authentication-tab #ufr-dynamic-id-authentication-toggle-banner-ufr-input-container p:last-of-type {
  margin-bottom: 20px;
}
.mgmt-container #stream-appearance-tab #addAuthModal .nav-action span::before,
.mgmt-container #stream-authentication-tab #addAuthModal .nav-action span::before {
  padding-left: 5px;
}
.mgmt-container #stream-appearance-tab #addAuthModal .nav-action:hover,
.mgmt-container #stream-authentication-tab #addAuthModal .nav-action:hover {
  color: #ce0058;
}
.mgmt-container #stream-appearance-tab #addAuthModal .nav-action:hover span::before,
.mgmt-container #stream-authentication-tab #addAuthModal .nav-action:hover span::before {
  color: #ce0058;
}
.mgmt-container #stream-appearance-tab,
.mgmt-container #app-configuration-tab {
  margin-top: 30px;
}
.mgmt-container #stream-appearance-tab #searchable-select-template-list-ufr-input-container,
.mgmt-container #app-configuration-tab #searchable-select-template-list-ufr-input-container,
.mgmt-container #stream-appearance-tab #searchable-select-app-configuration-ufr-input-container,
.mgmt-container #app-configuration-tab #searchable-select-app-configuration-ufr-input-container {
  padding-top: 0px;
  padding-bottom: 0px;
}
.mgmt-container #stream-appearance-tab .ufr-appearance-options .ufr-form-section-header,
.mgmt-container #app-configuration-tab .ufr-appearance-options .ufr-form-section-header,
.mgmt-container #stream-appearance-tab .ufr-app-configuration-options .ufr-form-section-header,
.mgmt-container #app-configuration-tab .ufr-app-configuration-options .ufr-form-section-header {
  padding: 0px;
}
.mgmt-container #stream-appearance-tab .ufr-appearance-options .ufr-form-section-header div,
.mgmt-container #app-configuration-tab .ufr-appearance-options .ufr-form-section-header div,
.mgmt-container #stream-appearance-tab .ufr-app-configuration-options .ufr-form-section-header div,
.mgmt-container #app-configuration-tab .ufr-app-configuration-options .ufr-form-section-header div {
  margin-top: 30px;
}
.mgmt-container #stream-appearance-tab #ufr-dynamic-id-prospect_email-ufr-input-container,
.mgmt-container #app-configuration-tab #ufr-dynamic-id-prospect_email-ufr-input-container {
  padding-top: 0px;
}
.mgmt-container #stream-appearance-tab #ufr-dynamic-id-prospect_email-ufr-input-container label,
.mgmt-container #app-configuration-tab #ufr-dynamic-id-prospect_email-ufr-input-container label {
  padding-top: 24px;
}
.mgmt-container #stream-info-tab {
  margin-bottom: 50px;
}
.mgmt-container #stream-info-tab label {
  color: #333;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
}
.mgmt-container #stream-info-tab #eligible-owners-dropdown {
  display: none;
}
.mgmt-container #stream-info-tab #clear-owner-select {
  position: absolute;
  margin-top: 3px;
  cursor: pointer;
}
.mgmt-container #stream-info-tab #current-owner {
  height: 50px;
  padding-top: 8px;
}
.mgmt-container #stream-info-tab #current-owner-name {
  display: inline-block;
}
.mgmt-container #stream-info-tab #edit-owner span {
  margin-top: 2px;
}
.mgmt-container #stream-info-tab .select-container {
  min-height: 50px;
}
.mgmt-container #stream-info-tab .select-container .select2-container {
  width: 100%;
}
.mgmt-container #stream-info-tab .select-container .select2-container .select2-choice {
  height: 32px;
  background: #fff;
  padding-top: 3px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border-radius: 3px;
}
.mgmt-container #stream-info-tab .select-container .select2-container .select2-choice .select2-arrow {
  background: none;
  border: none;
  padding-top: 2px;
}
.mgmt-container #stream-info-tab .select-container .select2-container.select2-dropdown-open .select2-choice {
  border: 1px solid #5897fb;
  border-radius: 3px;
}
.mgmt-container #stream-info-tab .stream-type,
.mgmt-container #stream-info-tab #current-owner-name {
  color: #9c9c9c;
}
.mgmt-container #stream-info-tab .source-desc {
  margin-left: 0px !important;
}
.mgmt-container #stream-info-tab .panel-input-wrapper {
  width: 328px;
}
.mgmt-container #stream-info-tab #stream-info-footer {
  margin-top: 200px;
  border-top: solid 1px #d1d1d1;
}
.mgmt-container #stream-info-tab #stream-info-footer .stream-info-footer-content {
  margin-top: 20px;
}
.mgmt-container #stream-info-tab #stream-info-footer .stream-info-footer-content label {
  font-family: Roboto !important;
  font-size: 13px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #333;
}
.mgmt-container #stream-info-tab #stream-info-footer .stream-info-footer-content label:last-child {
  float: right;
}
.collection-nav-bar,
.privacy-groups-bar,
.generic-api-dt-util-bar {
  display: table;
  list-style: none;
  background-color: #eeeeee;
  border: 1px solid #cccccc;
}
.collection-nav-bar .glyphicons:before,
.privacy-groups-bar .glyphicons:before,
.generic-api-dt-util-bar .glyphicons:before {
  padding: 4px 1px;
  color: #989898;
}
.collection-nav-bar .glyphicons.glyphicons-menu-hamburger,
.privacy-groups-bar .glyphicons.glyphicons-menu-hamburger,
.generic-api-dt-util-bar .glyphicons.glyphicons-menu-hamburger {
  font-size: 18px;
}
.collection-nav-bar .glyphicons.glyphicons-display,
.privacy-groups-bar .glyphicons.glyphicons-display,
.generic-api-dt-util-bar .glyphicons.glyphicons-display {
  font-size: 18px;
  vertical-align: middle;
  margin-top: -2px;
}
.collection-nav-bar .glyphicons.glyphicons-wifi,
.privacy-groups-bar .glyphicons.glyphicons-wifi,
.generic-api-dt-util-bar .glyphicons.glyphicons-wifi {
  transform: rotate(45deg);
}
.collection-nav-bar .set-display-order .glyphicons:before,
.privacy-groups-bar .set-display-order .glyphicons:before,
.generic-api-dt-util-bar .set-display-order .glyphicons:before {
  color: #2f96b4;
}
.collection-nav-bar > li:first-child,
.privacy-groups-bar > li:first-child,
.generic-api-dt-util-bar > li:first-child {
  border-right: 1px solid #ccc;
  padding: 2px 11px 0;
}
.collection-nav-bar > li:first-child:not(.btn-passphrase),
.privacy-groups-bar > li:first-child:not(.btn-passphrase),
.generic-api-dt-util-bar > li:first-child:not(.btn-passphrase) {
  text-align: center;
}
.collection-nav-bar > li,
.privacy-groups-bar > li,
.generic-api-dt-util-bar > li {
  display: table-cell;
  padding: 6px 18px;
  vertical-align: middle;
}
.collection-nav-bar > li > ul,
.privacy-groups-bar > li > ul,
.generic-api-dt-util-bar > li > ul {
  display: none;
}
.collection-nav-bar > li .btn-group ul.dropdown-menu,
.privacy-groups-bar > li .btn-group ul.dropdown-menu,
.generic-api-dt-util-bar > li .btn-group ul.dropdown-menu {
  margin-left: 8px;
}
.collection-nav-bar > li :hover:not(.hubs_collection_search, .nav-action) .glyphicons:before,
.privacy-groups-bar > li :hover:not(.hubs_collection_search, .nav-action) .glyphicons:before,
.generic-api-dt-util-bar > li :hover:not(.hubs_collection_search, .nav-action) .glyphicons:before {
  color: #2f96b4;
}
.collection-nav-bar > li .nav-action.disabled,
.privacy-groups-bar > li .nav-action.disabled,
.generic-api-dt-util-bar > li .nav-action.disabled,
.collection-nav-bar > li .nav-action.disabled:hover,
.privacy-groups-bar > li .nav-action.disabled:hover,
.generic-api-dt-util-bar > li .nav-action.disabled:hover {
  color: #cacaca;
  cursor: not-allowed;
}
.collection-nav-bar > li .nav-action.disabled .glyphicons:before,
.privacy-groups-bar > li .nav-action.disabled .glyphicons:before,
.generic-api-dt-util-bar > li .nav-action.disabled .glyphicons:before,
.collection-nav-bar > li .nav-action.disabled:hover .glyphicons:before,
.privacy-groups-bar > li .nav-action.disabled:hover .glyphicons:before,
.generic-api-dt-util-bar > li .nav-action.disabled:hover .glyphicons:before {
  color: #cacaca;
}
.collection-nav-bar > li .nav-label,
.privacy-groups-bar > li .nav-label,
.generic-api-dt-util-bar > li .nav-label {
  cursor: pointer;
  font-size: 13px;
  vertical-align: middle;
}
.collection-nav-bar > li.hubs_collection_search,
.privacy-groups-bar > li.hubs_collection_search,
.generic-api-dt-util-bar > li.hubs_collection_search,
.collection-nav-bar > li.with-secondary-search,
.privacy-groups-bar > li.with-secondary-search,
.generic-api-dt-util-bar > li.with-secondary-search {
  position: relative;
  width: 100%;
  padding: 6px 15px;
}
.collection-nav-bar > li.hubs_collection_search .halflings,
.privacy-groups-bar > li.hubs_collection_search .halflings,
.generic-api-dt-util-bar > li.hubs_collection_search .halflings,
.collection-nav-bar > li.with-secondary-search .halflings,
.privacy-groups-bar > li.with-secondary-search .halflings,
.generic-api-dt-util-bar > li.with-secondary-search .halflings {
  position: absolute;
  top: 8px;
  left: 12px;
  right: auto;
  bottom: auto;
  opacity: 0.5;
}
.collection-nav-bar > li.hubs_collection_search input.main-search-input,
.privacy-groups-bar > li.hubs_collection_search input.main-search-input,
.generic-api-dt-util-bar > li.hubs_collection_search input.main-search-input,
.collection-nav-bar > li.with-secondary-search input.main-search-input,
.privacy-groups-bar > li.with-secondary-search input.main-search-input,
.generic-api-dt-util-bar > li.with-secondary-search input.main-search-input {
  width: 100%;
  height: 29px;
  font-size: 13px;
  text-indent: 26px;
}
.collection-nav-bar > li.hubs_collection_search input.main-search-input:focus,
.privacy-groups-bar > li.hubs_collection_search input.main-search-input:focus,
.generic-api-dt-util-bar > li.hubs_collection_search input.main-search-input:focus,
.collection-nav-bar > li.with-secondary-search input.main-search-input:focus,
.privacy-groups-bar > li.with-secondary-search input.main-search-input:focus,
.generic-api-dt-util-bar > li.with-secondary-search input.main-search-input:focus {
  box-shadow: none;
  border-color: #5bc0de;
}
.collection-nav-bar > li.hubs_collection_search input::-ms-clear,
.privacy-groups-bar > li.hubs_collection_search input::-ms-clear,
.generic-api-dt-util-bar > li.hubs_collection_search input::-ms-clear,
.collection-nav-bar > li.with-secondary-search input::-ms-clear,
.privacy-groups-bar > li.with-secondary-search input::-ms-clear,
.generic-api-dt-util-bar > li.with-secondary-search input::-ms-clear {
  display: none;
}
.collection-nav-bar > li.hubs_collection_search .close-float,
.privacy-groups-bar > li.hubs_collection_search .close-float,
.generic-api-dt-util-bar > li.hubs_collection_search .close-float,
.collection-nav-bar > li.with-secondary-search .close-float,
.privacy-groups-bar > li.with-secondary-search .close-float,
.generic-api-dt-util-bar > li.with-secondary-search .close-float {
  display: none;
  overflow: hidden;
  position: absolute;
  top: 13px;
  left: auto;
  right: 55px;
  bottom: auto;
  width: 15px;
  height: 15px;
  line-height: 16px;
  font-size: 14px;
  padding: 0;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  background: #999;
  color: #fff;
  font-weight: bold;
  z-index: 3;
}
.collection-nav-bar > li.hubs_collection_search .close-float.active,
.privacy-groups-bar > li.hubs_collection_search .close-float.active,
.generic-api-dt-util-bar > li.hubs_collection_search .close-float.active,
.collection-nav-bar > li.with-secondary-search .close-float.active,
.privacy-groups-bar > li.with-secondary-search .close-float.active,
.generic-api-dt-util-bar > li.with-secondary-search .close-float.active {
  display: block;
}
.collection-nav-bar > li.hubs_collection_search .close-float:hover,
.privacy-groups-bar > li.hubs_collection_search .close-float:hover,
.generic-api-dt-util-bar > li.hubs_collection_search .close-float:hover,
.collection-nav-bar > li.with-secondary-search .close-float:hover,
.privacy-groups-bar > li.with-secondary-search .close-float:hover,
.generic-api-dt-util-bar > li.with-secondary-search .close-float:hover {
  color: #fff;
  background: #000;
}
.collection-nav-bar > li.hubs_collection_search .advanced-search-button,
.privacy-groups-bar > li.hubs_collection_search .advanced-search-button,
.generic-api-dt-util-bar > li.hubs_collection_search .advanced-search-button,
.collection-nav-bar > li.with-secondary-search .advanced-search-button,
.privacy-groups-bar > li.with-secondary-search .advanced-search-button,
.generic-api-dt-util-bar > li.with-secondary-search .advanced-search-button {
  display: block;
  width: 14px;
  height: 18px;
  position: absolute;
  top: 11px;
  left: auto;
  right: 25px;
  bottom: auto;
  color: #999;
  font-size: 10px;
}
.collection-nav-bar > li.hubs_collection_search .advanced-search-button:hover,
.privacy-groups-bar > li.hubs_collection_search .advanced-search-button:hover,
.generic-api-dt-util-bar > li.hubs_collection_search .advanced-search-button:hover,
.collection-nav-bar > li.with-secondary-search .advanced-search-button:hover,
.privacy-groups-bar > li.with-secondary-search .advanced-search-button:hover,
.generic-api-dt-util-bar > li.with-secondary-search .advanced-search-button:hover {
  color: #666;
}
.collection-nav-bar > li.hubs_collection_search .advanced-search-button:after,
.privacy-groups-bar > li.hubs_collection_search .advanced-search-button:after,
.generic-api-dt-util-bar > li.hubs_collection_search .advanced-search-button:after,
.collection-nav-bar > li.with-secondary-search .advanced-search-button:after,
.privacy-groups-bar > li.with-secondary-search .advanced-search-button:after,
.generic-api-dt-util-bar > li.with-secondary-search .advanced-search-button:after {
  font-family: 'Glyphicons Halflings';
  content: '\e252';
}
.collection-nav-bar > li.hubs_collection_search .advanced-search-close-button,
.privacy-groups-bar > li.hubs_collection_search .advanced-search-close-button,
.generic-api-dt-util-bar > li.hubs_collection_search .advanced-search-close-button,
.collection-nav-bar > li.with-secondary-search .advanced-search-close-button,
.privacy-groups-bar > li.with-secondary-search .advanced-search-close-button,
.generic-api-dt-util-bar > li.with-secondary-search .advanced-search-close-button {
  position: absolute;
  top: 15px;
  left: auto;
  right: 23px;
  bottom: auto;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2;
}
.collection-nav-bar > li.hubs_collection_search .advanced-search-close-button:hover,
.privacy-groups-bar > li.hubs_collection_search .advanced-search-close-button:hover,
.generic-api-dt-util-bar > li.hubs_collection_search .advanced-search-close-button:hover,
.collection-nav-bar > li.with-secondary-search .advanced-search-close-button:hover,
.privacy-groups-bar > li.with-secondary-search .advanced-search-close-button:hover,
.generic-api-dt-util-bar > li.with-secondary-search .advanced-search-close-button:hover,
.collection-nav-bar > li.hubs_collection_search .advanced-search-close-button:focus,
.privacy-groups-bar > li.hubs_collection_search .advanced-search-close-button:focus,
.generic-api-dt-util-bar > li.hubs_collection_search .advanced-search-close-button:focus,
.collection-nav-bar > li.with-secondary-search .advanced-search-close-button:focus,
.privacy-groups-bar > li.with-secondary-search .advanced-search-close-button:focus,
.generic-api-dt-util-bar > li.with-secondary-search .advanced-search-close-button:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: 0.5;
}
.collection-nav-bar > li.hubs_collection_search .search-form-wrapper,
.privacy-groups-bar > li.hubs_collection_search .search-form-wrapper,
.generic-api-dt-util-bar > li.hubs_collection_search .search-form-wrapper,
.collection-nav-bar > li.with-secondary-search .search-form-wrapper,
.privacy-groups-bar > li.with-secondary-search .search-form-wrapper,
.generic-api-dt-util-bar > li.with-secondary-search .search-form-wrapper {
  position: relative;
}
.collection-nav-bar > li.hubs_collection_search .search-form-wrapper .advanced-search-wrapper,
.privacy-groups-bar > li.hubs_collection_search .search-form-wrapper .advanced-search-wrapper,
.generic-api-dt-util-bar > li.hubs_collection_search .search-form-wrapper .advanced-search-wrapper,
.collection-nav-bar > li.with-secondary-search .search-form-wrapper .advanced-search-wrapper,
.privacy-groups-bar > li.with-secondary-search .search-form-wrapper .advanced-search-wrapper,
.generic-api-dt-util-bar > li.with-secondary-search .search-form-wrapper .advanced-search-wrapper {
  width: 100%;
  padding: 20px;
  position: absolute;
  top: 28px;
  left: 1px;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
}
.collection-nav-bar > li.hubs_collection_search .search-form-wrapper .advanced-search-wrapper .search-title,
.privacy-groups-bar > li.hubs_collection_search .search-form-wrapper .advanced-search-wrapper .search-title,
.generic-api-dt-util-bar > li.hubs_collection_search .search-form-wrapper .advanced-search-wrapper .search-title,
.collection-nav-bar > li.with-secondary-search .search-form-wrapper .advanced-search-wrapper .search-title,
.privacy-groups-bar > li.with-secondary-search .search-form-wrapper .advanced-search-wrapper .search-title,
.generic-api-dt-util-bar > li.with-secondary-search .search-form-wrapper .advanced-search-wrapper .search-title {
  font-size: 14px;
  color: #404041;
  font-weight: 500;
}
.collection-nav-bar > li.hubs_collection_search .search-form-wrapper .advanced-search-wrapper label,
.privacy-groups-bar > li.hubs_collection_search .search-form-wrapper .advanced-search-wrapper label,
.generic-api-dt-util-bar > li.hubs_collection_search .search-form-wrapper .advanced-search-wrapper label,
.collection-nav-bar > li.with-secondary-search .search-form-wrapper .advanced-search-wrapper label,
.privacy-groups-bar > li.with-secondary-search .search-form-wrapper .advanced-search-wrapper label,
.generic-api-dt-util-bar > li.with-secondary-search .search-form-wrapper .advanced-search-wrapper label {
  margin-top: 2px;
  padding-right: 5px;
  font-size: 13px;
  color: #808284;
  font-weight: inherit;
}
.collection-nav-bar > li.hubs_collection_search .search-form-wrapper .advanced-search-wrapper input,
.privacy-groups-bar > li.hubs_collection_search .search-form-wrapper .advanced-search-wrapper input,
.generic-api-dt-util-bar > li.hubs_collection_search .search-form-wrapper .advanced-search-wrapper input,
.collection-nav-bar > li.with-secondary-search .search-form-wrapper .advanced-search-wrapper input,
.privacy-groups-bar > li.with-secondary-search .search-form-wrapper .advanced-search-wrapper input,
.generic-api-dt-util-bar > li.with-secondary-search .search-form-wrapper .advanced-search-wrapper input {
  font-size: 14px;
  width: 100%;
}
.collection-nav-bar > li.hubs_collection_search .search-form-wrapper .advanced-search-wrapper .advanced-search-submit,
.privacy-groups-bar > li.hubs_collection_search .search-form-wrapper .advanced-search-wrapper .advanced-search-submit,
.generic-api-dt-util-bar > li.hubs_collection_search .search-form-wrapper .advanced-search-wrapper .advanced-search-submit,
.collection-nav-bar > li.with-secondary-search .search-form-wrapper .advanced-search-wrapper .advanced-search-submit,
.privacy-groups-bar > li.with-secondary-search .search-form-wrapper .advanced-search-wrapper .advanced-search-submit,
.generic-api-dt-util-bar > li.with-secondary-search .search-form-wrapper .advanced-search-wrapper .advanced-search-submit {
  font-size: 13px;
}
.collection-nav-bar > li.hubs_collection_search .result-tally,
.privacy-groups-bar > li.hubs_collection_search .result-tally,
.generic-api-dt-util-bar > li.hubs_collection_search .result-tally,
.collection-nav-bar > li.with-secondary-search .result-tally,
.privacy-groups-bar > li.with-secondary-search .result-tally,
.generic-api-dt-util-bar > li.with-secondary-search .result-tally {
  color: #ccc;
  position: absolute;
  top: 11px;
  left: auto;
  right: 80px;
  bottom: auto;
}
.collection-nav-bar > li.hubs_collection_search .section-tally,
.privacy-groups-bar > li.hubs_collection_search .section-tally,
.generic-api-dt-util-bar > li.hubs_collection_search .section-tally,
.collection-nav-bar > li.with-secondary-search .section-tally,
.privacy-groups-bar > li.with-secondary-search .section-tally,
.generic-api-dt-util-bar > li.with-secondary-search .section-tally {
  right: 16px;
}
.collection-nav-bar > li a.nav-action,
.privacy-groups-bar > li a.nav-action,
.generic-api-dt-util-bar > li a.nav-action,
.collection-nav-bar > li button.nav-action,
.privacy-groups-bar > li button.nav-action,
.generic-api-dt-util-bar > li button.nav-action {
  color: #686868;
  white-space: nowrap;
  border: none;
  background: transparent;
}
.collection-nav-bar > li a.nav-action:hover,
.privacy-groups-bar > li a.nav-action:hover,
.generic-api-dt-util-bar > li a.nav-action:hover,
.collection-nav-bar > li button.nav-action:hover,
.privacy-groups-bar > li button.nav-action:hover,
.generic-api-dt-util-bar > li button.nav-action:hover {
  color: #2f96b4;
}
.collection-nav-bar > li.btn-group.open .nav-action,
.privacy-groups-bar > li.btn-group.open .nav-action,
.generic-api-dt-util-bar > li.btn-group.open .nav-action {
  box-shadow: none;
}
.collection-nav-bar > li.btn-group.open .dropdown-menu,
.privacy-groups-bar > li.btn-group.open .dropdown-menu,
.generic-api-dt-util-bar > li.btn-group.open .dropdown-menu {
  border-radius: 0;
}
.collection-nav-bar > li .dropdown-menu,
.privacy-groups-bar > li .dropdown-menu,
.generic-api-dt-util-bar > li .dropdown-menu {
  background-color: #eee;
  border: 1px solid #ccc;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-top: 5px !important;
}
.collection-nav-bar > li .dropdown-menu li a,
.privacy-groups-bar > li .dropdown-menu li a,
.generic-api-dt-util-bar > li .dropdown-menu li a {
  opacity: 0.8;
}
.collection-nav-bar > li .dropdown-menu li a:hover,
.privacy-groups-bar > li .dropdown-menu li a:hover,
.generic-api-dt-util-bar > li .dropdown-menu li a:hover {
  opacity: 1;
  background-color: #5bc0de !important;
  color: white;
}
.collection-nav-bar > li.ufr-action-button,
.privacy-groups-bar > li.ufr-action-button,
.generic-api-dt-util-bar > li.ufr-action-button {
  padding-top: 5px;
  padding-bottom: 5px;
}
.create-item-modal .modal-body .form-group .help-block {
  display: none;
}
.create-item-modal .modal-body .form-group.has-error .help-block {
  display: block;
}
.modal.place-cta-modal .modal-header {
  border-bottom: 0;
}
.modal.place-cta-modal .modal-body {
  overflow-x: hidden;
  padding: 0px !important;
  background: #fff;
}
.modal.place-cta-modal .modal-body .badge {
  border-radius: 3px;
  cursor: default;
}
.modal.place-cta-modal .modal-body .badge.stream-level-on {
  display: inline-block;
  width: 36px;
  text-align: center;
}
.modal.place-cta-modal .modal-body .select-cta {
  height: 428px;
  background-color: #fbfbfb;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}
.modal.place-cta-modal .modal-body .select-cta .cta {
  cursor: pointer;
}
.modal.place-cta-modal .modal-body .select-cta .create-cta {
  display: none;
}
.modal.place-cta-modal .modal-body .select-cta.no-available-ctas .scroll-ctas {
  display: none;
}
.modal.place-cta-modal .modal-body .select-cta.no-available-ctas i.icon-cog {
  display: none;
}
.modal.place-cta-modal .modal-body .select-cta.no-available-ctas .create-cta {
  display: block;
  position: relative;
  top: 34px;
  left: 194px;
  right: auto;
  bottom: auto;
  width: 200px;
  height: 300px;
  border: 2px dashed #aaa;
  cursor: pointer;
}
.modal.place-cta-modal .modal-body .select-cta.no-available-ctas .create-cta span.add-icon {
  display: block;
  position: relative;
  top: 109px;
  left: 62px;
  right: auto;
  bottom: auto;
  width: 80px;
  height: 80px;
  background-color: #777;
  border-radius: 50%;
  color: #fff;
  font-size: 77px;
  font-weight: bold;
  text-align: center;
  line-height: 66px;
}
.modal.place-cta-modal .modal-body .select-cta.no-available-ctas .create-cta span.add-text {
  display: none;
}
.modal.place-cta-modal .modal-body .select-cta.no-available-ctas .create-cta:hover span.add-icon {
  background-color: #444;
}
.modal.place-cta-modal .modal-body .select-cta.no-available-ctas .create-cta:hover span.add-text {
  display: inline-block;
  position: relative;
  top: 120px;
  left: 75px;
  right: auto;
  bottom: auto;
  color: #444;
  font-weight: bold;
}
.modal.place-cta-modal .modal-body .select-cta .scroll-ctas {
  position: relative;
  width: 110px;
  height: 330px;
  margin: 0 auto;
}
.modal.place-cta-modal .modal-body .select-cta .scroll-ctas .scroll-cta-wrapper {
  position: relative;
  margin-left: -25px;
}
.modal.place-cta-modal .modal-body .select-cta .scroll-ctas .scroll-cta-wrapper li.no-result {
  position: relative;
  top: 82px;
  left: -25px;
  right: auto;
  bottom: auto;
  width: 156px;
  height: 223px;
  font-size: 16px;
  color: #939597;
  background: url("/img/icon_128/search_faded.png") no-repeat 40px 0;
  white-space: nowrap;
}
.modal.place-cta-modal .modal-body .select-cta .scroll-ctas .scroll-cta-wrapper li.no-result span {
  position: relative;
  top: 152px;
  left: 48px;
  right: auto;
  bottom: auto;
}
.modal.place-cta-modal .modal-body .select-cta .scroll-ctas .tile.single.cta {
  margin-left: -160px;
  -webkit-transform: scale(0.4);
  -ms-transform: scale(0.4);
  -o-transform: scale(0.4);
  transform: scale(0.4);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal.place-cta-modal .modal-body .select-cta .scroll-ctas .tile.single.cta.active {
  margin-left: -110px;
  margin-right: 70px;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
}
.modal.place-cta-modal .modal-body .select-cta .scroll-ctas .tile.single.cta.active:active {
  -webkit-transform: scale(0.82);
  -ms-transform: scale(0.82);
  -o-transform: scale(0.82);
  transform: scale(0.82);
  -webkit-box-shadow: "0 0 10px #fff";
  box-shadow: "0 0 10px #fff";
}
.modal.place-cta-modal .modal-body .select-cta .scroll-ctas .tile.single.cta:first-child {
  margin-left: -94px;
}
.modal.place-cta-modal .modal-body .select-cta .scroll-ctas .tile.single.cta:first-child.active {
  margin-left: -49px;
}
.modal.place-cta-modal .modal-body .select-cta .scroll-ctas .cta-activate-button-container {
  padding-bottom: 10px;
}
.modal.place-cta-modal .modal-body .select-cta .search-ctas {
  position: relative;
  display: inline-block;
  width: 300px;
  margin: 10px 0 0 0;
}
.modal.place-cta-modal .modal-body .select-cta .search-ctas i {
  position: absolute;
  top: 6px;
  left: 0;
  right: auto;
  bottom: auto;
  opacity: 0.5;
}
.modal.place-cta-modal .modal-body .select-cta .search-ctas input {
  width: 197px;
  margin-left: 15px;
  border: 0;
  font-size: 12px;
  color: #808284;
  background-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.modal.place-cta-modal .modal-body .select-cta .search-ctas span.clear-search {
  display: none;
}
.modal.place-cta-modal .modal-body .select-cta .search-ctas.active span.clear-search {
  display: block;
  position: absolute;
  top: 5px;
  left: auto;
  right: 55px;
  bottom: auto;
  width: 15px;
  height: 15px;
  font-size: 10px;
  text-align: center;
  line-height: 14px;
  color: #fff;
  border-radius: 50%;
  background: #ccc;
  cursor: pointer;
}
.modal.place-cta-modal .modal-body .select-cta .btn-group {
  display: inline-block;
  width: 167px;
  margin: 13px 10px 10px 10px;
  border-right: 1px solid #ddd;
}
.modal.place-cta-modal .modal-body .select-cta .btn-group.open > a .halflings:before {
  content: "\e113";
}
.modal.place-cta-modal .modal-body .select-cta .btn-group > a {
  display: inline-block;
  width: 158px;
  font-size: 14px;
}
.modal.place-cta-modal .modal-body .select-cta .btn-group > a:hover {
  background-color: transparent !important;
}
.modal.place-cta-modal .modal-body .select-cta .btn-group a {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #58585b;
}
.modal.place-cta-modal .modal-body .select-cta .btn-group a:before,
.modal.place-cta-modal .modal-body .select-cta .btn-group a:after {
  display: table;
  content: " ";
}
.modal.place-cta-modal .modal-body .select-cta .btn-group a:after {
  clear: both;
}
.modal.place-cta-modal .modal-body .select-cta .btn-group a > span {
  float: left;
}
.modal.place-cta-modal .modal-body .select-cta .btn-group a:hover {
  background: inherit;
  background-color: #f1f1f1;
  color: #58585b !important;
}
.modal.place-cta-modal .modal-body .select-cta .btn-group a [class^="bh-icon-"] {
  position: relative;
  top: 3px;
  left: auto;
  right: auto;
  bottom: auto;
  float: right;
}
.modal.place-cta-modal .modal-body .select-cta .btn-group a .bh-icon-link-cta {
  position: relative;
  top: auto;
  left: 3px;
  right: auto;
  bottom: auto;
}
.modal.place-cta-modal .modal-body .select-cta .btn-group a .bh-icon-stream-cta {
  position: relative;
  top: auto;
  left: 4px;
  right: auto;
  bottom: auto;
}
.modal.place-cta-modal .modal-body .select-cta .btn-group .halflings {
  position: relative;
  top: 2px;
  left: auto;
  right: auto;
  bottom: auto;
  float: right;
  margin-right: 9px;
  opacity: 0.6;
}
.modal.place-cta-modal .modal-body .select-cta .edit-cta-link {
  position: absolute;
  top: 15px;
  left: auto;
  right: 21px;
  bottom: auto;
  color: #333333;
  opacity: 0.8;
  cursor: pointer;
}
.modal.place-cta-modal .modal-body .select-cta .cta-info-container {
  position: absolute;
  top: 357px;
  left: auto;
  right: auto;
  bottom: auto;
  width: 100%;
  text-align: center;
}
.modal.place-cta-modal .modal-body .select-cta .cta-info-container .cta-info {
  display: inline-block;
  margin: 0 auto;
}
.modal.place-cta-modal .modal-body .select-cta .cta-info-container .cta-info p {
  white-space: nowrap;
}
.modal.place-cta-modal .modal-body .select-cta .cta-info-container .cta-info p .cta-info-name {
  font-weight: bold;
  padding-right: 10px;
  color: #666;
}
.modal.place-cta-modal .modal-body .select-cta .cta-info-container .cta-info p .badge {
  background-color: #a7a9ab;
}
.modal.place-cta-modal .modal-body .select-cta .iScrollHorizontalScrollbar {
  position: absolute;
  top: auto;
  left: -216px;
  right: auto;
  bottom: -29px;
  width: 541px;
  height: 1px;
  background-color: #ddd;
  overflow: visible !important;
}
.modal.place-cta-modal .modal-body .select-cta .iScrollIndicator {
  position: absolute;
  top: -9px;
  left: auto;
  right: auto;
  bottom: auto;
  width: 16px !important;
  height: 16px !important;
  border-radius: 50%;
  background-color: #888;
  border: 2px solid #fff;
  cursor: pointer;
}
.modal.place-cta-modal .modal-body .edit-cta-placement.gate-container {
  height: 89px;
  text-align: center;
}
.modal.place-cta-modal .modal-body .edit-cta-placement.gate-container .gate-cta {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-transform: uppercase;
  padding: 9px 0;
  width: 150px;
}
.modal.place-cta-modal .modal-body .edit-cta-placement.stream-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
}
.modal.place-cta-modal .modal-body .edit-cta-placement .no-form-ctas {
  line-height: 78px;
  color: #555;
}
.modal.place-cta-modal .modal-body .edit-cta-placement.fade {
  opacity: 0.2;
}
.modal.place-cta-modal .modal-body .edit-cta-placement .cta-placement-type {
  width: 295px;
  height: 95px;
  padding: 34px 34px 0 34px;
}
.modal.place-cta-modal .modal-body .edit-cta-placement .cta-placement-type.item-level {
  opacity: 0.6;
}
.modal.place-cta-modal .modal-body .edit-cta-placement .cta-placement-type.item-level .placement-body {
  padding: 9px;
  font-size: 10px;
  line-height: 11px;
}
.modal.place-cta-modal .modal-body .edit-cta-placement .cta-placement-type.stream-level.disable {
  opacity: 0.6;
}
.modal.place-cta-modal .modal-body .edit-cta-placement .cta-placement-type.stream-level.disable .placement-body .placement-row.not-editable {
  opacity: 1;
}
.modal.place-cta-modal .modal-body .edit-cta-placement .cta-placement-type .placement-header {
  padding-bottom: 4px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 5px;
}
.modal.place-cta-modal .modal-body .edit-cta-placement .cta-placement-type .placement-header label {
  display: inline-block;
  font-size: 12px;
}
.modal.place-cta-modal .modal-body .edit-cta-placement .cta-placement-type .placement-header span.badge {
  float: right;
  background-color: #a7a9ab;
}
.modal.place-cta-modal .modal-body .edit-cta-placement .cta-placement-type .placement-header span.badge.placement-on {
  background-color: #66be63;
}
.modal.place-cta-modal .modal-body .edit-cta-placement .cta-placement-type .placement-header .circle-icon {
  display: inline-block;
  float: right;
  width: 19px;
  height: 19px;
  margin-right: 3px;
  border: 1px solid #333;
  border-radius: 50%;
  text-align: center;
}
.modal.place-cta-modal .modal-body .edit-cta-placement .cta-placement-type .placement-body .placement-row {
  position: relative;
  margin-bottom: 5px;
}
.modal.place-cta-modal .modal-body .edit-cta-placement .cta-placement-type .placement-body .placement-row .prevent-interaction {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.modal.place-cta-modal .modal-body .edit-cta-placement .cta-placement-type .placement-body .placement-row.not-editable {
  opacity: 0.6;
}
.modal.place-cta-modal .modal-body .edit-cta-placement .cta-placement-type .placement-body .placement-row.not-editable .prevent-interaction {
  display: block;
}
.modal.place-cta-modal .modal-body .edit-cta-placement .cta-placement-type .placement-body .placement-row input,
.modal.place-cta-modal .modal-body .edit-cta-placement .cta-placement-type .placement-body .placement-row span {
  display: inline-block;
}
.modal.place-cta-modal .modal-body .edit-cta-placement .cta-placement-type .placement-body .placement-row input.placement-on {
  margin-right: 13px;
}
.modal.place-cta-modal .modal-body .edit-cta-placement .cta-placement-type .placement-body .placement-row span.placement-description {
  width: 106px;
  margin-right: 15px;
}
.modal.place-cta-modal .modal-body .edit-cta-placement .cta-placement-type .placement-body .placement-row input.placement-number {
  width: 35px;
  height: 26px;
  margin-right: 6px;
  text-align: center;
}
.modal.place-cta-modal .modal-body .edit-cta-placement .cta-placement-type .placement-body .placement-row .change-number {
  margin-right: 4px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.stream-option-wrapper .stream-option {
  margin: 10px 0 10px 30px;
}
#stream-custom-labels-tab,
#stream-lang-links-tab {
  margin-top: 32px;
}
#single-stream-performance-tab iframe {
  height: 800px;
  width: 100%;
  border: none;
}
.no-search .select2-search {
  display: none;
}
.select2-chosen .user-avatar {
  margin-top: 2px;
}
.l-padding-25 {
  padding-left: 25px;
}
html.layout-bs3 .mgmt-container .content-container.hubs-section-cta {
  font-family: Roboto, sans-serif;
}
html.layout-bs3 .mgmt-container .content-container.hubs-section-cta .cta-navbar span.nb-icon i {
  background-image: url("/img/hubs/backend/spritesheet.png");
}
html.layout-bs3 .mgmt-container .content-container.hubs-section-cta .cta-navbar span.nb-icon.nb-all i {
  background-position: -404px -285px;
}
html.layout-bs3 .mgmt-container .content-container.hubs-section-cta .cta-navbar span.nb-icon.nb-link i {
  background-position: -323px -285px;
}
html.layout-bs3 .mgmt-container .content-container.hubs-section-cta .cta-navbar span.nb-icon.nb-form i {
  background-position: -365px -285px;
}
html.layout-bs3 .mgmt-container .content-container.hubs-section-cta .cta-navbar .nav .active > a:not(:hover) .nb-all i {
  background-position: -404px -323px;
}
html.layout-bs3 .mgmt-container .content-container.hubs-section-cta .cta-navbar .nav .active > a:not(:hover) .nb-link i {
  background-position: -323px -322px;
}
html.layout-bs3 .mgmt-container .content-container.hubs-section-cta .cta-navbar .nav .active > a:not(:hover) .nb-form i {
  background-position: -365px -323px;
}
html.layout-bs3 .mgmt-container .content-container.hubs-section-cta .cta-selection-tab-content .tab-pane .page-header {
  padding-bottom: 0;
  margin-bottom: 0;
}
html.layout-bs3 .mgmt-container .content-container.hubs-section-cta .cta-selection-tab-content .tab-pane .page-header .icon-edit-large {
  width: 24px;
  height: 24px;
  margin-left: 10px;
  margin-top: 5px;
  background: transparent url("/img/hubs/backend/spritesheet.png") no-repeat -528px -90px;
}
html.layout-bs3 .mgmt-container .content-container.hubs-section-cta .show-cta-id {
  position: relative;
  top: -5px;
}
.hubs-cta-accordion {
  min-width: 372px;
}
.hubs-cta-accordion .accordion-toggle {
  cursor: pointer;
}
.hubs-cta-accordion .accordion-toggle .hide-when-collapsed {
  display: inline-block;
}
.hubs-cta-accordion .accordion-toggle .show-when-collapsed {
  display: none;
}
.hubs-cta-accordion .accordion-toggle.collapsed .hide-when-collapsed {
  display: none;
}
.hubs-cta-accordion .accordion-toggle.collapsed .show-when-collapsed {
  display: inline-block;
}
.hubs-cta-accordion .accordion-inner {
  position: relative;
  border-top: none;
  font-size: 0.9em;
}
.hubs-cta-accordion .accordion-inner .disabled-placement-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
}
.hubs-cta-accordion .enabled .accordion-inner .disabled-placement-overlay {
  display: none;
}
.cta-placement-all-selected .note {
  width: 300px;
  margin: 0 auto;
}
.placement-select-items-button {
  float: none;
  margin-left: 10px;
  margin-top: -2px;
}
.num-item-selected {
  display: inline-block;
  height: 24px;
  line-height: 30px;
  margin-left: 10px;
  font-size: 14px;
}
.num-item-selected > span {
  font-weight: bold;
}
.block-collection-config,
.block-item-config {
  margin: 10px 0 20px 20px;
  padding-left: 20px;
  border-left: 2px solid #eee;
}
.block-collection-config .hubs_cta_position_label,
.block-item-config .hubs_cta_position_label {
  width: 80px;
}
.item-block-property {
  margin-top: 5px;
}
.optional-container {
  margin-left: -7px;
}
.item-cta .item-cta-bg {
  position: relative;
  width: 225px;
  height: 260px;
  margin-top: -260px;
  background: transparent url("/img/hubs/backend/cta_bg.png") no-repeat top right;
}
.item-cta .cta .hidden-cta-fields .cta-field-section .preview-form-field {
  height: 18px;
}
.item-cta .cta .hidden-cta-fields .cta-field-section .cta-field-name {
  font-size: 11px;
}
.item-cta .cta .hidden-cta-fields .cta-field-section .cta-field-sprite-container {
  top: 24px;
}
.item-cta .cta > a.accent-button {
  font-size: 14px;
  padding: 16px;
}
.hubs_cta_position {
  margin-bottom: 5px;
  padding: 6px 15px;
}
.hubs_cta_position .hubs_cta_position_label {
  display: inline-block;
  width: 140px;
  margin: 9px 6px 0 0;
  color: #333333;
  font-weight: bold;
}
.hubs_cta_position .hubs_cta_position_label:last-child {
  width: 100px;
}
.hubs_cta_position .hubs_cta_position_input {
  display: inline-block;
  padding-top: 6px;
}
.hubs_cta_position .hubs_cta_position_input.item_level_options {
  position: relative;
  left: -3px;
}
.hubs_cta_position .hubs_cta_position_input.item_level_options input[type=radio] {
  margin: 3px 5px 0 0;
}
.hubs_cta_position .hubs_cta_position_input input[type=text] {
  display: inline-block;
  width: 32px;
  height: 24px;
  margin: 0;
  text-align: center;
}
.hubs_cta_position .hubs_cta_position_input input[type=checkbox] {
  margin: 4px 7px 0 0;
}
.hubs_cta_position .hubs_cta_position_input a {
  color: #666666;
  margin: 0 0 0 5px;
}
.hubs_cta_position .hubs_cta_position_input a:hover {
  color: #333333;
}
.hubs_cta_position .repeat-cta-container {
  margin-top: -10px;
}
.hubs_cta_position > div.alert {
  margin: 10px 0;
}
@-moz-document url-prefix() {
  .hubs_cta_position .hubs_cta_position_label {
    display: inline;
  }
  .hubs_cta_position .hubs_cta_position_input {
    display: inline;
  }
}
.example-positioning-group {
  float: right;
  width: 110px;
  height: 50px;
  margin-top: -5px;
  opacity: 0.4;
}
.example-positioning-group .item-example-tile {
  width: 30px;
  height: 45px;
  margin: 5px 2px 0 2px;
  padding-top: 15px;
  color: #fff;
  background-color: #999999;
  text-align: center;
  vertical-align: middle;
  line-height: 1;
  font-size: 1.4em;
}
.example-positioning-group .item-example-tile.item-CTA {
  width: 35px;
  height: 55px;
  margin-top: 0;
  padding-top: 15px;
  font-size: 1em;
  color: #666666;
  background: #fafafa;
  border: 1px solid #cccccc;
  -webkit-box-shadow: 0 0 10px #fff5d6;
  box-shadow: 0 0 10px #fff5d6;
}
.enabled .example-positioning-group {
  opacity: 1;
}
a.manage-form-fields {
  line-height: 34px;
  cursor: pointer;
}
a.manage-form-fields.disabled {
  pointer-events: none;
  color: grey;
}
.hubs_cta_info,
.hubs_cta_action,
.hubs_cta_design,
.hubs_cta_preview,
.hubs_cta_info,
.hubs_cta_config,
.hubs_cta_integration {
  max-width: 650px;
  margin: 0 auto;
}
.hubs_cta_action:before,
.hubs_cta_action:after {
  display: table;
  content: " ";
}
.hubs_cta_action:after {
  clear: both;
}
.hubs_cta_action .link-action-container {
  width: 98%;
}
.hubs_cta_action .link-action-container .form-control {
  display: inline-block;
}
.hubs_cta_action .link-action-container .link-type {
  position: relative;
  top: -2px;
  width: 16%;
  height: 32px;
  margin-right: 0;
}
.hubs_cta_action .link-action-container .link-input-container {
  width: 83%;
  display: inline-block;
}
.hubs_cta_action .link-action-container .link-input-container > input {
  width: 100%;
}
.hubs_cta_action.form-cta {
  max-width: 750px;
}
.hubs_cta_action.form-cta > div {
  float: left;
  width: 42%;
  padding: 0px 0 20px 20px;
}
.hubs_cta_action.form-cta > div:first-child {
  padding: 0px 20px 20px 0;
  border-right: 1px solid #cccccc;
}
.hubs_cta_action .cta-success-link-content,
.hubs_cta_action .never-hide-cta-action,
.hubs_cta_action .custom-form-handler-url {
  padding-left: 12px;
  border-left: 2px solid #eee;
  margin: 5px 0 20px 12px;
}
.hubs_cta_action .never-hide-cta-action {
  overflow: hidden;
}
.hubs_cta_action .never-hide-cta-action .cta-field-radio-title input[type="radio"] {
  display: inline;
  margin-right: 5px;
}
.hubs_cta_action label.launch-item-label {
  float: left;
}
.hubs_cta_action .launch-item-selection {
  float: right;
  margin-right: 5px;
}
.hubs_cta_action .success-message + div {
  padding-top: 10px;
}
.hubs_cta_action .success-message + div.note {
  padding-top: 5px;
}
.hubs_cta_action .progressive-profiling-container {
  margin-bottom: 10px;
}
.hubs_cta_action .progressive-profiling-container .progressive-text {
  margin-left: 10px;
}
.hubs_cta_action .progressive-profiling-container input[type="text"].progressive-max-fields {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 0 1px 0 3px;
}
.hubs_cta_action input[type="checkbox"].opt-in-toggle {
  float: left;
  margin: 9px 10px 0 0;
}
.hubs_cta_action input[type="text"].opt-in-label {
  width: 180px;
  width: calc(95% - 30px);
  margin-left: 10px;
}
.hubs_cta_action label.pull-left,
.hubs_cta_integration label.pull-left {
  margin: 8px 5px 20px 0;
}
.hubs_cta_design {
  max-width: 320px;
  text-align: center;
}
.hubs_cta_design .slider {
  width: 210px !important;
  /*hack*/
}
.hubs_cta_design label {
  text-align: center;
}
.hubs_cta_design .cta_font_size {
  width: 40px;
  margin-left: 10px;
}
.hubs_cta_design div.left {
  width: 140px;
  margin: 0 20px 12px 0;
}
.hubs_cta_design .font-size-slider {
  margin-top: 30px;
}
.hubs_cta_design .font-size-slider.disabled {
  pointer-events: none;
}
.hubs_cta_design .cta-bg-upload-controls {
  margin-top: 30px;
}
.hubs_cta_design .cta-bg-upload-controls input[type="file"] {
  width: 100px;
  margin: 0 auto;
  border: 1px solid red;
  text-align: center;
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  cursor: pointer;
}
.hubs_cta_design .cta-bg-upload-controls #remove-cta-bg {
  font-size: 0.9em;
  margin-top: 6px;
  font-weight: bold;
}
.hubs_cta_info input[type="text"],
.hubs_cta_action input[type="text"],
.hubs_cta_integration input[type="text"] {
  width: 98%;
  margin-bottom: 6px;
}
.hubs_cta_info input[type="text"].cta-list-id,
.hubs_cta_action input[type="text"].cta-list-id,
.hubs_cta_integration input[type="text"].cta-list-id {
  max-width: 50px;
  margin: 0;
  text-align: center;
}
.hubs_cta_info .cta-list-id-container,
.hubs_cta_action .cta-list-id-container,
.hubs_cta_integration .cta-list-id-container {
  line-height: 28px;
}
.hubs_cta_info .cta-list-id-container:before,
.hubs_cta_action .cta-list-id-container:before,
.hubs_cta_integration .cta-list-id-container:before,
.hubs_cta_info .cta-list-id-container:after,
.hubs_cta_action .cta-list-id-container:after,
.hubs_cta_integration .cta-list-id-container:after {
  display: table;
  content: " ";
}
.hubs_cta_info .cta-list-id-container:after,
.hubs_cta_action .cta-list-id-container:after,
.hubs_cta_integration .cta-list-id-container:after {
  clear: both;
}
.hubs_cta_info .cta-list-id-container span,
.hubs_cta_action .cta-list-id-container span,
.hubs_cta_integration .cta-list-id-container span,
.hubs_cta_info .cta-list-id-container .form-control,
.hubs_cta_action .cta-list-id-container .form-control,
.hubs_cta_integration .cta-list-id-container .form-control,
.hubs_cta_info .cta-list-id-container .btn,
.hubs_cta_action .cta-list-id-container .btn,
.hubs_cta_integration .cta-list-id-container .btn {
  float: left;
}
.hubs_cta_action input[type="text"] {
  width: 98%;
}
.hubs_cta_config {
  max-width: none;
  margin: 0;
}
.hubs_cta_config .accordion .cta-placement-container,
.hubs_cta_config .accordion .variation-items-container {
  width: 99%;
  width: calc(100% - 3px);
}
.hubs_cta_config .accordion .cta-placement-container .is-list-view,
.hubs_cta_config .accordion .variation-items-container .is-list-view {
  text-align: center;
}
.hubs_cta_config .accordion .accordion-group {
  background-color: #fff;
  margin-bottom: 0;
  border: 0 none;
  border-bottom: 1px solid #e5e5e5;
  border-radius: 0;
}
.hubs_cta_config .accordion .accordion-group .accordion-heading .accordion-toggle {
  padding: 15px;
}
.hubs_cta_config .accordion .accordion-group:first-child {
  border-top: 1px solid #e5e5e5;
}
.hubs_cta_config .font-size-slider {
  margin-top: 30px;
}
.hubs_cta_config .font-size-slider.disabled {
  pointer-events: none;
}
.hubs_cta_config .add-new-placement-btn,
.hubs_cta_config .add-new-variation-btn {
  text-align: center;
  padding-top: 20px;
  margin-bottom: 0;
}
.hubs_cta_config .add-new-placement-btn.disabled,
.hubs_cta_config .add-new-variation-btn.disabled {
  display: none;
}
.hubs_cta_action textarea {
  width: 98%;
  height: 65px;
  min-height: 65px;
  max-height: 65px;
}
.cta-preview-switch {
  display: inline-block;
  font-size: 10px;
  line-height: 12px;
  font-weight: bold;
}
.placement-footer {
  padding: 7px;
  margin-top: 5px;
  border-top: 1px solid #dddddd;
  background-color: #eeeeee;
}
[class^="step-icon-"],
[class*=" step-icon-"] {
  display: inline-block;
  width: 26px;
  height: 26px;
  line-height: 26px;
  vertical-align: text-top;
  background-image: url("/img/hubs/backend/spritesheet.png");
  background-position: 0px 0;
  background-repeat: no-repeat;
  margin-top: -4px;
  margin-right: 10px;
}
.step-icon-integration {
  background-position: -407px -368px;
}
.step-icon-layout {
  background-position: -448px -369px;
}
.step-icon-design {
  background-position: -487px -369px;
}
.step-icon-placements {
  background-position: -527px -370px;
}
.step-icon-variations {
  background-position: -527px -407px;
}
.hubs_cta_section_block_wrapper {
  position: relative;
  display: table;
  width: 100%;
  margin-bottom: 40px;
  box-sizing: border-box;
  border: 1px solid #cccccc;
}
.hubs_cta_section_block_wrapper .hubs_cta_section_block {
  display: table-row;
  width: 100%;
  min-width: 500px;
  background-color: #f9f9f9;
}
.hubs_cta_section_block_wrapper .hubs_cta_section_block:before,
.hubs_cta_section_block_wrapper .hubs_cta_section_block:after {
  display: none;
}
.hubs_cta_section_block_wrapper .hubs_cta_section_block > .tab-content {
  width: 95%;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection {
  display: table-cell;
  width: 40%;
  height: 100%;
  vertical-align: top;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection:first-child {
  width: 60%;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection hr {
  border-top: 1px dashed #cccccc;
  margin-top: 20px;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection label {
  color: #333333;
  font-size: 0.9em;
  margin-top: 6px;
  font-weight: bold;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper {
  position: relative;
  min-height: 400px;
  margin-right: -3px;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-wrapper,
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-nav {
  padding: 20px 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid #cccccc;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-wrapper {
  padding: 0;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-wrapper .cta-show-tab-container {
  display: block;
  padding: 0;
  margin: 0;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-wrapper .cta-show-tab-container .btn {
  position: relative;
  width: 33.3%;
  height: 40px;
  line-height: 30px;
  border: 0 none;
  color: #eee;
  background: #999;
  text-shadow: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0 none;
  z-index: initial;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-wrapper .cta-show-tab-container .btn .arrow {
  display: block;
  position: absolute;
  top: 0;
  left: auto;
  right: -40px;
  bottom: auto;
  width: 0;
  height: 0;
  border-color: transparent transparent transparent #eee;
  border-style: solid;
  border-width: 20px;
  -moz-transform: scale(0.9999);
  font-size: 0;
  line-height: 0;
  _border-top-color: cyan;
  _border-right-color: cyan;
  _border-bottom-color: cyan;
  _filter: chroma(color=cyan);
  content: "";
  z-index: 5;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-wrapper .cta-show-tab-container .btn .arrow:after {
  display: block;
  position: absolute;
  top: -20px;
  left: auto;
  right: -19px;
  bottom: auto;
  width: 0;
  height: 0;
  border-color: transparent transparent transparent #999;
  border-style: solid;
  border-width: 20px;
  -moz-transform: scale(0.9999);
  font-size: 0;
  line-height: 0;
  _border-top-color: cyan;
  _border-right-color: cyan;
  _border-bottom-color: cyan;
  _filter: chroma(color=cyan);
  content: "";
  z-index: 6;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-wrapper .cta-show-tab-container .btn:hover {
  background: #888;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-wrapper .cta-show-tab-container .btn:hover .arrow:after {
  width: 0;
  height: 0;
  border-color: transparent transparent transparent #888;
  border-style: solid;
  border-width: 20px;
  -moz-transform: scale(0.9999);
  font-size: 0;
  line-height: 0;
  _border-top-color: cyan;
  _border-right-color: cyan;
  _border-bottom-color: cyan;
  _filter: chroma(color=cyan);
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-wrapper .cta-show-tab-container .btn.active {
  background: #666;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-wrapper .cta-show-tab-container .btn.active .arrow:after {
  width: 0;
  height: 0;
  border-color: transparent transparent transparent #666;
  border-style: solid;
  border-width: 20px;
  -moz-transform: scale(0.9999);
  font-size: 0;
  line-height: 0;
  _border-top-color: cyan;
  _border-right-color: cyan;
  _border-bottom-color: cyan;
  _filter: chroma(color=cyan);
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-wrapper .cta-show-tab-container.step-count-4 .btn {
  width: 25%;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-wrapper .cta-show-tab-container.step-count-5 .btn {
  width: 20%;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-tab-content {
  min-height: 390px;
  padding: 20px 0;
  vertical-align: top;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-tab-content h3 {
  margin-bottom: 20px;
  padding: 0px 10px;
  text-align: center;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-tab-content .hubs_cta_integration[data-integration-type="form_cta_type"] {
  margin-top: 60px;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-tab-content .integration-tab,
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-tab-content .action-tab,
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-tab-content .design-tab {
  padding: 0px 20px;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-tab-content .integration-tab .row {
  margin-top: 10px;
  margin-bottom: 5px;
  line-height: 32px;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-nav {
  height: 46px;
  padding: 8px 0;
  border-top: 1px solid 1px solid #cccccc;
  border-bottom: 0;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-nav > div {
  margin: 0 20px;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-nav a.btn:not(.btn-info) {
  color: #fff;
  background: #999;
  text-shadow: none;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-nav a.btn:not(.btn-info):hover {
  background: #666;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-nav a.btn i {
  display: inline-block;
  width: 13px;
  height: 13px;
  line-height: 13px;
  vertical-align: text-top;
  background-image: url("/img/hubs/backend/spritesheet.png");
  background-position: 0px 0;
  background-repeat: no-repeat;
  margin-top: 2px;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-nav a.btn i.previous {
  background-position: -492px -174px;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-nav a.btn i.next {
  background-position: -531px -174px;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection.hubs_cta_preview {
  background: #fff url("/img/page_editor/checkered.png") repeat;
  border-left: 1px solid #cccccc;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection.hubs_cta_preview h3 {
  height: 40px;
  line-height: 40px;
  margin: 0 0 15px 0;
  background: #ddd;
  text-align: center;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection.hubs_cta_preview .cta-preview-switch-container {
  margin: 0 auto;
  text-align: center;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection.hubs_cta_preview .tile.single .cta-field-sprite-container {
  top: 29px !important;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection.hubs_cta_preview .hub_cta_preview_element {
  width: 250px;
  margin: 5px auto;
  background-color: #cbcbcb;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection.hubs_cta_preview .hub_cta_preview_element .tile.single .hidden-cta-fields.fields-revealed .smaller .cta-field-sprite-container {
  bottom: -1px !important;
  top: initial !important;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection.hubs_cta_preview .hub_cta_preview_element .tile.single .hidden-cta-fields .cta-field-section.one-line span {
  line-height: 24px;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection.hubs_cta_preview .hub_cta_preview_element .cta-activate-button-container {
  padding-bottom: 10px;
  padding-top: 0;
  width: 100%;
}
.hubs_cta_section_block_wrapper .hubs_cta_subsection.hubs_cta_preview .hub_cta_preview_element > .tile.single.cta.cta-preview {
  width: 250px !important;
}
.hubs_cta_section_block_wrapper .cta-show-tab-container {
  display: inline-block;
  padding-top: 10px;
  margin: auto 12px;
  text-align: center;
}
.hubs_cta_section_block_wrapper .cta-show-tab-container .btn {
  min-width: 80px;
}
.hubs_cta_section_block_wrapper .cta-show-tab-container:before,
.hubs_cta_section_block_wrapper .cta-show-tab-container:after {
  display: none;
}
.variations-container .hubs_cta_icon_label {
  max-width: 80%;
}
.variations-container .accordion-inner {
  font-size: 1em;
}
.variations-container .variation-image {
  float: left;
  width: 100px;
  height: 45px;
  margin-left: 15px;
  border: 1px solid #dddddd;
  overflow: hidden;
}
.variations-container .variation-image img {
  width: 100%;
}
.variations-container .hubs_cta_variation {
  position: relative;
}
.variations-container .hubs_cta_variation .variation-colors {
  width: 130px;
  padding-left: 40px;
}
.variations-container .hubs_cta_variation .variation-options {
  overflow: hidden;
  padding: 0px 20px 20px 20px;
}
.variations-container .hubs_cta_variation .variation-options > div {
  margin-bottom: 15px;
}
.variations-container .hubs_cta_variation .variation-options .launch-item-selection {
  position: relative;
  bottom: -5px;
  right: -10px;
}
.variations-container .hubs_cta_variation .variation-options .variation-font-size-slider {
  width: 210px;
}
.variations-container .hubs_cta_variation .variation-options .link-action-container .form-control {
  display: inline-block;
}
.variations-container .hubs_cta_variation .variation-options .link-action-container .link-type {
  width: 16%;
  height: 32px;
}
.variations-container .hubs_cta_variation .variation-options .link-action-container .link-input-container {
  position: relative;
  right: -10px;
  overflow: hidden;
  padding-right: 10px;
}
.variations-container .hubs_cta_variation .variation-options textarea,
.variations-container .hubs_cta_variation .variation-colors textarea {
  width: 100%;
  height: 65px;
}
.variations-container .hubs_cta_variation .variation-options input,
.variations-container .hubs_cta_variation .variation-colors input {
  width: 100%;
}
.variations-container .empty-variation {
  padding-top: 100px;
  min-height: 220px;
  text-align: center;
  color: #999999;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
@media only screen and (max-width: 1650px) {
  .variations-container .collapsed .hubs_cta_icon_label {
    max-width: 65%;
  }
  .variations-container .hubs_cta_icon_label {
    max-width: 55%;
  }
}
@media only screen and (min-device-width: 1210px) and (max-width: 1400px) {
  .variations-container .collapsed .hubs_cta_icon_label {
    max-width: 60%;
  }
  .variations-container .hubs_cta_icon_label {
    max-width: 45%;
  }
}
.oldie .hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-wrapper .cta-show-tab-container .btn .arrow {
  width: 21px;
  height: 40px;
  right: -20px;
  border: 0 none;
  background: transparent url("/img/hubs/backend/step_arrows.png") no-repeat 0 0;
}
.oldie .hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-wrapper .cta-show-tab-container .btn .arrow:after {
  display: none;
}
.oldie .hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-wrapper .cta-show-tab-container .btn:hover .arrow {
  background-position: 0px -40px;
}
.oldie .hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-wrapper .cta-show-tab-container .btn.active .arrow {
  background-position: 0px -80px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-wrapper .cta-show-tab-container .btn {
    overflow: visible;
    z-index: auto;
  }
  .hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-wrapper .cta-show-tab-container .btn .arrow {
    z-index: 100;
  }
}
@media only screen and (max-width: 1100px), only screen and (min-width: 1201px) and (max-width: 1350px) {
  .hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-wrapper .cta-show-tab-container .btn .txt {
    display: none;
  }
  .hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-wrapper .cta-show-tab-container .btn [class^="step-icon-"],
  .hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-wrapper .cta-show-tab-container .btn [class*=" step-icon-"] {
    margin-right: -15px;
  }
  .hubs_cta_action input[type="text"] {
    width: 95%;
  }
}
@media only screen and (max-width: 1600px) {
  .hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-wrapper .cta-show-tab-container.step-count-5 .btn .txt {
    display: none;
  }
  .hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-wrapper .cta-show-tab-container.step-count-5 .btn [class^="step-icon-"],
  .hubs_cta_section_block_wrapper .hubs_cta_subsection .hubs_cta_subsection_inner_wrapper .cta-action-tabs-wrapper .cta-show-tab-container.step-count-5 .btn [class*=" step-icon-"] {
    margin-right: -15px;
  }
}
.hubs_cta_icon {
  float: left;
  clear: none;
  width: 22px;
  height: 20px;
  background: transparent url("/img/hubs/backend/spritesheet2.png") no-repeat;
  opacity: 0.5;
}
.hubs_cta_icon.home {
  background-position: -494px -156px;
}
.hubs_cta_icon.docs {
  background-position: -256px -156px;
}
.hubs_cta_icon.social {
  background-position: -303px -156px;
}
.hubs_cta_icon.videos {
  background-position: -350px -156px;
}
.hubs_cta_icon.blogs {
  background-position: -395px -156px;
}
.hubs_cta_icon.featured {
  background-position: -443px -156px;
}
.hubs_cta_icon.custom {
  background-position: -549px -156px;
}
.hubs_cta_icon_label {
  float: left;
  clear: none;
  max-width: 40%;
  padding: 2px 2px 0 6px;
  font-size: 1.2em;
  color: #999999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.enabled .hubs_cta_icon {
  opacity: 1;
}
.enabled .hubs_cta_icon_label {
  color: #333333;
}
.collapsed .hubs_cta_icon_label {
  max-width: 80%;
}
.add_inline_cta_section hr {
  border-top: 1px solid #cccccc;
  margin: 5px 0 5px 0;
}
.add_inline_cta_section .add_cta_header .add_cta_header_title {
  float: left;
  padding-top: 10px;
  font-size: 1.25em;
}
.add_inline_cta_section .add_cta_header .cancel-button {
  float: right;
}
.add_inline_cta_section .add_cta_body .add_cta_inner_body .hubs_cta_inline_preview {
  display: inline-block;
  width: 370px;
  margin: 0;
  background: #fff;
}
.add_inline_cta_section .add_cta_body .add_cta_inner_body .hubs_cta_inline_preview .place_cta_btn {
  margin-left: 80px;
}
.add_inline_cta_section .step1 {
  padding: 10px;
  background-color: #e7e7e2;
  -webkit-box-shadow: inset 0 -10px 20px #d4d4cf;
  box-shadow: inset 0 -10px 20px #d4d4cf;
}
.add_inline_cta_section .step1 .add_cta_body {
  width: 99%;
  margin: 0 auto;
  padding: 10px 0;
  overflow: hidden;
}
.add_inline_cta_section .step1 .add_cta_body .cta_scroll {
  display: inline-block;
  width: 4%;
  height: 240px;
  color: #999999;
  text-align: middle;
  background-color: #fff;
  cursor: pointer;
  opacity: 0.65;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.add_inline_cta_section .step1 .add_cta_body .cta_scroll div {
  width: 15px;
  height: 46px;
  margin: 100px auto 0 auto;
  vertical-align: middle;
  background: url("/img/hubs/backend/spritesheet2.png") no-repeat;
}
.add_inline_cta_section .step1 .add_cta_body .cta_scroll .scroll_left {
  float: left;
}
.add_inline_cta_section .step1 .add_cta_body .cta_scroll .scroll_left div {
  background-position: -153px -212px;
}
.add_inline_cta_section .step1 .add_cta_body .cta_scroll .scroll_right {
  float: right;
  margin-left: 4px;
}
.add_inline_cta_section .step1 .add_cta_body .cta_scroll .scroll_right div {
  background-position: -135px -212px;
}
.add_inline_cta_section .step1 .add_cta_body .cta_scroll:hover,
.add_inline_cta_section .step1 .add_cta_body .cta_scroll:focus {
  opacity: 1;
}
.add_inline_cta_section .step1 .add_cta_body .cta_item_group {
  display: inline-block;
  float: left;
  width: 88%;
  height: 220px;
  margin: 0 5px;
  padding: 10px 5px;
  overflow: auto;
  white-space: nowrap;
  text-align: center;
}
.add_inline_cta_section .step1 .add_cta_body .cta_item_group .hub_cta_add_element {
  display: inline-block;
}
.add_inline_cta_section .step1 .add_cta_body .cta_item_group .hub_cta_add_element .tile {
  margin-top: 18px;
  margin-bottom: 0;
}
.add_inline_cta_section .step2 {
  padding: 10px;
  margin: 0 auto;
  background-color: #e7e7e2;
  -webkit-box-shadow: inset 0 -10px 20px #d4d4cf;
  box-shadow: inset 0 -10px 20px #d4d4cf;
}
.add_inline_cta_section .step2 .add_cta_body {
  width: 100%;
  padding: 10px 0;
  text-align: middle;
}
.add_inline_cta_section .step2 .add_cta_body .add_cta_inner_body {
  width: 600px;
  margin: 0 auto;
}
.add_inline_cta_section .step2 .add_cta_body .add_cta_inner_body .hub_cta_add_element {
  display: block;
}
.add_inline_cta_section .step2 .hubs_cta_inline_preview {
  width: 300px;
}
.add_inline_cta_section .step2 .hubs_cta_inline_preview .accordion-inner {
  padding: 9px;
}
.add_inline_cta_section .step2 .hubs_cta_inline_preview .accordion-inner .hubs_cta_position {
  padding-top: 0;
}
.add_inline_cta_section .step2 .hubs_cta_inline_preview .accordion-inner .hubs_cta_position .hubs_cta_position-group {
  width: 100%;
  height: 40px;
  margin: 0 auto;
}
.add_inline_cta_section .step2 .hubs_cta_inline_preview .accordion-inner .hubs_cta_position .hubs_cta_position-group .hubs_cta_position_label,
.add_inline_cta_section .step2 .hubs_cta_inline_preview .accordion-inner .hubs_cta_position .hubs_cta_position-group .hubs_cta_position_input {
  margin-right: 2px;
}
.add_inline_cta_section .step2 .hubs_cta_inline_preview .accordion-inner .hubs_cta_position .hubs_cta_position-group .example-positioning-group {
  display: block;
  float: left;
  width: 75px;
  margin: 0 auto;
  opacity: 1;
}
.add_inline_cta_section .step2 .hubs_cta_inline_preview .accordion-inner .hubs_cta_position .hubs_cta_position-group .example-positioning-group .item-example-tile {
  width: 20px;
  height: 20px;
  margin-top: 4px;
  padding-top: 10px;
}
.add_inline_cta_section .step2 .hubs_cta_inline_preview .accordion-inner .hubs_cta_position .hubs_cta_position-group .example-positioning-group .item-example-tile.item-CTA {
  font-size: 0.6em;
}
.add_inline_cta_section .hub_cta_add_element.preview {
  width: 100%;
}
.add_inline_cta_section .add-cta-note {
  margin-top: 2px;
  font-size: 0.9em;
}
label.cta-field-checkbox-title {
  display: inline;
  font-weight: normal;
}
label.cta-field-checkbox-title input[type=checkbox] {
  display: inline;
}
label.cta-field-checkbox-title .cta-field-checkbox {
  display: inline-block;
  width: 30px !important;
}
.cta-edit-form-fields {
  margin-right: 2%;
  width: 48%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cta-edit-form-fields.field-type-hidden > label {
  color: #999999;
  font-weight: normal;
}
.cta-edit-form-fields input {
  box-shadow: none;
}
select#cta-selection {
  font-size: 16px;
}
.cta-list-dropdown {
  width: 300px;
}
.cta-follow-select {
  max-width: 169px;
  font-size: 0.9em;
}
.cta-follow-select.cta-follow-day,
.cta-follow-select.cta-follow-time {
  max-width: 85px;
  padding: 6px 4px;
}
/*

.cta-form-integrations {
    margin-right: 20px
 }

.side-note {
    color: darken(@gray-lighter, 6.7%);  // #ddd
 }

.integration-test > .cta-service-success {
    font-weight: bold;
    color: green
 }

.cta-integration-list {
    .text-overflow();
 }

*/
.cta-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}
.cta-overlay.overlay-message > div {
  position: relative;
  top: 15%;
  left: auto;
  right: auto;
  bottom: auto;
  width: 50%;
  padding: 40px;
  margin: 0 auto;
  text-align: center;
  background: #fff;
  -webkit-box-shadow: 0 0 14px #aaaaaa;
  box-shadow: 0 0 14px #aaaaaa;
}
.cta-overlay.overlay-message > div > h3 > span {
  color: #666666;
  margin: 0 6px;
  padding: 7px;
  border: 1px solid #dddddd;
  font-size: 12px;
}
.cta-overlay.overlay-message .missing-marketo-fields {
  margin: 0 auto;
  margin-top: 20px;
}
.cta-overlay.overlay-message .missing-marketo-fields > div:first-child {
  padding: 5px 10px;
  font-size: 13px;
  font-weight: bold;
  background: #eeeeee;
  border-bottom: 1px solid #cccccc;
}
.cta-overlay.overlay-message .missing-marketo-fields > div:last-child > div {
  padding: 7px;
  text-align: center;
}
.cta-overlay.overlay-message .missing-marketo-fields > div:last-child > div:nth-child(even) {
  background-color: #eeeeee;
}
.cta-overlay.overlay-message .missing-marketo-fields > div:last-child > div.exists {
  opacity: 0.5;
}
.cta-overlay.overlay-message .missing-marketo-fields > div:last-child > div.error {
  color: #b94a48;
}
.cta-overlay.overlay-message .missing-marketo-fields > div:last-child > div.error:hover {
  background: #aaaaaa !important;
  color: #fff !important;
}
.cta-overlay.overlay-message .missing-marketo-fields .name,
.cta-overlay.overlay-message .missing-marketo-fields .apiName {
  display: inline-block;
  width: 36%;
}
.cta-overlay.overlay-message .missing-marketo-fields .type {
  display: inline-block;
  width: 15%;
}
.cta-overlay.overlay-message .missing-marketo-fields .status {
  display: inline-block;
  width: 8%;
}
.cta-overlay.overlay-message.hide-marketo-message {
  top: -50px;
}
.cta-overlay.overlay-message.hide-marketo-message > div {
  width: 850px;
  top: -25px;
}
.cta-overlay.overlay-body {
  z-index: 999;
  opacity: 0.8;
  -moz-opacity: 0.8;
  filter: alpha(opacity=80);
  background: #fff;
}
.cta-overlay.hide-cta-container {
  display: block;
}
.cta-add-placement-modal .modal-body .heading {
  text-align: center;
}
.cta-add-placement-modal .modal-body .heading .new-cta-placement {
  font-size: 18px;
}
.cta-add-placement-modal .modal-body .cta-items:before,
.cta-add-placement-modal .modal-body .cta-items:after {
  display: table;
  content: " ";
}
.cta-add-placement-modal .modal-body .cta-items:after {
  clear: both;
}
.cta-add-placement-modal .modal-body .cta-items .cta-carousel {
  line-height: normal;
  margin: 6px 35px;
}
.cta-add-placement-modal .modal-body .cta-items .cta-carousel .carousel-control {
  top: 50%;
}
.cta-add-placement-modal .modal-body .cta-items .cta-add-placement-singular {
  margin-top: 6px;
}
.cta-add-placement-modal .modal-body .cta-items .tile.single.cta {
  float: none !important;
  margin: 0 auto !important;
  display: block;
}
.cta-add-placement-modal .modal-body .cta-items .tile.single.cta .hidden-cta-fields .cta-field-section .cta-field-sprite-container {
  top: 29px;
}
.cta-add-placement-modal .modal-body .hubs_cta_position {
  text-align: center;
  margin-bottom: 20px;
}
.cta-add-placement-modal .modal-body .hubs_cta_position .hubs_cta_position_label {
  width: auto;
}
.cta-add-placement-modal .modal-body .hubs_cta_position .example-positioning-group {
  float: none;
  display: inline-block;
  height: 35px;
  margin-top: 20px;
  opacity: 1;
}
.cta-add-placement-modal .modal-footer {
  text-align: center;
}
.add-cta-modal .modal-dialog {
  width: 640px;
}
.add-cta-modal .modal-body {
  position: relative;
  width: auto;
  height: 500px;
}
.add-cta-modal .modal-body form {
  padding-left: 30px;
  padding-top: 15px;
}
.add-cta-modal .modal-body form:before,
.add-cta-modal .modal-body form:after {
  display: table;
  content: " ";
}
.add-cta-modal .modal-body form:after {
  clear: both;
}
.add-cta-modal .modal-body .step-2 .step-2-header {
  text-align: center;
  padding-bottom: 30px;
  padding-top: 50px;
  font-size: 12px;
}
.add-cta-modal .modal-body .step-2 .form-element {
  margin: 0 auto 20px;
  width: 450px;
}
.add-cta-modal .modal-body .step-2 .select2-container .select2-choice {
  height: 36px;
  line-height: 32px;
  text-align: left;
}
.add-cta-modal .modal-body .step-2 .select2-container .select2-choice i.icon-large,
.add-cta-modal .modal-body .step-2 .select2-result-label i.icon-large {
  vertical-align: middle;
  margin-right: 10px;
}
.add-cta-modal .modal-body .step-2 .note {
  display: inline-block;
  margin-top: 40px;
}
.add-cta-modal .modal-body .step-3 .step-3-header {
  text-align: center;
  padding-bottom: 30px;
  padding-top: 50px;
  font-size: 10px;
}
.add-cta-modal .modal-body .step-3 .new-cta-title-input {
  margin: 10px auto 30px auto;
  color: #555;
}
.add-cta-modal .modal-body .step-3 .cta-integration-type-preview {
  margin-top: 30px;
  position: relative;
  width: 35%;
  height: 80px;
  text-align: center;
  display: inline-block;
  cursor: default;
}
.add-cta-modal .modal-body .step-3 .cta-integration-type-preview:hover {
  border-color: #999999;
}
.add-cta-modal .modal-body .step-3 .cta-integration-type-preview .integration-setup-sprite {
  margin: auto;
}
.add-cta-modal .modal-body .step-4 {
  padding-top: 30px;
}
.add-cta-modal .modal-body .step-5 .copy-cta-title-input {
  width: 80%;
  height: 50px;
  margin: 30px auto 30px auto;
  padding-left: 15px;
  color: #555;
  font-size: 2em;
}
.add-cta-modal .modal-body .step-5 .copy-cta-description {
  font-size: 13px;
  padding: 0px 30px 30px 50px;
}
.add-cta-modal .modal-body .add-cta-type-section {
  width: 250px;
}
.add-cta-modal .modal-body .add-cta-type-section .new-cta-type {
  width: 250px;
  height: 350px;
  padding: 0px 20px 20px 20px;
  cursor: pointer;
  border: 1px solid #fff;
  opacity: 0.5;
  -webkit-transition: opacity 100ms ease-in-out;
  -o-transition: opacity 100ms ease-in-out;
  transition: opacity 100ms ease-in-out;
}
.add-cta-modal .modal-body .add-cta-type-section .new-cta-type label {
  text-align: center;
  padding: 12px;
  margin: 0;
  line-height: 18px;
}
.add-cta-modal .modal-body .add-cta-type-section .new-cta-type:hover {
  border-color: #dddddd;
  opacity: 1;
}
.add-cta-modal .modal-body .add-cta-type-section .add-cta-type-section-text {
  padding-top: 25px;
  text-align: center;
}
.add-cta-modal .modal-body .add-cta-type-section .add-cta-type-section-text span {
  text-align: center;
}
.add-cta-modal .modal-body .add-cta-type-section .add-cta-type-section-text .integration-icon-container {
  margin-top: 6px;
}
.add-cta-modal .modal-body .add-cta-type-section .add-cta-type-section-text .integration-icon-container .integration-setup-sprite {
  display: inline-block;
}
.add-cta-modal .modal-body .add-cta-type-section .add-cta-type-section-text.add-cta-type-left-text {
  padding-left: 40px;
  padding-right: 40px;
}
.add-cta-modal .modal-body .add-cta-type-section.add-cta-type-section-right {
  position: relative;
  top: -5px;
  left: auto;
  right: auto;
  bottom: auto;
}
.add-cta-modal .modal-body .add-cta-middle-section {
  width: 5px;
  height: 500px;
  margin-right: 40px;
}
.add-cta-modal .modal-body .add-cta-middle-section .add-cta-line {
  width: 1px;
  height: 140px;
  margin: 0 auto;
  border-right: 1px solid #dddddd;
}
.add-cta-modal .modal-body .add-cta-middle-section .add-cta-middle-section-container {
  position: absolute;
  top: 120px;
  left: 313px;
  right: auto;
  bottom: auto;
}
.add-cta-modal .modal-body .add-cta-middle-section .add-cta-middle-section-container .add-cta-middle-section-text {
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
}
.add-cta-modal .modal-body .add-cta-middle-section .add-cta-middle-section-container .add-cta-middle-section-text span {
  color: #888888;
  font-size: 16px;
}
.add-cta-modal .modal-body .clear-background {
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
}
.add-cta-modal .modal-body .integration-setup-sprite {
  position: relative;
  top: 10px;
}
.add-cta-modal .modal-body .integration-setup-sprite.mailchimp-text {
  position: relative;
  top: 20px;
}
.cta-integration-dropdown .select2-result-label i.icon-large {
  vertical-align: middle;
  margin-right: 10px;
}
#no-lists-modal .no-lists-body {
  padding: 40px 60px 0 60px;
}
#no-lists-modal .error-container {
  height: 128px;
  margin-bottom: 10px;
  text-align: center;
}
#no-lists-modal .error-header {
  color: #ce0058;
  font-size: 18px;
  text-align: center;
}
#no-lists-modal .no-lists-label {
  color: #555555;
  font-size: 12px;
}
#no-lists-modal .no-lists-link {
  color: #2f96b4;
  font-size: 13px;
  text-decoration: underline;
}
#no-lists-modal .no-lists-button-container {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 80px;
}
#upgrade-modal .lock-container {
  padding: 30px;
  text-align: center;
}
.manage-fields-modal .container {
  width: 400px;
}
.manage-fields-modal .modal-body {
  max-height: 500px;
}
.manage-fields-modal .new-field-icon img {
  margin: -3px;
  width: 18px;
  height: 18px;
}
.manage-fields-modal .edit-field-mode .upload-overlay {
  width: 538px;
  position: relative;
}
.manage-fields-modal .edit-field-mode .upload-overlay .upload-overlay-progress {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
}
.manage-fields-modal .edit-field-mode .upload-overlay .upload-overlay-progress .upload-overlay-progress-spinner {
  padding: 125px 0 0 210px;
}
.manage-fields-modal .new-field-button.new-field-icon {
  margin-left: 8px;
  margin-right: 8px;
}
.manage-fields-modal .halflings-info-sign {
  margin-left: 5px;
  margin-top: 9px;
}
.manage-fields-modal .field-settings-container {
  padding-bottom: 10px;
  text-align: center;
}
.manage-fields-modal .enter-comment {
  font-size: 15px;
  padding-bottom: 9px;
}
.manage-fields-modal .edit-field-mode .field-settings-container {
  text-align: left;
}
.manage-fields-modal .edit-field-mode .field-settings-container label.edit-section {
  text-align: center;
  padding-bottom: 16px;
  font-size: 16px;
  font-weight: bold;
}
.manage-fields-modal .edit-field-mode .field-settings-container > div {
  padding-bottom: 7px;
  margin: 10px;
}
.manage-fields-modal .edit-field-mode .field-settings-container > div:before,
.manage-fields-modal .edit-field-mode .field-settings-container > div:after {
  display: table;
  content: " ";
}
.manage-fields-modal .edit-field-mode .field-settings-container > div:after {
  clear: both;
}
.manage-fields-modal .edit-field-mode .field-settings-container > div span.field-info {
  display: inline-block;
  width: 100px;
  height: 100%;
  font-size: 15px;
}
.manage-fields-modal .edit-field-mode .field-settings-container > div select {
  display: inline-block;
  width: 158px;
}
.manage-fields-modal .edit-field-mode .field-settings-container > div.search-map-fields-container {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}
.manage-fields-modal .edit-field-mode .field-settings-container > div.search-map-fields-container #form-field-autosuggest {
  vertical-align: top;
  width: 423px;
}
.manage-fields-modal .edit-field-mode .field-settings-container > div.search-map-fields-container .btn {
  width: 74px;
}
.manage-fields-modal .edit-field-mode .field-settings-container > div.api-name-container .tooltip-message {
  vertical-align: top;
  margin-left: 6px;
}
.manage-fields-modal .edit-field-mode .field-settings-container > div input[type=text] {
  display: inline-block;
  width: 242px;
}
.manage-fields-modal .edit-field-mode .field-settings-container > div .halflings-info-sign {
  opacity: 0.4;
  margin-left: 5px;
}
.manage-fields-modal .edit-field-mode .field-settings-container .tooltip-message {
  display: inline-block;
}
.manage-fields-modal .edit-field-mode .field-settings-container .hidden-value {
  border-left: 1px solid #ddd;
  padding: 15px 0 15px 20px;
}
.manage-fields-modal .edit-field-mode .field-settings-container .hidden-value input {
  width: 223px;
}
.manage-fields-modal .edit-field-mode .field-settings-container .hidden-value > div {
  margin-bottom: 10px;
}
.manage-fields-modal .edit-field-mode .field-settings-container .hidden-value > div.label-value {
  overflow: auto;
}
.manage-fields-modal .edit-field-mode .field-settings-container .hidden-value > div.query-string-info {
  margin-bottom: 15px;
}
.manage-fields-modal .edit-field-mode .field-settings-container .hidden-value > div.query-string-info .field-info {
  font-size: 12px;
  width: 257px;
}
.manage-fields-modal .edit-field-mode .field-settings-container .hidden-value > div.query-string-info input {
  width: 14px;
}
.manage-fields-modal .edit-field-mode .field-settings-container .hidden-value label.edit-section {
  padding-top: 16px;
}
.manage-fields-modal .enter-note {
  padding-top: 15px;
}
.manage-fields-modal .fill-out-field-label {
  display: none;
}
.manage-fields-modal .fill-out-field-label .new-field-button {
  display: block;
  position: static;
  margin: 15px auto 0 auto;
  padding: 0px 15px;
  line-height: 32px;
}
.manage-fields-modal .icon-remove,
.manage-fields-modal .icon-resize-vertical {
  margin-top: 1px;
  margin-right: 5px;
  margin-left: 5px;
  opacity: 0.2;
}
.manage-fields-modal .existing-field-container {
  padding-top: 20px;
}
.manage-fields-modal .existing-field-container .field-value-list-upload {
  position: relative;
  display: inline-block;
  width: 77%;
  margin-left: 10px;
}
.manage-fields-modal .existing-field-container .field-value-list-upload .upload-btn {
  position: relative;
}
.manage-fields-modal .existing-field-container .field-value-list-upload .delete-btn {
  position: absolute;
  right: 0;
}
.manage-fields-modal .existing-field-container .field-value-list-upload input[type="file"] {
  width: 62px;
  height: 100%;
  margin: 0 auto;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.manage-fields-modal .existing-field-container .field-value-list-upload .tooltip-message {
  display: inline-block;
  margin-left: 2px;
  vertical-align: top;
}
.manage-fields-modal .existing-field-container .field-value-list-upload .tooltip-message .halflings-info-sign {
  opacity: 0.4;
}
.manage-fields-modal .existing-title {
  font-size: 16px;
  font-weight: bold;
}
.manage-fields-modal .existing-fields {
  padding-top: 15px;
}
.manage-fields-modal .field-values {
  padding-top: 5px;
}
.manage-fields-modal .field-values .existing-field .halflings-remove {
  margin: 3px 0 3px 7px;
}
.manage-fields-modal .existing-field {
  padding-top: 6px;
  padding-bottom: 6px;
  margin-bottom: 3px;
  font-size: 15px;
  background: #eeeeee;
  cursor: pointer;
  border-radius: 1px;
  -webkit-transition: background-color 0.3s linear;
  -o-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
}
.manage-fields-modal .existing-field .glyphicons-git-branch {
  font-size: 13px;
  margin: 1px 15px 0 0;
}
.manage-fields-modal .existing-field .halflings-remove,
.manage-fields-modal .existing-field .halflings-pencil {
  margin: 7px 15px 3px 0;
  font-size: 12px;
}
.manage-fields-modal .existing-field .halflings-remove,
.manage-fields-modal .existing-field .halflings-resize-vertical {
  opacity: 0.3;
}
.manage-fields-modal .existing-field .halflings-resize-vertical {
  margin: 5px 5px 5px 10px;
}
.manage-fields-modal .existing-field:hover {
  background: #dddddd;
}
.manage-fields-modal .existing-field:hover .halflings-remove,
.manage-fields-modal .existing-field:hover .halflings-resize-vertical {
  opacity: 1;
}
.manage-fields-modal .existing-field .field-name {
  display: inline-block;
  cursor: pointer;
  width: 323px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.manage-fields-modal .existing-field .field-name > .field-type-label {
  color: #999999;
  margin-left: 3px;
}
.manage-fields-modal .existing-field-placeholder {
  height: 18px;
  margin-bottom: 3px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 15px;
  background: #fff;
  border: 1px solid #eeeeee;
  border-radius: 1px;
}
.manage-fields-modal .newly-added {
  background: #dddddd;
}
.manage-fields-modal .newly-added .icon-remove,
.manage-fields-modal .newly-added .icon-resize-vertical {
  opacity: 1;
}
.manage-fields-modal .field-value-titles {
  font-weight: bold;
  margin: 15px 0 0 0;
}
.manage-fields-modal .field-value-titles .label-title {
  margin: 0 204px 0 42px;
}
.manage-fields-modal .new-field-display-name {
  display: inline-block;
  width: 220px;
  margin: 6px 0 0 36px;
}
.manage-fields-modal .new-field-value {
  display: inline-block;
  width: 220px;
  margin: 0 0 0 2px;
}
.manage-fields-modal .add-new-field-value {
  display: inline-block;
  margin-top: -6px;
}
.manage-fields-modal .field-conditions-container {
  padding-top: 30px;
}
.manage-fields-modal .field-conditions-container > span {
  display: inline-block;
  font-size: 15px;
  vertical-align: middle;
  padding-right: 6px;
}
.manage-fields-modal .field-conditions-container select {
  display: inline-block;
  width: 123px;
}
.manage-fields-modal .field-conditions-container button {
  margin-top: -2px;
  display: inline-block;
}
.manage-fields-modal .field-conditions-container .field-conditions {
  border-radius: 1px;
  padding-top: 20px;
}
.manage-fields-modal .field-conditions-container .field-conditions div,
.manage-fields-modal .field-conditions-container .field-conditions span {
  display: inline-block;
  font-size: 15px;
  vertical-align: middle;
}
.manage-fields-modal .field-conditions-container .field-conditions .halflings-remove {
  font-size: 12px;
  margin: 3px 0 3px 101px;
  cursor: pointer;
}
.manage-fields-modal .field-conditions-container .field-conditions .field-condition {
  width: 524px;
  cursor: default;
  padding-left: 6px;
}
.manage-fields-modal .field-conditions-container .field-conditions .field-condition .condition-label {
  padding: 6px 6px 6px 20px;
  width: 487px;
}
.manage-fields-modal .field-conditions-container .new-field-condition-container {
  margin-top: 6px;
}
.manage-fields-modal .field-conditions-container .new-field-condition-container > span {
  font-size: 15px;
  padding-left: 21px;
  padding-right: 10px;
}
.manage-fields-modal .edit-field-mode {
  display: none;
}
.manage-fields-modal.edit-field-mode .edit-field-mode {
  display: block;
}
.manage-fields-modal.edit-field-mode .manage-fields-mode {
  display: none;
}
.manage-fields-modal.edit-field-mode .fill-out-field-name.edit-field-mode .existing-field-container {
  display: none;
}
.manage-fields-modal.edit-field-mode.is-select .fill-out-field-name.edit-field-mode .existing-field-container {
  width: 524px;
  display: block;
}
.manage-fields-modal .fill-out-field-name div.text-center > span.note {
  display: inline-block;
}
.manage-fields-modal .value-label {
  display: inline-block;
  width: 224px;
}
.manage-fields-modal .value-value {
  display: inline-block;
  width: 180px;
  text-align: center;
}
#cta-cropper .cr-boundary {
  height: 90%;
}
.value-value {
  display: inline-block;
  width: 160px;
}
.manage-fields-modal .typeahead.dropdown-menu a {
  width: 232px;
}
.typeahead.dropdown-menu a {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #666;
  text-align: left;
}
.typeahead.dropdown-menu li > a:hover,
.dropdown-menu .active > a,
.typeahead.dropdown-menu .active > a:hover {
  background-color: #2f96b4;
  color: #fff;
}
textarea.embed-code {
  width: 98%;
  height: 80px;
  margin-bottom: 10px;
}
.icon-preview {
  display: inline-block;
  width: 48px;
  height: 48px;
  margin: 0 10px 10px 0;
  background-image: url("/img/hubs/sprite-1x.png");
  background-color: #5bc0de;
  cursor: pointer;
}
.icon-preview.menu-none {
  border: 2px dashed #ccc;
  margin: 0 15px 0 0;
  background-color: initial;
  background-image: none;
}
.icon-preview.menu-none:hover {
  border-color: #aaa;
}
.icon-preview.menu-home {
  background-position: -224px 0;
}
.icon-preview.menu-docs {
  background-position: -224px -59px;
}
.icon-preview.menu-social {
  background-position: -224px -233px;
}
.icon-preview.menu-blogs {
  background-position: -224px -116px;
}
.icon-preview.menu-custom {
  background-position: -331px 0;
}
.icon-preview.menu-videos {
  background-position: -224px -176px;
}
.menu-item-add-edit .modal-body .menu-item-upsert {
  padding-top: 20px;
}
.menu-item-add-edit .modal-body .alert {
  width: 350px;
  margin: 10px auto 10px auto;
}
.menu-item-add-edit .modal-body .menu-item-icons {
  display: none;
  text-align: center;
}
.menu-item-add-edit .modal-body .menu-item-icons .tile-container {
  display: inline-block;
  max-width: 400px;
}
.menu-item-add-edit .modal-body.show-icons .menu-item-icons {
  display: block;
}
.menu-item-add-edit .modal-body.show-icons .menu-item-upsert {
  display: none;
}
.menu-item-add-edit .modal-body .menu-item-attribute {
  padding-bottom: 20px;
}
.menu-item-add-edit .modal-body .menu-item-attribute .menu-item-attribute-text {
  display: inline-block;
  width: 170px;
  padding-right: 50px;
  text-align: right;
  font-weight: bold;
}
.menu-item-add-edit .modal-body .menu-item-attribute input.menu-item-label,
.menu-item-add-edit .modal-body .menu-item-attribute input.menu-item-url,
.menu-item-add-edit .modal-body .menu-item-attribute input.menu-item-class-name {
  display: inline-block;
  width: auto;
}
.menu-item-add-edit .modal-body .menu-item-attribute select {
  display: inline-block;
  width: 220px;
}
.menu-item-add-edit .modal-body .menu-item-attribute.has-select-icon {
  display: table;
}
.menu-item-add-edit .modal-body .menu-item-attribute.has-select-icon div,
.menu-item-add-edit .modal-body .menu-item-attribute.has-select-icon span {
  display: table-cell;
  vertical-align: middle;
}
.menu-item-add-edit .modal-body .menu-item-attribute.has-select-icon a {
  margin-left: 30px;
}
.menu-item-add-edit .modal-body .menu-item-attribute.change-type div {
  display: inline-block;
  position: relative;
  left: -17px;
}
.menu-item-add-edit .modal-body .menu-item-attribute.change-type div .menu-item-type {
  display: inline;
  margin-left: 16px;
  margin-right: 2px;
}
.menu-item-add-edit .modal-body .add-to-menu {
  padding: 20px;
  text-align: center;
}
.menu-item-add-edit .modal-body .add-to-menu a {
  font-size: 15px;
  padding: 9px 14px;
  font-weight: 500;
}
.menu-item-add-edit .modal-body .link,
.menu-item-add-edit .modal-body .collection,
.menu-item-add-edit .modal-body .section {
  display: none;
}
.menu-item-add-edit .modal-body.show-link .link {
  display: block;
}
.menu-item-add-edit .modal-body.show-collection .collection {
  display: block;
}
.menu-item-add-edit .modal-body.show-section .section {
  display: block;
}
.mgmt-container .content-container.hubs-sections-appearance {
  /* stylelint-disable */
}
.mgmt-container .content-container.hubs-sections-appearance .appearance-navbar .glyphicons {
  font-size: 15px;
}
.mgmt-container .content-container.hubs-sections-appearance .appearance-navbar .glyphicons:before {
  padding: 0px 6px 0 11px;
}
.mgmt-container .content-container.hubs-sections-appearance .nav-block-icon.nav-branding {
  width: 280px;
  margin: 0 auto 20px auto;
}
.mgmt-container .content-container.hubs-sections-appearance .nav-block-icon.nav-branding > li a {
  background-image: url("/img/hubs/backend/spritesheet.png");
  background-repeat: no-repeat;
}
.mgmt-container .content-container.hubs-sections-appearance .nav-block-icon.nav-branding > li a.block-icon-text {
  background-position: -279px 4px;
}
.mgmt-container .content-container.hubs-sections-appearance .nav-block-icon.nav-branding > li a.block-icon-images {
  background-position: -360px 1px;
}
.mgmt-container .content-container.hubs-sections-appearance .nav-block-icon.nav-branding > li a.block-icon-colours {
  background-position: -440px 1px;
}
.mgmt-container .content-container.hubs-sections-appearance .tab-pane {
  margin: 0 auto;
}
.mgmt-container .content-container.hubs-sections-appearance .tab-pane#hubs-style-text {
  width: 600px;
}
.mgmt-container .content-container.hubs-sections-appearance .tab-pane#hubs-style-text .hubs_font_option.disabled {
  pointer-events: none;
  background: #eee;
}
.mgmt-container .content-container.hubs-sections-appearance .tab-pane#hubs-style-images {
  width: auto;
  max-width: 1165px;
}
.mgmt-container .content-container.hubs-sections-appearance .tab-pane#hubs-style-images input[disabled] + .btn {
  background: #bebebe;
  border-color: #bebebe;
  cursor: not-allowed;
}
.mgmt-container .content-container.hubs-sections-appearance .tab-pane#hubs-style-colour {
  width: 600px;
}
.mgmt-container .content-container.hubs-sections-appearance .row .col-md-6 {
  text-align: center;
}
.mgmt-container .content-container.hubs-sections-appearance .input-box-body .add-on {
  margin-right: -2px;
  margin-top: 3px;
}
.mgmt-container .content-container.hubs-sections-appearance .input-box-body .hub-company-url-select-container {
  margin: 30px 0 0 0;
}
.mgmt-container .content-container.hubs-sections-appearance .input-box-body .company-url-target {
  width: 170px;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"],
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] {
  /* stylelint-enable */
  margin-top: 20px;
  margin-bottom: 60px;
  text-align: center;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"]:before,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"]:before,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"]:after,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"]:after {
  display: table;
  content: " ";
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"]:after,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"]:after {
  clear: both;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] > p,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] > p {
  width: 90%;
  height: 5em;
  margin: 0 auto;
  font-size: 13px;
  line-height: 15px;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"].image-container-primary_logo > p,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"].image-container-primary_logo > p,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"].image-container-background_image > p,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"].image-container-background_image > p {
  height: 3.3em;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"].image-container-primary_logo .input-group,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"].image-container-primary_logo .input-group,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"].image-container-background_image .input-group,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"].image-container-background_image .input-group {
  margin-right: 10px;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .uploaded-logo,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .uploaded-logo {
  position: relative;
  margin: 0 auto;
  border: 1px solid #cccccc;
  background: transparent url("/img/page_editor/checkered.png") repeat 0 0;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .uploaded-logo.primary_logo,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .uploaded-logo.primary_logo,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .uploaded-logo.background_image,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .uploaded-logo.background_image {
  width: 90%;
  height: 200px;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .uploaded-logo.secondary_logo,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .uploaded-logo.secondary_logo,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .uploaded-logo.favicon,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .uploaded-logo.favicon,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .uploaded-logo.thumbnail,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .uploaded-logo.thumbnail {
  width: 90%;
  height: 200px;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .uploaded-logo.secondary_logo > .uploaded-logo-inner,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .uploaded-logo.secondary_logo > .uploaded-logo-inner,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .uploaded-logo.favicon > .uploaded-logo-inner,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .uploaded-logo.favicon > .uploaded-logo-inner,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .uploaded-logo.thumbnail > .uploaded-logo-inner,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .uploaded-logo.thumbnail > .uploaded-logo-inner {
  margin-top: 30px;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .uploaded-logo img,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .uploaded-logo img {
  max-width: 100%;
  max-height: 100%;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .uploaded-logo.primary_logo img,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .uploaded-logo.primary_logo img,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .uploaded-logo.background_image img,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .uploaded-logo.background_image img,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .uploaded-logo.thumbnail img,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .uploaded-logo.thumbnail img,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .uploaded-logo.secondary_logo img,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .uploaded-logo.secondary_logo img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .uploaded-logo .no-preview-item,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .uploaded-logo .no-preview-item {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 260px;
  height: 40px;
  background: #fff;
  border: 1px solid #cccccc;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .uploaded-logo .no-preview-item h4,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .uploaded-logo .no-preview-item h4 {
  font-size: 13px;
  color: #888888;
  font-weight: normal;
  line-height: 40px;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .favicon-image-table .display-cell,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .favicon-image-table .display-cell,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .favicon-caption-table .display-cell,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .favicon-caption-table .display-cell {
  vertical-align: middle;
  text-align: center;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .favicon-caption-table,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .favicon-caption-table {
  width: 90%;
  margin: 5px auto;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .favicon-caption-table .display-row,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .favicon-caption-table .display-row {
  color: #666666;
  background: #fff;
  text-align: center;
  font-size: 11px;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .image-dimensions,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .image-dimensions {
  margin: 10px 0;
  text-align: center;
  color: #666666;
  font-size: 13px;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .image-dimensions strong,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .image-dimensions strong {
  color: #555;
  white-space: nowrap;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .button-row:before,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .button-row:before,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .button-row:after,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .button-row:after {
  display: table;
  content: " ";
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .button-row:after,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .button-row:after {
  clear: both;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .button-row .upload-button-container,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .button-row .upload-button-container {
  display: inline-block;
  position: relative;
  width: 100px;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .button-row .upload-button-container input[type="file"],
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .button-row .upload-button-container input[type="file"] {
  width: 100%;
  height: 28px;
  margin-left: -18px;
  line-height: 28px;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .button-row .remove-button-container,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .button-row .remove-button-container {
  display: inline-block;
  position: relative;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .button-row .remove-button-container a .icon-grey,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .button-row .remove-button-container a .icon-grey {
  opacity: 0.4;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .button-row .position-select-container,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .button-row .position-select-container {
  display: inline-block;
  position: relative;
  margin-left: 20px;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .button-row .position-select-container select,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .button-row .position-select-container select {
  margin-left: 0;
  max-width: 93px;
}
.mgmt-container .content-container.hubs-sections-appearance .form-large [class^="image-container-"] .button-row .position-select-container .input-prepend,
.mgmt-container .content-container.hubs-sections-appearance .form-large [class*=" image-container-"] .button-row .position-select-container .input-prepend {
  margin-bottom: 0;
}
.mgmt-container .content-container.hubs-sections-appearance .hubs-branding-colours {
  margin: 20px 0 0 0;
}
.mgmt-container .content-container.hubs-sections-appearance .hubs-branding-colours p {
  color: #666666;
}
.mgmt-container .content-container.hubs-sections-appearance .hubs-branding-colours .sp-replacer.sp-disabled {
  cursor: not-allowed;
}
.mgmt-container .content-container.hubs-sections-appearance .hubs-branding-colours .sp-replacer .sp-preview {
  width: 120px;
}
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container {
  position: relative;
  width: 780px;
  margin: 0 auto;
}
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .info-table {
  text-align: left;
  padding-bottom: 10px;
  margin: -20px 0 20px 0;
  background-color: #fbfbfb;
  border: 10px solid #fbfbfb;
  outline: 1px solid #eeeeee;
}
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .info-table td {
  padding: 0px 5px;
  vertical-align: top;
}
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .info-table p {
  margin: 3px 0 0 0;
}
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .filter-tabs {
  margin: 30px 0 15px;
}
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .error,
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .warning,
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .info,
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .success,
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .default {
  width: 20px;
  height: 20px;
  padding: 0px 5px;
  background-color: #fff;
  border: 1px solid #ddd;
}
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .error,
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .table tbody tr.error > td {
  background-color: #f2dede;
}
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .warning,
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .table tbody tr.warning > td {
  background-color: #fcf8e3;
}
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .info,
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .table tbody tr.info > td {
  background-color: #d9edf7;
}
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .success,
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .table tbody tr.success > td {
  background-color: #dff0d8;
}
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .input-prepend,
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .input-append {
  margin: 0;
}
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .table tbody tr > td .input-group {
  float: right;
  width: 100%;
}
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .table tbody tr > td .input-group .input-group-addon {
  cursor: default;
  background: #eeeeee;
}
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .table tbody tr > td .input-group .input-group-addon em {
  display: none;
}
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .table tbody tr > td .input-group input {
  z-index: 0;
}
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .table tbody tr > td .input-group input:focus {
  position: static;
}
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .table tbody tr.success > td .input-group .input-group-addon em {
  display: inline;
  cursor: pointer;
  font-style: normal;
  font-size: 16px;
  color: #a33;
}
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-container .table label {
  padding: 5px 0;
  margin: 0;
  font-weight: normal;
}
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-footer {
  position: relative;
  width: 100%;
  padding: 9px 0;
  margin-bottom: 0;
  text-align: center;
  background-color: #666;
}
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-footer .custom-labels-footer-inner .btn {
  margin-right: 20px;
}
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-footer.fixed {
  position: fixed;
  top: auto;
  left: 0;
  right: auto;
  bottom: 0;
}
.mgmt-container .content-container.hubs-sections-appearance .custom-labels-footer.fixed .custom-labels-footer-inner {
  margin-left: 250px;
}
.mgmt-container .content-container.hubs-sections-appearance .hubs-lab-container {
  font-family: Roboto, sans-serif;
  position: relative;
  width: 780px;
  margin: 0 auto;
}
.mgmt-container .content-container.hubs-sections-appearance .hubs-lab-container .alert {
  margin-left: 0;
}
.mgmt-container .content-container.hubs-sections-appearance .hubs-lab-container .hubs-option-block {
  margin-bottom: 20px;
}
.mgmt-container .content-container.hubs-sections-appearance .hubs-lab-container .hubs-option-block label {
  padding-left: 18px;
  color: #444;
  font-weight: normal;
  line-height: 1.2em;
}
.mgmt-container .content-container.hubs-sections-appearance .hubs-lab-container .hubs-option-block .note {
  margin-left: 22px;
}
.mgmt-container .content-container.hubs-sections-appearance .hubs-lab-container .hubs-option-block .note p {
  color: inherit;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .toggle-switch-container.disabled,
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .toggle-switch-container.disabled .toggle-switch-text {
  cursor: not-allowed;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .toggle-switch-container.disabled .toggle-switch,
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .toggle-switch-container.disabled .toggle-switch-text .toggle-switch {
  pointer-events: none;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .upgrade-alert {
  width: 238px;
  margin: 50px auto 0 auto;
  padding-right: 14px;
  text-align: center;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container h3 {
  margin-bottom: 8px;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-top-row-attributes {
  width: 500px;
  margin: 0 auto;
  overflow: hidden;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-top-row-attributes .menu-settings-container {
  float: left;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-top-row-attributes .menu-color-container {
  position: relative;
  float: right;
  box-sizing: content-box;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-top-row-attributes .menu-color-container .sp-replacer.sp-disabled {
  cursor: not-allowed;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .custom-text,
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .default-text {
  display: none;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-bottom-row-attributes {
  padding-top: 50px;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container {
  font-family: Roboto, sans-serif;
  padding-top: 70px;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .new-item.disabled,
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .btn.disabled {
  pointer-events: none;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .create-default-container {
  text-align: center;
  padding-top: 100px;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .menu-layout {
  max-width: 700px;
  padding-bottom: 50px;
  margin: 0 auto;
  text-align: right;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .menu-layout.disabled {
  pointer-events: none;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .menu-layout .menu-item {
  position: relative;
  padding-top: 10px;
  cursor: pointer;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .menu-layout .menu-item .icon-resize-vertical {
  margin-top: 6px;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .menu-layout .menu-item .icon-preview {
  position: absolute;
  left: 0;
  display: inline-block;
  margin: 0 15px 0 0;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .menu-layout .menu-item .menu-empty {
  background-color: transparent;
  background-image: none;
  cursor: default;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .menu-layout .menu-item span {
  font-size: 16px;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .menu-layout .menu-item .menu-item-sprite {
  width: 20px;
  height: 20px;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .menu-layout .menu-item .menu-item-info {
  display: inline-block;
  width: 87%;
  padding: 9px;
  background-color: #ccc;
  text-align: left;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .menu-layout .menu-item .menu-item-info .menu-item-label {
  display: inline-block;
  position: relative;
  top: 6px;
  max-width: 395px;
  color: white;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .menu-layout .menu-item .menu-item-info .menu-item-type {
  color: #555;
  font-style: italic;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .menu-layout .menu-item .menu-item-info .menu-item-action {
  border: 0;
  background: transparent;
  display: inline-block;
  padding: 6px;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .menu-layout .menu-item .menu-item-info .menu-item-action span {
  color: #fff;
  font-size: 12px;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .menu-layout .menu-item .menu-item-info .menu-item-action.edit-action {
  margin-left: 21px;
  margin-right: 8px;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .menu-layout .menu-item .menu-item-info .menu-item-action:hover {
  background-color: #bbb;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .menu-layout .menu-item .menu-item-info .menu-item-type {
  padding: 6px;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .menu-layout .menu-item .children {
  padding-left: 70px;
  overflow: hidden;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .menu-layout .menu-item .halflings-resize-vertical {
  color: #666;
  font-size: 12px;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .menu-layout .new-item {
  float: right;
  width: 85%;
  padding: 10px 12px;
  margin: 10px 15px 0 0;
  border: #ccc dashed;
  text-align: left;
  cursor: pointer;
  font-weight: bold;
  font-size: 26px;
  color: #5bc0de;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .menu-layout .new-item > span {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  position: relative;
  top: -3px;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .menu-layout .new-item:hover {
  border-color: #888;
  color: #2f96b4;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container .menu-layout .new-item:hover > span {
  color: #999;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container.default-menu {
  display: none;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container.default-menu .new-item {
  display: none;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container.default-menu .menu-item {
  cursor: initial;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container.default-menu .menu-item i {
  opacity: 0;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container.default-menu .menu-item .icon-preview {
  cursor: default;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container.default-menu .menu-item .menu-item-info .menu-item-action {
  display: none;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container.default-menu .menu-item .menu-item-resize {
  visibility: hidden;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container.custom-menu {
  display: none;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container.custom-menu.disabled {
  cursor: default;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container.custom-menu.disabled .menu-item-resize {
  visibility: hidden;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container.custom-menu.disabled .menu-item .menu-item-info .menu-item-delete,
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container.custom-menu.disabled .menu-item .menu-item-info .menu-item-edit,
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container.custom-menu.disabled .new-item,
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container .menu-layout-container.custom-menu.disabled .create-default-container {
  display: none;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container.show-custom .custom-menu {
  display: block;
}
.mgmt-container .content-container.hubs-sections-appearance.hubs-menu-container.show-default .default-menu {
  display: block;
}
body.ace-editor-full-screen {
  overflow: hidden;
}
@media only screen and (max-width: 1300px) {
  .mgmt-container .content-container.hubs-section-appearance .custom-labels-footer.fixed .custom-labels-footer-inner {
    margin-left: 60px;
  }
}
.ie8 .mgmt-container .content-container.hubs-sections-appearance .nav-block-icon.nav-branding {
  width: 300px;
}
.mgmt-container .hubs-section-integrations {
  font-family: Roboto, sans-serif;
  padding: 0 20px 38px;
}
.mgmt-container .hubs-section-integrations .navbar.integrations-navbar {
  margin-bottom: 20px;
}
.mgmt-container .hubs-section-integrations .navbar.integrations-navbar .glyphicons {
  font-size: 15px;
}
.mgmt-container .hubs-section-integrations .navbar.integrations-navbar .glyphicons:before {
  padding: 0.5px 6px 0 11px;
}
.mgmt-container .hubs-section-integrations .navbar.integrations-navbar .navbar-nav > li {
  background-color: #eee;
}
.mgmt-container .hubs-section-integrations .navbar.integrations-navbar .navbar-nav > li > a {
  color: #333;
  text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.25);
}
.mgmt-container .hubs-section-integrations .navbar.integrations-navbar .navbar-nav > li > a:hover {
  background: #ddd;
  color: #333;
}
.mgmt-container .hubs-section-integrations .navbar.integrations-navbar .navbar-nav > li.active > a,
.mgmt-container .hubs-section-integrations .navbar.integrations-navbar .navbar-nav > li.active:not(.open) > a:not(:hover) {
  background-color: #eee;
  color: #ce0058;
}
.mgmt-container .hubs-section-integrations .table:not(#configurable-hub-apps-table) tr td {
  padding: 0;
  width: 25%;
}
.mgmt-container .hubs-section-integrations .table:not(#configurable-hub-apps-table) tr td .padded-cell {
  padding: 8px;
}
.mgmt-container .hubs-section-integrations .table:not(#configurable-hub-apps-table) tr td .padded-cell.action-buttons .hide-when-connected {
  display: block;
}
.mgmt-container .hubs-section-integrations .table:not(#configurable-hub-apps-table) tr td .padded-cell.action-buttons .hide-when-disconnected {
  display: none;
}
.mgmt-container .hubs-section-integrations .table:not(#configurable-hub-apps-table) tr td .padded-cell.action-buttons.service-connected .hide-when-connected {
  display: none;
}
.mgmt-container .hubs-section-integrations .table:not(#configurable-hub-apps-table) tr td .padded-cell.action-buttons.service-connected .hide-when-disconnected {
  display: block;
}
.mgmt-container .hubs-section-integrations .table:not(#configurable-hub-apps-table) tr td:first-child {
  width: 30px;
  max-width: 30px;
}
.mgmt-container .hubs-section-integrations .table:not(#configurable-hub-apps-table) tr td:last-child {
  width: 200px;
  max-width: 200px;
}
.mgmt-container .hubs-section-integrations #app-configurations-tab-content > div {
  margin-bottom: 20px;
}
.mgmt-container .hubs-section-integrations .ufr-input-container {
  padding-top: 20px;
}
.mgmt-container .hubs-section-integrations .ufr-form-section-header div {
  border-bottom-color: #eee;
}
/* integration logos (within modals) */
.integration-logo {
  text-align: center;
}
.integration-logo span {
  margin: 0px auto;
  display: block;
  position: relative;
  height: 90px;
  width: 130px;
  text-indent: -99999px;
  background: transparent;
  background-repeat: no-repeat;
  background-image: url("/img/integrations/spritesheet.png");
  background-position: -99999px -99999px;
}
.integration-logo span.disqus {
  background-position: -610px -340px;
}
.integration-logo span.bombora {
  background-position: -830px -605px;
}
.integration-logo span.google-analytics {
  background-position: -460px -120px;
}
.integration-logo span.pinterest {
  background-position: -10px -10px;
}
.integration-logo span.brightinfo {
  background-position: -1050px -340px;
}
.integration-logo span.optimizely {
  background-position: -1198px -15px;
}
.integration-logo span.google-tag-manager {
  background-position: -460px -120px;
}
.integration-logo span.hubspot {
  background-position: -400px -460px;
  width: 180px;
}
.integration-logo span.salesforce {
  background-position: -210px -598px;
  width: 180px;
  height: 100px;
}
ol.verification-steps li {
  line-height: 28px;
}
.mgmt-container .content-container.hubs-section-options {
  font-family: Roboto, sans-serif;
}
.mgmt-container .content-container.hubs-section-options .options-navbar .glyphicons {
  font-size: 15px;
}
.mgmt-container .content-container.hubs-section-options .options-navbar .glyphicons:before {
  padding: 0.5px 6px 0 11px;
}
.mgmt-container .content-container.hubs-section-options .page-header {
  position: relative;
  width: 780px;
  margin: 0 auto;
}
.mgmt-container .content-container.hubs-section-options .hub-authors-container .author-avatar-small {
  width: 50px;
  border-radius: 50%;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container {
  position: relative;
  width: 780px;
  margin: 0 auto;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container .alert {
  margin-left: 0;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container .section-title {
  color: #555;
  font-weight: bold;
  margin: 0 0 10px -20px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container .hubs-option-block {
  margin-bottom: 20px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container .hubs-option-block label {
  color: #444;
  font-weight: normal;
  line-height: 1.2em;
  margin-bottom: 0;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container .hubs-option-block .select-label {
  margin-left: -20px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container .hubs-option-block select {
  font-size: 14px;
  width: 200px;
  display: inline;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container .hubs-option-block .note {
  margin-left: 20px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container .hubs-option-block .note p {
  color: inherit;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container .hub-option-secondary-block {
  padding-left: 27px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container .enlarged {
  width: 100%;
  margin-bottom: 16px;
  font-size: 14px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.advanced-options .hubs-option-block label {
  padding-left: 18px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.advanced-options .hubs-option-block .hub-option-sub-container {
  margin-top: 5px;
  margin-left: 26px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.advanced-options .hubs-option-block .hub-option-sub-container .hub-option-sub-option {
  padding-top: 8px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.advanced-options .hubs-option-block .hub-option-sub-container .hub-option-sub-option .sub-option-label {
  padding-left: 0;
  padding-bottom: 5px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.advanced-options .hubs-option-block .hub-option-sub-container .hub-option-sub-option .hub-option-sub-option-header .badge {
  margin: 0.5em 0;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.advanced-options .hubs-option-block .hub-option-sub-container input[type='textarea'] {
  height: 100px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.advanced-options .hubs-option-block .hub-option-sub-container .note {
  margin-left: 0;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.authentication-options .auth-status-container {
  margin-top: 40px;
  margin-bottom: 15px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.authentication-options .auth-status-container:before,
.mgmt-container .content-container.hubs-section-options .hubs-options-container.authentication-options .auth-status-container:after {
  display: table;
  content: " ";
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.authentication-options .auth-status-container:after {
  clear: both;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.authentication-options .auth-status-container .auth-status {
  float: left;
  font-size: 20px;
  line-height: 30px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.authentication-options .auth-status-container .auth-status .protected {
  display: none;
  color: #46a546;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.authentication-options .auth-status-container .auth-status .not-protected {
  color: #777777;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.authentication-options .auth-status-container .auth-status.enabled .protected {
  display: inline;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.authentication-options .auth-status-container .auth-status.enabled .not-protected {
  display: none;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.authentication-options .auth-status-container .toggle-switch-parent {
  width: 54px;
  float: right;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.authentication-options .auth-status-container .toggle-switch-parent.disabled {
  cursor: not-allowed;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.authentication-options .auth-status-container .uf-toggle-switch-container {
  float: right;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.authentication-options .auth-status-container .uf-toggle-switch-container.disabled {
  pointer-events: none;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.authentication-options .auth-custom-code-container {
  margin: 30px 0 10px 0;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.authentication-options .auth-custom-code-container .section-title {
  color: #555;
  font-size: 1.1em;
  font-weight: bold;
  margin: 0 0 10px 0;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.authentication-options .auth-custom-code-container .toggle-field {
  margin: 0 0 10px 0;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.authentication-options .auth-custom-code-container .toggle-field label {
  color: #666666;
  font-size: 1em;
  font-weight: normal;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.authentication-options .auth-custom-code-container .toggle-field label input {
  display: inline;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.authentication-options .input-group {
  margin: 0 0 9px 0;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.authentication-options .input-group input {
  width: 463px;
  padding: 8px;
  margin-right: 8px;
  border-radius: 3px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.authentication-options .password-footer {
  margin-bottom: 20px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.authentication-options .password-footer .password-strength-container {
  display: none;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 27px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.authentication-options .password-footer .password-strength-container .password-strength-text {
  float: left;
  width: 100px;
  line-height: 10px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.authentication-options .password-footer .password-strength-container .password-strength-meter {
  float: left;
  width: calc(100% - 100px);
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options {
  width: 780px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default .panel-heading .hubs_share_icon {
  margin: 4px 10px 0 0;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default .panel-heading .hubs_share_icon_label {
  padding: 0px 0 0 12px;
  font-size: 18px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default .panel-body .twitter-via-container .control-label {
  float: left;
  width: 21px;
  margin: 0 5px 0 0;
  padding: 10px 3px 0 1px;
  font-weight: normal;
  color: #444;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default .panel-body .twitter-via-container input {
  width: 285px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default .panel-body textarea {
  width: 100%;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  margin: 0 0 6px 0;
  font-size: 13px;
  border-radius: 0;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default .panel-body .hubs_share_enable {
  margin: 10px 0 0 0;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default .panel-body .hubs_share_enable label {
  margin: 0;
  line-height: 20px;
  color: #333;
  font-size: 13px;
  font-weight: normal;
  cursor: pointer;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default .panel-body .hubs_share_enable input[type="checkbox"] {
  margin-top: 4px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default .panel-body .note {
  margin-top: 12px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default .hubs_share_icon {
  opacity: 0.5;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default .hubs_share_icon.facebook {
  background-position: -312px -10px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default .hubs_share_icon.twitter {
  background-position: center;
  background-image: url("/img/integrations/twitter_x_logo.svg");
  background-size: contain;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default .hubs_share_icon.email {
  background-position: -421px -10px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default .hubs_share_icon.google {
  background-position: -476px -10px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default .hubs_share_icon.linkedin {
  background-position: -531px -10px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default .hubs_share_icon.pinterest {
  background-position: -580px -10px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default .hubs_share_message .label {
  padding: 0;
  margin-bottom: 0;
  color: #666;
  font-weight: normal;
  background-color: transparent;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default.enabled .hubs_share_message:before {
  display: none;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default.enabled .hubs_share_icon {
  opacity: 1;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default.enabled .hubs_share_icon.facebook {
  background-position: -312px -50px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default.enabled .hubs_share_icon.twitter {
  background-position: center;
  background-image: url("/img/integrations/twitter_x_logo.svg");
  background-size: contain;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default.enabled .hubs_share_icon.email {
  background-position: -421px -50px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default.enabled .hubs_share_icon.google {
  background-position: -476px -50px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default.enabled .hubs_share_icon.linkedin {
  background-position: -531px -50px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container.sharing-options .panel-default.enabled .hubs_share_icon.pinterest {
  background-position: -580px -50px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container .delete-hub {
  margin-left: 25px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container .delete-hub input[type=checkbox] {
  margin-right: 10px;
}
.mgmt-container .content-container.hubs-section-options .hubs-options-container .delete-hub .note {
  margin-left: 27px;
}
.mgmt-container .hubs-options-container.change-domain span.btn-wrapper {
  cursor: not-allowed;
}
.mgmt-container .hubs-options-container.change-domain span.btn-wrapper .btn-default.disabled {
  pointer-events: none;
}
.top-box {
  min-height: 300px;
  margin-bottom: 20px;
}
.top-scores {
  background-color: #eeeeee;
  min-height: 300px;
  margin-bottom: 20px;
  border-radius: 15px;
}
.content-tips {
  background-color: #eeeeee;
  min-height: 300px;
  margin-bottom: 20px;
  border-radius: 15px;
}
.top-content {
  background-color: #eeeeee;
  border-radius: 15px;
}
.hubs-preview-overlay,
.hubs-preview-wrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  bottom: 46px;
  background: #fff;
  opacity: 0.9;
}
.hubs-preview-wrapper {
  background: transparent;
  overflow: hidden;
  opacity: 1;
}
.hubs-preview-modal {
  background: transparent url("/img/hubs_preview_deviceShells.png") no-repeat;
  overflow: hidden;
}
.hubs-preview-modal .hubs-preview-address {
  height: 30px;
  line-height: 30px;
  background: #fff;
  border-bottom: 1px solid #cccccc;
  box-shadow: inset 0 -1px 11px #eeeeee;
}
.hubs-preview-modal .hubs-preview-address > span {
  margin: 0 10px;
}
.hubs-preview-modal iframe {
  background: #555;
}
.hubs-preview-modal.desktop {
  position: absolute;
  top: 2%;
  left: 2%;
  right: auto;
  bottom: auto;
  width: 96%;
  height: 96%;
  background: none;
  border: 3px solid #000;
  border-radius: 8px;
}
.hubs-preview-modal.desktop iframe {
  width: 100%;
  height: 100%;
  margin: 0;
}
.hubs-preview-modal.tablet {
  position: absolute;
  top: 20px;
  left: 50%;
  right: auto;
  bottom: auto;
  width: 1115px;
  height: 864px;
  margin-left: -557.5px;
  background-position: -384px 0;
  border: 0 none;
}
.hubs-preview-modal.tablet .hubs-preview-address {
  width: 909px;
  height: 30px;
  margin: 88px 103px 0 103px;
}
.hubs-preview-modal.tablet iframe {
  width: 909px;
  height: 653px;
  margin: 0 103px 0 103px;
}
.hubs-preview-modal.phone {
  position: absolute;
  top: 20px;
  left: 50%;
  right: auto;
  bottom: auto;
  width: 383px;
  height: 803px;
  margin-left: -181.5px;
  background-position: 0px 0;
  border: 0 none;
}
.hubs-preview-modal.phone .hubs-preview-address {
  width: 321px;
  height: 30px;
  margin: 118px 0 0 31px;
}
.hubs-preview-modal.phone iframe {
  width: 321px;
  height: 543px;
  margin: 0 0 0 31px;
}
.hubs-preview-command-bar {
  display: none;
  position: fixed;
  top: auto;
  left: 0;
  right: auto;
  bottom: 0;
  width: 100%;
  height: 46px;
  background-color: transparent;
  pointer-events: none;
}
.hubs-preview-command-bar .hubs-preview-command-bar-inner {
  position: relative;
  width: auto;
  height: 46px;
  margin-left: 250px;
  padding-right: 250px;
  background-color: #f9f9f9;
  text-align: center;
  pointer-events: auto;
}
.hubs-preview-command-bar .hubs-preview-command-bar-inner .btn {
  margin: 9px 0 0 0;
}
@media only screen and (max-width: 1300px) {
  .hubs-preview-modal.tablet {
    left: 20px;
    margin-left: 0;
  }
  .hubs-preview-command-bar .hubs-preview-command-bar-inner {
    margin-left: 60px;
    padding-right: 60px;
  }
}
.mgmt-container .content-container.hubs-section-item-editor {
  margin-bottom: 60px;
}
.mgmt-container .content-container.hubs-section-item-editor .title-edit {
  position: relative;
  width: 100%;
  height: 40px;
  padding: 5px 1%;
  margin-top: 10px;
  font-size: 26px;
}
.mgmt-container .content-container.hubs-section-item-editor .item-edit-url {
  max-width: 74%;
}
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:link,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:visited,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:active,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:hover,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:focus {
  margin-left: 23px;
  color: #555;
  position: relative;
}
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:link .icon-embed,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:visited .icon-embed,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:active .icon-embed,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:hover .icon-embed,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:focus .icon-embed,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:link .icon-share-prong,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:visited .icon-share-prong,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:active .icon-share-prong,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:hover .icon-share-prong,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:focus .icon-share-prong {
  margin-top: 2px;
}
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:link .halflings,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:visited .halflings,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:active .halflings,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:hover .halflings,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:focus .halflings {
  top: 2px;
  opacity: 0.6;
}
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:link .glyphicons,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:visited .glyphicons,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:active .glyphicons,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:hover .glyphicons,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:focus .glyphicons {
  top: 50%;
  position: absolute;
  right: calc(100% + 2px);
  transform: translateY(-50%);
}
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:link .glyphicons.glyphicons-embed,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:visited .glyphicons.glyphicons-embed,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:active .glyphicons.glyphicons-embed,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:hover .glyphicons.glyphicons-embed,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:focus .glyphicons.glyphicons-embed {
  right: calc(100% + 5px);
}
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:link .glyphicons:before,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:visited .glyphicons:before,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:active .glyphicons:before,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:hover .glyphicons:before,
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:focus .glyphicons:before {
  padding: 0;
}
.mgmt-container .content-container.hubs-section-item-editor .item-edit-links a:hover {
  color: #222222;
}
.mgmt-container .content-container.hubs-section-item-editor .social-user {
  padding: 10px;
  margin-top: 10px;
}
.mgmt-container .content-container.hubs-section-item-editor .social-user > img.avatar {
  float: left;
  width: auto;
  height: 48px;
  margin-right: 5px;
  border-radius: 4px;
}
.mgmt-container .content-container.hubs-section-item-editor .social-user > div.user-name {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: normal;
  line-height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mgmt-container .content-container.hubs-section-item-editor .tab-content .tab-pane .panel-container {
  margin-top: 0;
}
.mgmt-container .content-container.hubs-section-item-editor .tab-content .tab-pane .panel-container .panel-body {
  min-height: 350px;
}
.mgmt-container .content-container.hubs-section-item-editor .tab-content .tab-pane .panel-container .panel-body .halflings {
  color: #555;
  font-size: 12px;
}
.mgmt-container .content-container.hubs-section-item-editor .tab-content .tab-pane .panel-container .panel-body > .panel-body-group {
  clear: none;
  min-width: 400px;
}
.mgmt-container .content-container.hubs-section-item-editor .tab-content .tab-pane .panel-container .panel-body > .panel-body-group > label:not(.btn) {
  width: 30%;
}
.mgmt-container .content-container.hubs-section-item-editor .tab-content .tab-pane .panel-container .panel-body > .panel-body-group > label:not(.btn) span {
  padding-left: 8px;
  font-weight: normal;
  color: #999;
  letter-spacing: 0;
}
.mgmt-container .content-container.hubs-section-item-editor .tab-content .tab-pane .panel-container .panel-body > .panel-body-group .btn[data-action='image-manager'] {
  margin-top: -6px;
}
.mgmt-container .content-container.hubs-section-item-editor .tab-content .tab-pane .panel-container .panel-body > .panel-body-group .panel-input-wrapper {
  display: inline-block;
  width: 100%;
}
.mgmt-container .content-container.hubs-section-item-editor .tab-content .tab-pane .panel-container .panel-body > .panel-body-group .panel-input-wrapper.short {
  max-width: 350px;
}
.mgmt-container .content-container.hubs-section-item-editor .tab-content .tab-pane .panel-container .panel-body > .panel-body-group .panel-input-wrapper > input[type=text] {
  width: 100%;
}
.mgmt-container .content-container.hubs-section-item-editor .tab-content .tab-pane .panel-container .panel-body > .panel-body-group.with-btn .panel-input-wrapper {
  width: 88%;
}
.mgmt-container .content-container.hubs-section-item-editor .tab-content .tab-pane .tile-img-actions {
  margin-top: 10px;
}
.mgmt-container .content-container.hubs-section-item-editor .tab-content .tab-pane .tile-img-upload-container {
  display: inline-block;
  margin: 2px 0 0;
  vertical-align: top;
}
.mgmt-container .content-container.hubs-section-item-editor .tab-content .tab-pane .tile-img-upload-container .tile-img-input {
  visibility: hidden;
  width: 1px;
  height: 0;
}
.mgmt-container .content-container.hubs-section-item-editor .tab-content .tab-pane .tile-img-upload-container .tile-img-upload span.halflings {
  color: #fff;
}
.mgmt-container .content-container.hubs-section-item-editor .tab-content .tab-pane .tile-img-actions {
  display: none;
}
.mgmt-container .content-container.hubs-section-item-editor .tab-content .item-tags-wrapper .tagsinput {
  border-radius: 3px;
}
.mgmt-container .content-container.hubs-section-item-editor #single-item-performance-tab iframe {
  height: 800px;
  width: 100%;
  border: none;
  margin-top: -40px;
}
.mgmt-container .content-container.hubs-section-item-editor .edit-menu .dropdown-toggle {
  position: relative;
  display: block;
  width: 32px;
  height: 26px;
  padding: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.mgmt-container .content-container.hubs-section-item-editor .edit-menu .dropdown-toggle .glyphicons {
  font-size: 18px;
}
.mgmt-container .content-container.hubs-section-item-editor .edit-menu.open .glyphicons {
  color: #2f96b4;
}
.mgmt-container .content-container.hubs-section-item-editor .edit-menu .dropdown-menu {
  background-color: #fff;
  border: 1px solid #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}
.mgmt-container .content-container.hubs-section-item-editor .edit-menu .dropdown-menu li a:link,
.mgmt-container .content-container.hubs-section-item-editor .edit-menu .dropdown-menu li a:visited,
.mgmt-container .content-container.hubs-section-item-editor .edit-menu .dropdown-menu li a:active,
.mgmt-container .content-container.hubs-section-item-editor .edit-menu .dropdown-menu li a:hover {
  opacity: 0.8;
}
.mgmt-container .content-container.hubs-section-item-editor .edit-menu .dropdown-menu li a:link.disabled,
.mgmt-container .content-container.hubs-section-item-editor .edit-menu .dropdown-menu li a:visited.disabled,
.mgmt-container .content-container.hubs-section-item-editor .edit-menu .dropdown-menu li a:active.disabled,
.mgmt-container .content-container.hubs-section-item-editor .edit-menu .dropdown-menu li a:hover.disabled {
  opacity: 0.3;
}
.mgmt-container .content-container.hubs-section-item-editor .edit-menu .dropdown-menu li a:hover {
  color: #fff;
  background-color: #5bc0de;
  opacity: 1;
}
.mgmt-container .content-container.hubs-section-item-editor .edit-menu .dropdown-menu li a:hover .icon-gray {
  display: inline-block;
}
.mgmt-container .content-container.hubs-section-item-editor input.datefield {
  width: 160px;
}
.mgmt-container .content-container.hubs-section-item-editor textarea {
  width: 100%;
  height: 60px;
}
.mgmt-container .content-container.hubs-section-item-editor label.btn {
  margin: 0;
}
.mgmt-container .content-container.hubs-section-item-editor .item-group {
  display: block;
  float: none;
  margin: 15px auto 0 auto;
}
.mgmt-container .content-container.hubs-section-item-editor .panel-body.checkered {
  overflow: hidden;
}
.mgmt-container .content-container.hubs-section-item-editor .panel-body.checkered > div.tile.single {
  margin: 10px auto !important;
  float: none !important;
  pointer-events: none;
  cursor: default;
  z-index: 0;
}
.mgmt-container .content-container.hubs-section-item-editor .panel-body.checkered > div.tile.single.cropping {
  pointer-events: initial;
  display: none;
}
.mgmt-container .content-container.hubs-section-item-editor .custom-script-wrapper {
  margin-bottom: 0;
}
.mgmt-container .content-container.hubs-section-item-editor .custom-script-wrapper .nav-block-icon {
  margin-bottom: 0;
}
.mgmt-container .content-container.hubs-section-item-editor .toggle-custom-script-wrapper {
  margin-bottom: 20px;
}
.mgmt-container .content-container.hubs-section-item-editor .toggle-custom-script-wrapper label {
  display: inline-block;
}
.mgmt-container .content-container.hubs-section-item-editor .item-editor-footer {
  position: relative;
  width: 100%;
  height: 46px;
  padding: 9px 0;
  margin-bottom: 0;
  background-color: #666666;
  text-align: center;
}
.mgmt-container .content-container.hubs-section-item-editor .item-editor-footer .item-editor-footer-inner .btn {
  margin-right: 20px;
}
.mgmt-container .content-container.hubs-section-item-editor .item-editor-footer .item-editor-footer-inner .draft-status {
  position: absolute;
  top: 15px;
  left: auto;
  right: 20px;
  bottom: auto;
  color: #eeeeee;
  font-size: 14px;
}
.mgmt-container .content-container.hubs-section-item-editor .item-editor-footer .item-editor-footer-inner .draft-status img,
.mgmt-container .content-container.hubs-section-item-editor .item-editor-footer .item-editor-footer-inner .draft-status .icon-white,
.mgmt-container .content-container.hubs-section-item-editor .item-editor-footer .item-editor-footer-inner .draft-status .halflings {
  margin: 0 7px 0 0;
}
.mgmt-container .content-container.hubs-section-item-editor .item-editor-footer .item-editor-footer-inner .draft-status .status-saved,
.mgmt-container .content-container.hubs-section-item-editor .item-editor-footer .item-editor-footer-inner .draft-status .status-saving,
.mgmt-container .content-container.hubs-section-item-editor .item-editor-footer .item-editor-footer-inner .draft-status .status-error {
  display: none;
}
.mgmt-container .content-container.hubs-section-item-editor .item-editor-footer .item-editor-footer-inner .draft-status.saving .status-saving {
  display: block;
}
.mgmt-container .content-container.hubs-section-item-editor .item-editor-footer .item-editor-footer-inner .draft-status.saved .status-saved {
  display: block;
}
.mgmt-container .content-container.hubs-section-item-editor .item-editor-footer .item-editor-footer-inner .draft-status.error .status-error {
  display: block;
}
.mgmt-container .content-container.hubs-section-item-editor .item-editor-footer.fixed {
  position: fixed;
  top: auto;
  left: 0;
  right: auto;
  bottom: 0;
  z-index: 8;
}
.mgmt-container .content-container.hubs-section-item-editor .item-editor-footer.fixed .item-editor-footer-inner {
  margin-left: 250px;
}
.mgmt-container .content-container.hubs-section-item-editor .item-editor-footer.fixed .item-editor-footer-inner #published-at-opt {
  display: inline-block;
  width: 300px;
}
.mgmt-container .content-container.hubs-section-item-editor .item-editor-footer.fixed .item-editor-footer-inner #published-at-opt #update-item-publish-date {
  float: left;
  display: block;
  margin-right: 5px;
}
.mgmt-container .content-container.hubs-section-item-editor .item-editor-footer.fixed .item-editor-footer-inner #published-at-opt label {
  margin: 4px 0 0 0;
  color: white;
  font-weight: normal;
  font-size: 12px;
}
.mgmt-container .content-container.hubs-section-item-editor .media-alert {
  background: #cccccc;
  color: #fff;
}
.mgmt-container .content-container.hubs-section-item-editor .media-alert > div {
  width: 80%;
  padding: 6px;
  margin: 10px auto;
  background: #999999;
  text-align: center;
  border-radius: 20px;
}
.author-avatar-tiny {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
  background-size: cover;
  vertical-align: middle;
}
.select2-chosen > div.author-avatar-tiny {
  width: 21px;
  height: 21px;
  margin-top: -3px;
}
.atomic-reach .panel-container > .panel-body {
  max-height: 744px;
  min-height: 744px;
  overflow: auto;
}
/* .hub-item-url {
    width: auto;
    padding-bottom: 30px;

    > span {
        display: inline-block;
        position: relative;
        top: 5px;
        max-width: 72%;
        margin: 0 10px;
 }
 } */
.btn-custom-code.disabled {
  cursor: not-allowed;
}
.btn-custom-code.disabled #add-custom-code.disabled {
  pointer-events: none;
}
.disabled,
table.table.api-dt tbody > tr td.api-data-table-counts.inline-edit-text.disabled span.priority-text,
.disabled-btn-wrapper {
  cursor: not-allowed;
}
.uf-toggle-switch-container.disabled {
  cursor: not-allowed;
}
.uf-toggle-switch-container.disabled .uf-toggle-switch {
  pointer-events: none;
}
.mgmt-container .content-container.hubs-section-schedule table.table.standard-data-table tbody td {
  line-height: 18px;
}
.mgmt-container .content-container.hubs-section-schedule table.table.standard-data-table tbody td .stream-title {
  font-weight: bold;
  text-transform: uppercase;
}
.mgmt-container .content-container.hubs-section-schedule table.table.standard-data-table tbody td:last-child {
  text-align: right;
}
.mgmt-container .content-container.hubs-section-schedule .manage-schedule-btn {
  font-size: 13px;
}
.manage-schedule-modal {
  width: 700px;
  height: 100%;
}
.manage-schedule-modal .header {
  width: 700px;
}
.manage-schedule-modal .header h1 {
  margin: 20px 0 5px 0;
  font-size: 26px;
  text-align: center;
  font-weight: 400;
}
.manage-schedule-modal .body {
  width: 700px;
  height: 100%;
  overflow: hidden;
}
.manage-schedule-modal .body .schedule-tab {
  width: 700px;
  height: 100%;
}
.manage-schedule-modal .body .schedule-tab .divider {
  position: relative;
  height: 32px;
  background: #aaa;
}
.manage-schedule-modal .body .schedule-tab .divider h2 {
  width: 560px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 32px;
  margin: 0 auto 12px auto;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.manage-schedule-modal .body .schedule-tab .divider h2 em {
  font-style: normal;
}
.manage-schedule-modal .body .schedule-tab .schedule-split-panel {
  width: 600px;
  margin: 30px auto;
}
.manage-schedule-modal .body .schedule-tab .schedule-split-panel:before,
.manage-schedule-modal .body .schedule-tab .schedule-split-panel:after {
  display: table;
  content: " ";
}
.manage-schedule-modal .body .schedule-tab .schedule-split-panel:after {
  clear: both;
}
.manage-schedule-modal .body .schedule-tab .schedule-split-panel .schedule-panel-left {
  float: left;
  width: 250px;
  margin-right: 45px;
}
.manage-schedule-modal .body .schedule-tab .schedule-split-panel .schedule-panel-right {
  float: left;
  width: 300px;
}
.manage-schedule-modal .body .schedule-tab .schedule-split-panel.no-margin .schedule-panel-left {
  margin-right: 0;
}
.manage-schedule-modal .body .schedule-tab .schedule-split-panel.no-margin .schedule-panel-right {
  width: 345px;
}
.manage-schedule-modal .body .schedule-tab .target-view .tile.single {
  margin: 5px;
}
.manage-schedule-modal .body .schedule-tab .target-view .tile.single .img {
  opacity: 1;
}
.manage-schedule-modal .body .schedule-tab .target-view .tile.single.uberflip .description {
  height: 125px !important;
}
.manage-schedule-modal .body .schedule-tab .target-view .tile.single:hover > a.view {
  text-decoration: none !important;
  background-color: #fcfcfc !important;
}
.manage-schedule-modal .body .schedule-tab .target-view .tile.single:hover > a.view:after {
  background-position: 0px 0 !important;
}
.manage-schedule-modal .body .schedule-tab .target-view .tile.single:hover .description h3 {
  color: #555 !important;
}
.manage-schedule-modal .body .schedule-tab .target-view.bordered .tile,
.manage-schedule-modal .body .schedule-tab .target-view.bordered .collection-tile {
  margin: 0;
  border: 1px solid #ddd;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.manage-schedule-modal .body .schedule-tab .tile.single.cta .cta-field-sprite-container {
  top: 29px;
}
.manage-schedule-modal .body .schedule-tab .stream-search {
  margin-left: 350px;
}
.manage-schedule-modal .body .schedule-tab .stream-search .input-group input {
  padding-right: 25px;
}
.manage-schedule-modal .body .schedule-tab .stream-search .input-group .input-group-addon a {
  color: #333333;
}
.manage-schedule-modal .body .schedule-tab .stream-search .stream-search-clear {
  display: none;
  overflow: hidden;
}
.manage-schedule-modal .body .schedule-tab .stream-search .stream-search-clear.active {
  display: block;
  position: absolute;
  top: 8px;
  left: auto;
  right: 44px;
  bottom: auto;
  width: 15px;
  height: 15px;
  line-height: 15px;
  font-size: 16px;
  padding: 0;
  text-align: center;
  cursor: pointer;
  z-index: 3;
  color: #bbb;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.manage-schedule-modal .body .schedule-tab .stream-search .stream-search-clear:hover {
  color: white;
  background: #333;
  border: 1px solid white;
  -webkit-box-shadow: 0 0 1px 1px #aaa;
  box-shadow: 0 0 1px 1px #aaa;
}
.manage-schedule-modal .body .schedule-tab .back-arrow {
  position: absolute;
  top: 4px;
  left: 30px;
  right: auto;
  bottom: auto;
  width: 24px;
  height: 24px;
  color: #fff;
  background: transparent;
  font-size: 36px;
  line-height: 20px;
  text-align: center;
  border-radius: 12px;
  -webkit-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
  cursor: pointer;
}
.manage-schedule-modal .body .schedule-tab .back-arrow:hover {
  color: #aaa;
  background: #fff;
}
.manage-schedule-modal .body .schedule-tab .empty-group {
  margin: 30px 0;
  padding: 25px;
  text-align: center;
  background: #f9f9f9;
}
.manage-schedule-modal .body .schedule-tab .empty-group .empty-group-text {
  width: 80%;
  margin: 15px auto;
  font-size: 1.25em;
  text-align: center;
}
.manage-schedule-modal .body .schedule-tab.schedule-type .schedule-type-buttons {
  width: 250px;
  margin: 70px auto 0 auto;
}
.manage-schedule-modal .body .schedule-tab.schedule-type .schedule-type-buttons p {
  margin-bottom: 15px;
}
.manage-schedule-modal .body .schedule-tab.schedule-type .schedule-type-buttons .btn-schedule-type-selection {
  width: 220px;
  padding: 14px;
  font-size: 14px;
  text-transform: uppercase;
  text-shadow: none;
  background-color: #dddddd;
}
.manage-schedule-modal .body .schedule-tab.schedule-type .schedule-type-buttons .btn-schedule-type-selection:hover {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
  border-bottom-color: #31b0d5;
}
.manage-schedule-modal .body .schedule-tab.schedule-target .schedule-target-selection {
  width: 600px;
  margin: 0 auto;
}
.manage-schedule-modal .body .schedule-tab.schedule-target .schedule-target-selection .stream-search {
  padding: 12px 0;
}
.manage-schedule-modal .body .schedule-tab.schedule-target .schedule-target-selection .stream-search:before,
.manage-schedule-modal .body .schedule-tab.schedule-target .schedule-target-selection .stream-search:after {
  display: table;
  content: " ";
}
.manage-schedule-modal .body .schedule-tab.schedule-target .schedule-target-selection .stream-search:after {
  clear: both;
}
.manage-schedule-modal .body .schedule-tab.schedule-target .schedule-target-selection .cta-container {
  padding-top: 30px;
}
.manage-schedule-modal .body .schedule-tab.schedule-target .schedule-target-selection .cta-container #addCtaCarousel {
  position: relative;
  line-height: normal;
  margin: 6px 35px;
}
.manage-schedule-modal .body .schedule-tab.schedule-target .schedule-target-selection .cta-container .tile.single.cta {
  display: block;
  float: none !important;
  margin: 0 auto !important;
  -webkit-transform: scale(0.8, 0.8);
  -moz-transform: scale(0.8, 0.8);
  -o-transform: scale(0.8, 0.8);
  -ms-transform: scale(0.8, 0.8);
  transform: scale(0.8, 0.8);
}
.manage-schedule-modal .body .schedule-tab.schedule-target .schedule-target-selection .cta-container .cta-selectbox {
  text-align: center;
}
.manage-schedule-modal .body .schedule-tab.schedule-target .schedule-target-selection .cta-container .select-cta-container {
  text-align: center;
  padding: 0;
}
.manage-schedule-modal .body .schedule-tab.schedule-target .schedule-target-selection .cta-container .select-cta-container a {
  width: 172px;
  padding: 14px;
  font-weight: bold;
}
.manage-schedule-modal .body .schedule-tab.schedule-target .schedule-target-selection .cta-container a:link.carousel-control {
  color: #555;
  background: transparent;
  border: 0 none;
}
.manage-schedule-modal .body .schedule-tab.schedule-target .schedule-target-selection .stream-container {
  max-height: 370px;
  padding: 2px;
  border: 1px solid #ddd;
  overflow: auto;
}
.manage-schedule-modal .body .schedule-tab.schedule-target .schedule-target-selection .stream-container ul.stream-listing {
  padding: 0;
}
.manage-schedule-modal .body .schedule-tab.schedule-target .schedule-target-selection .stream-container ul.stream-listing li {
  font-weight: bold;
  margin: 0;
  cursor: pointer;
}
.manage-schedule-modal .body .schedule-tab.schedule-target .schedule-target-selection .stream-container ul.stream-listing li.no-streams {
  display: none;
  padding: 20px 6px;
  font-style: italic;
}
.manage-schedule-modal .body .schedule-tab.schedule-target .schedule-target-selection .stream-container ul.stream-listing li a {
  display: block;
  color: #555;
  padding: 6px;
  font-size: 12px;
}
.manage-schedule-modal .body .schedule-tab.schedule-target .schedule-target-selection .stream-container ul.stream-listing li a em {
  display: inline-block;
  width: 75%;
  vertical-align: middle;
  font-style: normal;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.manage-schedule-modal .body .schedule-tab.schedule-target .schedule-target-selection .stream-container ul.stream-listing li a em span {
  padding-left: 5px;
  font-weight: 300;
  text-decoration: none;
  opacity: 0.75;
  font-size: 10px;
}
.manage-schedule-modal .body .schedule-tab.schedule-target .schedule-target-selection .stream-container ul.stream-listing li a span {
  margin-right: 10px;
}
.manage-schedule-modal .body .schedule-tab.schedule-target .schedule-target-selection .stream-container ul.stream-listing li a span.arrow {
  display: inline-block;
  float: right;
  width: 34px;
  height: 34px;
  margin-top: 7px;
  background: transparent url("/img/hubs/backend/miscicons.png") no-repeat -87px -16px;
}
.manage-schedule-modal .body .schedule-tab.schedule-target .schedule-target-selection .stream-container ul.stream-listing li a:hover {
  color: #fff;
  background-color: #5bc0de;
}
.manage-schedule-modal .body .schedule-tab.schedule-target-item .select-item-container {
  width: 600px;
  padding: 20px 0;
  margin: 0 auto;
}
.manage-schedule-modal .body .schedule-tab.schedule-target-item .select-item-container #item-selector {
  max-width: 600px;
}
.manage-schedule-modal .body .schedule-tab.schedule-target-item .select-item-container #item-selector .item-selector-header {
  margin-bottom: 15px;
}
.manage-schedule-modal .body .schedule-tab.schedule-target-item .select-item-container #item-selector .item-selector-header:before,
.manage-schedule-modal .body .schedule-tab.schedule-target-item .select-item-container #item-selector .item-selector-header:after {
  display: table;
  content: " ";
}
.manage-schedule-modal .body .schedule-tab.schedule-target-item .select-item-container #item-selector .item-selector-header:after {
  clear: both;
}
.manage-schedule-modal .body .schedule-tab.schedule-target-item .select-item-container #item-selector .item-selector-header .input-group {
  float: right;
  width: 300px;
}
.manage-schedule-modal .body .schedule-tab.schedule-target-item .select-item-container #item-selector .item-selector-header .input-group .input-group-addon a {
  color: #333333;
}
.manage-schedule-modal .body .schedule-tab.schedule-target-item .select-item-container #item-selector .item-selector-header .input-group .search-clear {
  display: none;
  overflow: hidden;
}
.manage-schedule-modal .body .schedule-tab.schedule-target-item .select-item-container #item-selector .item-selector-header .input-group .search-clear.active {
  display: block;
  position: absolute;
  top: 8px;
  left: auto;
  right: 44px;
  bottom: auto;
  width: 15px;
  height: 15px;
  line-height: 15px;
  font-size: 16px;
  padding: 0;
  text-align: center;
  cursor: pointer;
  z-index: 3;
  color: #bbb;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.manage-schedule-modal .body .schedule-tab.schedule-target-item .select-item-container #item-selector .item-selector-header .input-group .search-clear:hover {
  color: white;
  background: #333;
  border: 1px solid white;
  -webkit-box-shadow: 0 0 1px 1px #aaa;
  box-shadow: 0 0 1px 1px #aaa;
}
.manage-schedule-modal .body .schedule-tab.schedule-target-item .select-item-container .item-selector-item-container {
  max-height: 380px !important;
  border: 1px solid #ddd;
}
.manage-schedule-modal .body .schedule-tab.schedule-target-item .select-item-container .item-selector-item-container .item-group {
  margin-left: 19px;
}
.manage-schedule-modal .body .schedule-tab.schedule-select .schedule-selection {
  border: 1px solid #ddd;
}
.manage-schedule-modal .body .schedule-tab.schedule-select .schedule-selection .schedule-listing-container {
  height: 330px;
  max-height: 330px;
  background: #eee;
  overflow: auto;
}
.manage-schedule-modal .body .schedule-tab.schedule-select .schedule-selection .schedule-listing-container .schedule-listing {
  background: #fff;
}
.manage-schedule-modal .body .schedule-tab.schedule-select .schedule-selection .schedule-listing-container .schedule-listing li {
  display: block;
  color: #555;
  padding: 10px 20px;
  margin: 0;
  font-size: 12px;
  line-height: 34px;
  -webkit-transition: background-color 250ms linear;
  -o-transition: background-color 250ms linear;
  transition: background-color 250ms linear;
  border-bottom: 1px solid #ddd;
}
.manage-schedule-modal .body .schedule-tab.schedule-select .schedule-selection .schedule-listing-container .schedule-listing li span.action {
  display: block;
  float: left;
  width: 90px;
  font-weight: bold;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.manage-schedule-modal .body .schedule-tab.schedule-select .schedule-selection .schedule-listing-container .schedule-listing li span.when {
  display: inline-block;
  width: 128px;
}
.manage-schedule-modal .body .schedule-tab.schedule-select .schedule-selection .schedule-listing-container .schedule-listing li .edit-btn,
.manage-schedule-modal .body .schedule-tab.schedule-select .schedule-selection .schedule-listing-container .schedule-listing li .remove-btn {
  display: block;
  float: right;
  width: 26px;
  height: 26px;
  margin: 3px 0 0 6px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 14px;
  text-align: center;
  vertical-align: middle;
  -webkit-transition: border 250ms linear;
  -o-transition: border 250ms linear;
  transition: border 250ms linear;
}
.manage-schedule-modal .body .schedule-tab.schedule-select .schedule-selection .schedule-listing-container .schedule-listing li .edit-btn .halflings,
.manage-schedule-modal .body .schedule-tab.schedule-select .schedule-selection .schedule-listing-container .schedule-listing li .remove-btn .halflings {
  visibility: hidden;
  top: -3px;
}
.manage-schedule-modal .body .schedule-tab.schedule-select .schedule-selection .schedule-listing-container .schedule-listing li .add-new {
  display: block;
  color: #555;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}
.manage-schedule-modal .body .schedule-tab.schedule-select .schedule-selection .schedule-listing-container .schedule-listing li:hover {
  color: #fff;
  background: #5bc0de;
}
.manage-schedule-modal .body .schedule-tab.schedule-select .schedule-selection .schedule-listing-container .schedule-listing li:hover .edit-btn,
.manage-schedule-modal .body .schedule-tab.schedule-select .schedule-selection .schedule-listing-container .schedule-listing li:hover .remove-btn {
  border: 1px solid #5bc0de;
}
.manage-schedule-modal .body .schedule-tab.schedule-select .schedule-selection .schedule-listing-container .schedule-listing li:hover .edit-btn .halflings,
.manage-schedule-modal .body .schedule-tab.schedule-select .schedule-selection .schedule-listing-container .schedule-listing li:hover .remove-btn .halflings {
  visibility: visible;
}
.manage-schedule-modal .body .schedule-tab.schedule-select .schedule-selection .schedule-listing-container .schedule-listing li:hover .edit-btn:hover,
.manage-schedule-modal .body .schedule-tab.schedule-select .schedule-selection .schedule-listing-container .schedule-listing li:hover .remove-btn:hover {
  border: 1px solid #fff;
}
.manage-schedule-modal .body .schedule-tab.schedule-select .schedule-selection .schedule-listing-container .schedule-listing li:hover .add-new {
  color: #fff;
}
.manage-schedule-modal .body .schedule-tab.schedule-select .schedule-selection .schedule-listing-container .schedule-listing li.flashy {
  color: #fff;
  background-color: #5bc0de;
}
.manage-schedule-modal .body .schedule-tab.schedule-select .schedule-selection .schedule-listing-container .schedule-listing li.flashy .edit-btn,
.manage-schedule-modal .body .schedule-tab.schedule-select .schedule-selection .schedule-listing-container .schedule-listing li.flashy .remove-btn {
  border: 1px solid #5bc0de;
}
.manage-schedule-modal .body .schedule-tab.schedule-action .schedule-configuration label {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}
.manage-schedule-modal .body .schedule-tab.schedule-action .schedule-configuration select {
  width: 300px;
  margin-bottom: 20px;
}
.manage-schedule-modal .body .schedule-tab.schedule-action .schedule-configuration select option {
  font-weight: bold;
}
.manage-schedule-modal .body .schedule-tab.schedule-action .schedule-configuration .datefield {
  background-color: #fff;
}
.manage-schedule-modal .body .schedule-tab.schedule-action .schedule-configuration .button-container {
  margin: 30px 0;
  text-align: right;
}
.manage-schedule-modal .body .schedule-tab.schedule-action .cta .hidden-cta-fields .cta-field-section .cta-field-sprite-container {
  top: 29px;
}
.manage-schedule-modal .body .schedule-tab.schedule-action .manage-schedule-link {
  padding: 10px 60px;
}
.manage-schedule-modal .body .schedule-tab .tags-wrapper {
  margin-bottom: 20px;
}
.iframe #flash_message {
  position: absolute;
  bottom: -70px;
  width: 90%;
  padding: 10px 14px;
  margin: 0 20px;
}
.iframe #flash_message .close {
  display: none;
}
i[class^="bh-icon-"] {
  display: inline-block;
  width: 14px;
  height: 14px;
  line-height: 14px;
  vertical-align: text-top;
  background-image: url("/img/hubs/backend/spritesheet.png");
}
i.bh-icon-beside-cta {
  background-position: -533px -533px;
}
i.bh-icon-lock-cta {
  width: 13px;
  background-position: -534px -574px;
}
i.bh-icon-lock-cta.icon-white {
  background-position: -493px -492px;
}
i.bh-icon-cta {
  background-position: -174px -573px;
}
i.bh-icon-form-cta {
  background-position: -452px -573px;
}
i.bh-icon-link-cta {
  background-position: -495px -573px;
}
i.bh-icon-stream-cta {
  width: 20px;
  background-position: -410px -573px;
}
.tag-modal-wrapper .form-wrapper {
  padding: 10px;
  width: 450px;
  margin: 0 auto;
  max-width: 100%;
}
.tag-modal-wrapper .form-wrapper input[type=text] {
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 12px;
}
.tag-modal-wrapper .form-wrapper textarea {
  height: 170px;
}
.tag-modal-wrapper .form-wrapper .mini-counter {
  float: right;
}
.tag-modal-wrapper .tag-submit-button {
  margin-right: 10px;
}
.tag-modal-wrapper .counter-description-wrapper {
  overflow: hidden;
  padding-top: 3px;
}
.tag-modal-wrapper .counter-name-wrapper {
  position: relative;
  top: -6px;
}
.tags-table {
  font-family: Roboto, sans-serif;
  display: table;
  min-width: 1000px;
  margin: 0 auto 30px;
}
.tags-table thead > tr > th {
  color: #8c8f91;
}
.tags-table thead > tr > th.sorting_asc,
.tags-table thead > tr > th.sorting_desc {
  -webkit-filter: grayscale(100%);
  color: #48484a;
}
.tags-table thead > tr > th.sorting {
  color: #8c8f91;
}
.tags-table thead > tr > th.no-sort {
  background: transparent !important;
  cursor: default;
}
.tags-table .tag-title {
  font-size: 13px;
  font-weight: 700;
  white-space: nowrap;
  max-width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tags-table a.tag-name,
.tags-table a.tags-count {
  color: #333;
  font-weight: bold;
}
.tags-table a.tag-name:hover,
.tags-table a.tags-count:hover {
  text-decoration: underline;
}
.tags-table .tags-buttons {
  text-align: right;
}
.tags-table .tags-buttons > a .halflings {
  color: #8c8f91;
}
.mgmt-container #tag-container .text-center.add-new {
  margin-top: 40px;
}
.mgmt-container #tag-container .text-center.add-new a {
  font-size: 13px;
}
.hubs-section-tags .tags-table .table.collection-table td {
  padding: 8px 8px 8px 0;
}
.hubs-section-tags .tags-table .table.collection-table td.tags-counter a,
.hubs-section-tags .tags-table .table.collection-table td.tags-counter span {
  margin-left: 2px;
}
.edit-tag-modal-wrapper .item-tile-wrapper .item-feature-link-new {
  display: none;
}
.edit-tag-modal-wrapper .item-tile-wrapper .item-placeholder .img {
  opacity: 1;
}
.recommendation-section-edit-rule .edit-tag-form,
.edit-tag-modal-wrapper .edit-tag-form,
.advanced-search-wrapper .edit-tag-form,
.item-tags-wrapper .edit-tag-form,
.tags-wrapper .edit-tag-form,
.add-user-modal .edit-tag-form {
  padding-top: 5px;
  padding-right: 20px;
}
.recommendation-section-edit-rule .edit-tag-form textarea,
.edit-tag-modal-wrapper .edit-tag-form textarea,
.advanced-search-wrapper .edit-tag-form textarea,
.item-tags-wrapper .edit-tag-form textarea,
.tags-wrapper .edit-tag-form textarea,
.add-user-modal .edit-tag-form textarea {
  height: 210px;
}
.recommendation-section-edit-rule div.tagsinput,
.edit-tag-modal-wrapper div.tagsinput,
.advanced-search-wrapper div.tagsinput,
.item-tags-wrapper div.tagsinput,
.tags-wrapper div.tagsinput,
.add-user-modal div.tagsinput {
  height: auto !important;
  overflow: visible;
  border-radius: 3px;
  padding: 1px 5px;
}
.recommendation-section-edit-rule div.tagsinput:after,
.edit-tag-modal-wrapper div.tagsinput:after,
.advanced-search-wrapper div.tagsinput:after,
.item-tags-wrapper div.tagsinput:after,
.tags-wrapper div.tagsinput:after,
.add-user-modal div.tagsinput:after {
  content: "";
  display: table;
  clear: both;
}
.recommendation-section-edit-rule div.tagsinput input,
.edit-tag-modal-wrapper div.tagsinput input,
.advanced-search-wrapper div.tagsinput input,
.item-tags-wrapper div.tagsinput input,
.tags-wrapper div.tagsinput input,
.add-user-modal div.tagsinput input {
  padding: 4px 0;
  margin-bottom: 0;
  outline: none;
  border: none;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.recommendation-section-edit-rule div.tagsinput > div,
.edit-tag-modal-wrapper div.tagsinput > div,
.advanced-search-wrapper div.tagsinput > div,
.item-tags-wrapper div.tagsinput > div,
.tags-wrapper div.tagsinput > div,
.add-user-modal div.tagsinput > div {
  position: relative;
}
.recommendation-section-edit-rule ul.autocompleter-list,
.edit-tag-modal-wrapper ul.autocompleter-list,
.advanced-search-wrapper ul.autocompleter-list,
.item-tags-wrapper ul.autocompleter-list,
.tags-wrapper ul.autocompleter-list,
.add-user-modal ul.autocompleter-list {
  background-color: #fff;
}
.recommendation-section-edit-rule ul.autocompleter-list li.autocompleter-item,
.edit-tag-modal-wrapper ul.autocompleter-list li.autocompleter-item,
.advanced-search-wrapper ul.autocompleter-list li.autocompleter-item,
.item-tags-wrapper ul.autocompleter-list li.autocompleter-item,
.tags-wrapper ul.autocompleter-list li.autocompleter-item,
.add-user-modal ul.autocompleter-list li.autocompleter-item {
  border-bottom: none;
  white-space: nowrap;
}
.recommendation-section-edit-rule ul.autocompleter-list li.autocompleter-item:hover,
.edit-tag-modal-wrapper ul.autocompleter-list li.autocompleter-item:hover,
.advanced-search-wrapper ul.autocompleter-list li.autocompleter-item:hover,
.item-tags-wrapper ul.autocompleter-list li.autocompleter-item:hover,
.tags-wrapper ul.autocompleter-list li.autocompleter-item:hover,
.add-user-modal ul.autocompleter-list li.autocompleter-item:hover,
.recommendation-section-edit-rule ul.autocompleter-list li.autocompleter-item.autocompleter-item-selected,
.edit-tag-modal-wrapper ul.autocompleter-list li.autocompleter-item.autocompleter-item-selected,
.advanced-search-wrapper ul.autocompleter-list li.autocompleter-item.autocompleter-item-selected,
.item-tags-wrapper ul.autocompleter-list li.autocompleter-item.autocompleter-item-selected,
.tags-wrapper ul.autocompleter-list li.autocompleter-item.autocompleter-item-selected,
.add-user-modal ul.autocompleter-list li.autocompleter-item.autocompleter-item-selected {
  background-color: #eeeded;
  border-color: #eeeded;
  border-bottom: none;
}
.recommendation-section-edit-rule div.tagsinput span.tag,
.edit-tag-modal-wrapper div.tagsinput span.tag,
.advanced-search-wrapper div.tagsinput span.tag,
.item-tags-wrapper div.tagsinput span.tag,
.tags-wrapper div.tagsinput span.tag,
.add-user-modal div.tagsinput span.tag {
  background: #fff;
  color: #555;
  border: 1px solid #a7a9ab;
  font-size: 13px;
  font-family: Roboto, sans-serif;
  padding: 1px 5px;
  margin: 2px 5px 2px 0;
}
.recommendation-section-edit-rule div.tagsinput span.tag span,
.edit-tag-modal-wrapper div.tagsinput span.tag span,
.advanced-search-wrapper div.tagsinput span.tag span,
.item-tags-wrapper div.tagsinput span.tag span,
.tags-wrapper div.tagsinput span.tag span,
.add-user-modal div.tagsinput span.tag span {
  word-break: break-all;
}
.recommendation-section-edit-rule div.tagsinput span.tag a,
.edit-tag-modal-wrapper div.tagsinput span.tag a,
.advanced-search-wrapper div.tagsinput span.tag a,
.item-tags-wrapper div.tagsinput span.tag a,
.tags-wrapper div.tagsinput span.tag a,
.add-user-modal div.tagsinput span.tag a {
  position: relative;
  font-weight: 600;
  color: #808284;
  top: -1px;
  font-size: 12px;
}
.recommendation-section-edit-rule div.tagsinput span.tag a:hover,
.edit-tag-modal-wrapper div.tagsinput span.tag a:hover,
.advanced-search-wrapper div.tagsinput span.tag a:hover,
.item-tags-wrapper div.tagsinput span.tag a:hover,
.tags-wrapper div.tagsinput span.tag a:hover,
.add-user-modal div.tagsinput span.tag a:hover {
  color: #555;
}
.modal.model-has-tags .modal-body {
  overflow: visible;
}
div.tagsinput div.autocompleter-closed {
  display: none;
}
.filters-container {
  font-family: Roboto, sans-serif;
  min-width: 1000px;
  margin: 0 auto;
  display: table;
}
.filters-container .add-filter-container {
  margin-bottom: 20px;
}
.filters-container .add-filter-container .add-filter-btn {
  font-size: 13px;
}
.filters-container table.table.filter-table {
  margin-top: 20px;
}
.filters-container table.table.filter-table thead > tr > th.sorting_asc,
.filters-container table.table.filter-table thead > tr > th.sorting_desc {
  -webkit-filter: grayscale(100%);
  color: #48484a;
}
.filters-container table.table.filter-table thead > tr > th.sorting,
.filters-container table.table.filter-table thead > tr > th.sorting_disabled {
  color: #8c8f91;
}
.filters-container table.table.filter-table thead > tr > th:last-child {
  min-width: 160px;
}
.filters-container table.table.filter-table thead > tr > th.no-sort {
  background: transparent !important;
  cursor: default;
}
.filters-container table.table.filter-table tbody > tr:hover > td.filter-table-buttons > button {
  opacity: 1;
}
.filters-container table.table.filter-table td.filter-desc {
  text-overflow: ellipsis;
  overflow: hidden;
}
.filters-container table.table.filter-table td.filter-desc.disabled {
  cursor: not-allowed;
}
.filters-container table.table.filter-table td.filter-desc.disabled .edit-filter.name {
  pointer-events: none;
}
.filters-container table.table.filter-table td a.edit-filter {
  color: #333;
}
.filters-container table.table.filter-table td a.edit-filter.name {
  font-weight: bold;
}
.filters-container table.table.filter-table td a.edit-filter:hover {
  text-decoration: underline;
}
.filters-container table.table.filter-table td.filter-table-buttons {
  text-align: right;
  white-space: nowrap;
}
.filters-container table.table.filter-table td.filter-table-buttons > button {
  opacity: 0;
}
.filters-container table.table.filter-table td.filter-table-buttons .halflings {
  color: #8c8f91;
}
.filters-container table.table.filter-table td.filter-table-buttons .disabled {
  cursor: not-allowed;
}
.filters-container table.table.filter-table td.filter-table-buttons .disabled .halflings {
  opacity: 0.65;
}
.filters-container table.table.filter-table td.text-center {
  padding-right: 40px;
}
.filters-container .empty-filter-container {
  margin: 0 0 40px 0;
}
.filters-container .empty-filter-container .no-filters {
  display: block;
  margin-top: 150px;
  font-size: 60px;
  color: lightgray;
}
.filters-container .empty-filter-container .no-filters-text {
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  color: lightgray;
}
.filter-edit-modal.disabled .edit-actions-container {
  cursor: not-allowed;
}
.filter-edit-modal.disabled .edit-actions-container span {
  pointer-events: none;
}
.filter-edit-modal.disabled .search-criteria-value,
.filter-edit-modal.disabled .action-value {
  cursor: not-allowed;
}
.filter-edit-modal.disabled .search-criteria-value .edit-link,
.filter-edit-modal.disabled .action-value .edit-link {
  pointer-events: none;
}
@media only screen and (max-width: 1300px) {
  .filters-container {
    min-width: 100%;
  }
}
.app-external-page #content-container {
  position: relative;
  /* fix the content div to handle fluid-height iframe */
}
.app-external-page #content-container #content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*Make sure the flash message doesn't push the iframe down*/
  /* Get the iframe to be 100% height */
}
.app-external-page #content-container #content #flash_message {
  position: relative;
  z-index: 1;
  max-height: 61px;
}
.app-external-page #content-container #content #flash_message ~ #app-external-page {
  position: relative;
  bottom: 61px;
  margin-bottom: -61px;
}
.app-external-page #content-container #content #app-external-page .content-container {
  padding: 0;
  position: relative;
}
.app-external-page #content-container #content #app-external-page iframe {
  border: none;
  position: absolute;
  width: 100%;
  height: 100%;
}
#s2id_connect-purpose-select .select2-choice {
  background: white;
  border-radius: 3px;
  border: 1px solid #ccc;
  height: 50px;
  padding-left: 15px;
  padding-top: 10px;
}
#s2id_connect-purpose-select .select2-arrow {
  background: white;
  border: none;
  padding-top: 10px;
}
.select2-container-active .select2-choice {
  border: 1px solid #5897fb !important;
}
#select2-drop .select2-highlighted {
  background: none;
  color: #b70150;
}
.select2-drop-active {
  border: 1px solid #5897fb !important;
}
.select2-results li {
  padding-left: 5px;
}
a.cke_path_item,
a.cke_path_item:link,
a.cke_path_item:visited,
a.cke_path_item:active {
  color: #ce0058;
}
table.table.api-dt,
table.api-dt {
  min-width: 800px;
  margin-top: 20px;
  font-family: Roboto, sans-serif;
}
table.table.api-dt thead > tr > th,
table.api-dt thead > tr > th {
  white-space: nowrap;
}
table.table.api-dt thead > tr > th.api-data-table-counts,
table.api-dt thead > tr > th.api-data-table-counts {
  text-align: right;
  padding-right: 20px;
}
table.table.api-dt thead > tr > th.sorting_asc,
table.api-dt thead > tr > th.sorting_asc,
table.table.api-dt thead > tr > th.sorting_desc,
table.api-dt thead > tr > th.sorting_desc {
  color: #48484a;
}
table.table.api-dt thead > tr > th.sorting,
table.api-dt thead > tr > th.sorting {
  color: #8c8f91;
}
table.table.api-dt thead > tr > th.sorting_disabled,
table.api-dt thead > tr > th.sorting_disabled {
  color: #8c8f91;
  cursor: default;
}
table.table.api-dt tbody > tr:hover > td,
table.api-dt tbody > tr:hover > td {
  background: #f9f9f9;
}
table.table.api-dt tbody > tr:hover > td.api-data-table-buttons,
table.api-dt tbody > tr:hover > td.api-data-table-buttons {
  opacity: 1;
}
table.table.api-dt tbody > tr td,
table.api-dt tbody > tr td {
  vertical-align: middle;
}
table.table.api-dt tbody > tr td.api-data-table-counts,
table.api-dt tbody > tr td.api-data-table-counts {
  text-align: right;
  padding-right: 20px;
}
table.table.api-dt tbody > tr td.api-data-table-counts .priority-text,
table.api-dt tbody > tr td.api-data-table-counts .priority-text {
  border: 1px solid #eee;
  padding: 1px 5px;
  background: #eee;
  border-radius: 2px;
  cursor: pointer;
}
table.table.api-dt tbody > tr td.api-data-table-counts .priority-text:hover,
table.api-dt tbody > tr td.api-data-table-counts .priority-text:hover {
  border: 1px solid #ccc;
}
table.table.api-dt tbody > tr td.api-data-table-buttons,
table.api-dt tbody > tr td.api-data-table-buttons {
  width: 185px;
  min-width: 185px;
  opacity: 0;
  text-align: right;
}
table.table.api-dt tbody > tr td.api-data-table-buttons.single-button,
table.api-dt tbody > tr td.api-data-table-buttons.single-button {
  width: auto;
  opacity: 1;
  text-align: left;
}
table.table.api-dt tbody > tr td.api-data-table-buttons.single-button span,
table.api-dt tbody > tr td.api-data-table-buttons.single-button span {
  font-size: 16px;
}
table.table.api-dt tbody > tr td.api-data-table-buttons > a,
table.api-dt tbody > tr td.api-data-table-buttons > a {
  margin: 0 2px;
}
table.table.api-dt tbody > tr td.api-data-table-buttons .btn,
table.api-dt tbody > tr td.api-data-table-buttons .btn {
  display: inline;
}
table.table.api-dt tbody > tr td.api-data-table-buttons .halflings,
table.api-dt tbody > tr td.api-data-table-buttons .halflings,
table.table.api-dt tbody > tr td.api-data-table-buttons .glyphicons,
table.api-dt tbody > tr td.api-data-table-buttons .glyphicons {
  color: #8c8f91;
  vertical-align: middle;
  margin-top: -3px;
}
table.table.api-dt tbody > tr td.api-data-table-buttons .halflings span,
table.api-dt tbody > tr td.api-data-table-buttons .halflings span,
table.table.api-dt tbody > tr td.api-data-table-buttons .glyphicons span,
table.api-dt tbody > tr td.api-data-table-buttons .glyphicons span {
  display: none;
}
table.table.api-dt tbody > tr td.api-data-table-buttons .halflings:before,
table.api-dt tbody > tr td.api-data-table-buttons .halflings:before,
table.table.api-dt tbody > tr td.api-data-table-buttons .glyphicons:before,
table.api-dt tbody > tr td.api-data-table-buttons .glyphicons:before {
  padding: 0;
}
table.table.api-dt tbody > tr td.api-dt-thumbnail,
table.api-dt tbody > tr td.api-dt-thumbnail {
  width: 100px;
  height: 40px;
  padding-right: 0;
  padding-left: 0;
}
table.table.api-dt tbody > tr td.api-dt-thumbnail div > .data-table-thumb,
table.api-dt tbody > tr td.api-dt-thumbnail div > .data-table-thumb {
  width: 84px;
  height: 40px;
  vertical-align: middle;
  background-size: cover;
  background-position: center center;
  display: inline-block;
}
table.table.api-dt tbody > tr td.api-dt-thumbnail div.thumbnail-hidden,
table.api-dt tbody > tr td.api-dt-thumbnail div.thumbnail-hidden {
  background-color: rgba(51, 51, 51, 0.5);
  position: relative;
}
table.table.api-dt tbody > tr td.api-dt-thumbnail div.thumbnail-hidden > .data-table-thumb,
table.api-dt tbody > tr td.api-dt-thumbnail div.thumbnail-hidden > .data-table-thumb {
  opacity: 0.5;
}
table.table.api-dt tbody > tr td.api-dt-thumbnail div.thumbnail-hidden:before,
table.api-dt tbody > tr td.api-dt-thumbnail div.thumbnail-hidden:before {
  content: "Hidden";
  background: white;
  padding: 0 3px;
  position: absolute;
  right: 0;
  z-index: 1;
  font-size: 10px;
  font-weight: 700;
  border: 1px solid #eeeeee;
  color: #555;
  line-height: 13px;
  margin: -1px;
}
table.table.api-dt tbody > tr td.api-data-table-avatar,
table.api-dt tbody > tr td.api-data-table-avatar {
  width: 50px;
}
table.table.api-dt tbody > tr td.api-data-table-avatar .l-padding-25,
table.api-dt tbody > tr td.api-data-table-avatar .l-padding-25 {
  padding-left: 25px;
}
table.table.api-dt tbody > tr td.api-data-table-avatar .user-avatar-tiny + div,
table.api-dt tbody > tr td.api-data-table-avatar .user-avatar-tiny + div {
  line-height: 20px;
}
table.table.api-dt tbody > tr td a,
table.api-dt tbody > tr td a {
  color: #000;
  font-weight: bold;
  text-decoration: none;
}
table.table.api-dt tbody > tr td.api-data-table-link a,
table.api-dt tbody > tr td.api-data-table-link a {
  border-bottom: none;
}
table.table.api-dt tbody > tr td.api-data-table-link a:hover,
table.api-dt tbody > tr td.api-data-table-link a:hover {
  border-bottom: 1px solid #888;
}
table.table.api-dt tbody > tr td.api-data-table-date,
table.api-dt tbody > tr td.api-data-table-date {
  width: 150px;
  white-space: nowrap;
}
table.table.api-dt tbody > tr td.inline-edit-text input,
table.api-dt tbody > tr td.inline-edit-text input {
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: right;
  padding: 2px 0;
}
table.table.api-dt tbody > tr td.inline-edit-text input:focus,
table.api-dt tbody > tr td.inline-edit-text input:focus {
  background: #fff;
}
.generic-api-dt-util-bar {
  width: 100%;
  display: table;
  list-style: none;
  background-color: #eeeeee;
  border: 1px solid #cccccc;
  padding: 0;
}
.generic-api-dt-util-bar > li:first-child {
  padding: 11px 14px;
  vertical-align: middle;
}
.generic-api-dt-util-bar > li:first-child.add-border {
  border-right: 1px solid #cccccc;
}
.generic-api-dt-util-bar > li {
  display: table-cell;
  padding: 6px 18px;
  vertical-align: middle;
}
.generic-api-dt-util-bar > li a.nav-action {
  color: black;
  white-space: nowrap;
  opacity: 0.5;
}
.generic-api-dt-util-bar > li a.nav-action:hover {
  color: #2f96b4;
}
.generic-api-dt-util-bar > li .nav-action.disabled {
  color: grey;
  white-space: nowrap;
  opacity: 0.5;
}
.api-dt-util-bar {
  min-width: 800px;
}
.dataTables_controls {
  margin-top: 20px;
}
.dataTables_controls label {
  font-size: 12.5px;
}
.api-table-search {
  position: relative;
  width: 100%;
  padding: 6px 15px;
}
.api-table-search .halflings {
  position: absolute;
  top: 8px;
  left: 12px;
  right: auto;
  bottom: auto;
  opacity: 0.5;
}
.api-table-search input.main-search-input {
  width: 100%;
  height: 29px;
  font-size: 13px;
  text-indent: 26px;
}
.api-table-search input.main-search-input:focus {
  outline: 0;
}
.api-table-search input::-ms-clear {
  display: none;
}
.api-table-search .search-form-wrapper {
  position: relative;
}
.api-dt-toggle {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 30px;
  margin: 0;
  vertical-align: middle;
  /* Hide default HTML checkbox */
  /* The slider */
}
.api-dt-toggle input {
  display: none;
}
.api-dt-toggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}
.api-dt-toggle .slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
.api-dt-toggle input:checked + .slider {
  background-color: #7db33e;
}
.api-dt-toggle input:focus + .slider {
  box-shadow: 0 0 8px #5bc0de;
}
.api-dt-toggle input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}
#user-activity-table tbody tr td {
  vertical-align: top;
}
#user-activity-table tbody tr td.activity-message a {
  color: #000;
  font-weight: bold;
  border-bottom: 1px dotted #ccc;
  text-decoration: none;
}
#user-activity-table tbody tr td.activity-message a:hover {
  border-bottom: 1px solid #888;
}
#user-activity-table tbody tr td:not(:first-of-type) {
  padding-top: 13px;
}
#user-activity-table tbody tr td.truncate {
  max-width: 175px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#user-activity-table tbody tr td div.note {
  font-size: 0.9em;
  line-height: 1.7em;
}
#user-activity-table tbody tr td div.note > span.edit-from,
#user-activity-table tbody tr td div.note > span.edit-to {
  padding: 2px;
  margin: 0 4px 0 0;
}
#user-activity-table tbody tr td div.note > span.edit-from {
  background-color: #fff3f6;
}
#user-activity-table tbody tr td div.note > span.edit-to {
  background-color: #f1ffee;
}
#user-activity-table tbody tr td div.note:hover {
  background-color: transparent;
}
.hubs_manage_cta {
  z-index: 0;
}
.lock-item {
  z-index: 8;
}
.cta-form-field-container {
  z-index: 10;
}
.run-away {
  z-index: 11 !important;
}
.item-cta-bg {
  z-index: 12;
}
.overlay-message {
  z-index: 1000;
}
.hubs-preview-overlay {
  z-index: 30000;
}
.hubs-preview-wrapper {
  z-index: 30010;
}
.hubs-preview-command-bar {
  z-index: 30020;
}
.select2-drop.select2-with-searchbox {
  z-index: 999999;
}
.mgmt-container .content-container .custom-script-wrapper .custom-script-container.ace-editor-full-screen {
  z-index: 10000;
}
.item-selector-item-container .item-group.item-hidden:before {
  z-index: 1;
}
.collection-placeholder .tile {
  z-index: 0;
}
.collection-preview-bg {
  z-index: 7;
}
.item-group .knob-container {
  z-index: 25;
}
.item-group .knob-container .knob-overlay {
  z-index: -1;
}
.item-group.item-hidden:before {
  z-index: 1;
}
.item-group .collection-item-controls .more-item-options ul {
  z-index: 24;
}
.item-group.with-options .item-action-controls {
  z-index: 26;
}
.item-group.with-options:hover .overlay-options {
  z-index: 24;
}
.item-group.with-options:hover .overlay-options.holding-options .option-box .option-background {
  z-index: -1;
}
.item-preview-bg {
  z-index: 7;
}
.performing-item-container .performing-item .item-wrapper .content-score {
  z-index: 7;
}
.performing-item-container .performing-item .item-wrapper:hover .content-score {
  z-index: -1;
}
.author-btn-wrapper {
  display: inline-block;
}
.layout-bs3 .new-cta-title-input {
  color: #555;
  width: 450px;
  height: 36px;
  font-size: 1.5em;
  padding-left: 15px;
  margin: 0 auto;
}
.page-width.item-level .blocking-cta,
#collection-items.blocking-cta {
  filter: alpha(opacity=2000);
  opacity: 20;
  filter: blur(4px);
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: progid:DXImageTransform.Microsoft.blur(pixelradius=3);
}
html.mobile .page-width.item-level .blocking-cta,
html.mobile #collection-items.blocking-cta {
  -webkit-filter: none;
  -moz-filter: none;
  -o-filter: none;
  -ms-filter: none;
  filter: none;
}
.prevent-interaction {
  display: none;
}
.blocking-cta.prevent-interaction {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  position: absolute;
  overflow: hidden;
  -o-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.cta-item-container {
  float: left;
  width: auto;
  padding-top: 0px;
  padding-left: 20px;
}
.cta-item-container .cta {
  position: fixed !important;
}
.cta {
  margin-bottom: 20px;
}
.cta .submission-loader-container {
  display: inline;
  position: absolute;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
}
.cta .submission-loader-container .loader-background {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  width: 52px;
  height: 52px;
  margin: -26px 0 0 -26px;
  background-color: #1a3d18;
  border-radius: 25px;
  filter: alpha(opacity=5000);
  opacity: 50;
}
.cta .submission-loader-container .backdrop {
  position: absolute;
  top: 0;
  left: auto;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  filter: alpha(opacity=4000);
  opacity: 40;
  background-color: #1a3d18;
}
.cta .submission-loader-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  width: 32px;
  height: 32px;
  margin: -16px 0 0 -16px;
}
.cta .submission-loader-container.hidden {
  display: none;
}
.cta.full-screen-cta {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 0;
  overflow: hidden;
  -o-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.cta.full-screen-cta .hidden-cta-fields {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  margin: auto;
  padding: 0;
  max-width: 400px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -o-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.cta.full-screen-cta .hidden-cta-fields .cta-field-section {
  height: auto;
  padding-top: 5px;
}
.cta.full-screen-cta .hidden-cta-fields .cta-field-section select {
  width: 96.5%;
}
.cta.full-screen-cta .hidden-cta-fields .cta-field-section.one-line {
  padding-top: 10px;
}
.cta.full-screen-cta .hidden-cta-fields .cta-button-container {
  width: 88%;
}
.cta.full-screen-cta .hidden-cta-fields .cta-button-container > input.cta-button {
  width: 96.5%;
}
.cta.full-screen-cta .run-away {
  position: absolute;
  top: 0;
  left: auto;
  right: auto;
  bottom: auto;
}
.cta.tile.single:before {
  background-color: inherit;
}
.cta:before {
  background-color: inherit;
  content: "";
  filter: alpha(opacity=10000);
  opacity: 100;
}
.cta .cta-sprite {
  background: url("../../../../html/src/img/static/hubs/hubs-cta-spritesheet.png") no-repeat;
  display: inline-block;
}
.cta .cta-sprite-check {
  background-position: 0px 0;
  width: 94px;
  height: 94px;
}
.cta .cta-sprite-exclaim {
  background-position: -98px 0;
  width: 94px;
  height: 94px;
}
.cta .cta-sprite-close {
  background-position: -29px -99px;
  width: 23px;
  height: 23px;
}
.cta .cta-sprite-email {
  background-position: -56px -99px;
  width: 24px;
  height: 24px;
}
.cta .cta-sprite-left-half-body {
  background-position: -84px -99px;
  width: 24px;
  height: 24px;
}
.cta .cta-sprite-right-half-body {
  background-position: -112px -99px;
  width: 24px;
  height: 24px;
}
.cta .cta-sprite-company {
  background-position: -140px -99px;
  width: 24px;
  height: 24px;
}
.cta .cta-sprite-small-exclaim {
  background-position: -2px -99px;
  width: 24px;
  height: 24px;
}
.cta .hide-preview-cancel {
  display: none;
  cursor: pointer;
  position: relative;
  top: 0;
  left: 225px;
  text-align: right;
  height: 25px;
  line-height: 25px;
}
.cta .hide-preview-cancel .cta-close {
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  text-align: center;
  width: 25px;
}
.cta .run-away {
  height: 74%;
  width: 100%;
  top: 0;
  left: 0;
  border-bottom: 1px solid;
  position: relative;
}
.cta .hidden-cta-fields {
  height: 95%;
  width: 100%;
  top: 75%;
  position: absolute;
  margin: auto;
  overflow: hidden;
  padding-top: 3%;
}
.cta .hidden-cta-fields input {
  margin-bottom: 14px;
}
.cta .hidden-cta-fields.fields-revealed {
  height: 92%;
}
.cta .hidden-cta-fields.fields-revealed .cta-field-section {
  padding-bottom: 0px;
}
.cta .hidden-cta-fields.fields-revealed .cta-field-section input {
  margin-bottom: 6px;
}
.cta .hidden-cta-fields.fields-revealed .cta-button-container input {
  margin-bottom: 0px;
}
.cta .hidden-cta-fields.fields-revealed .cta-field-section.one-line {
  padding-top: 10px;
}
.cta .hidden-cta-fields form {
  margin: 0 0 20px;
}
.cta .hidden-cta-fields .cta-field-section {
  height: 25%;
  position: relative;
  width: 88%;
  margin: 0 auto;
  padding-bottom: 15px;
}
.cta .hidden-cta-fields .cta-field-section.smaller .cta-field-name {
  font-size: 14px;
  padding-bottom: 0;
}
.cta .hidden-cta-fields .cta-field-section.smaller .cta-field-sprite-container.cta-front {
  top: 27px !important;
}
.cta .hidden-cta-fields .cta-field-section.smaller input {
  height: 19px;
  margin-bottom: 0px;
}
.cta .hidden-cta-fields .cta-field-section.smaller select {
  height: 27px !important;
  line-height: 32px !important;
  margin: 0px;
}
.cta .hidden-cta-fields .cta-field-section .cta-field-name {
  display: inline-block;
  zoom: 1;
  font-size: 16px;
  padding-bottom: 4px;
  color: #fff;
}
.cta .hidden-cta-fields .cta-field-section .cta-field-name.label-only-text {
  font-size: 11px;
  line-height: 1.5em;
}
.cta .hidden-cta-fields .cta-field-section .cta-field-name-optional {
  font-size: 80%;
  filter: alpha(opacity=7500);
  opacity: 75;
}
.cta .hidden-cta-fields .cta-field-section .cta-field-sprite-container {
  position: absolute;
  background-color: transparent;
  top: 36px;
  left: 8px;
}
.cta .hidden-cta-fields .cta-field-section .preview-form-field {
  display: inline-block;
  zoom: 1;
  height: 25px;
}
.cta .hidden-cta-fields .cta-field-section .preview-form-field::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.cta .hidden-cta-fields .cta-field-section .preview-form-field:-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.cta .hidden-cta-fields .cta-field-section .preview-form-field::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.cta .hidden-cta-fields .cta-field-section .preview-form-field:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.cta .hidden-cta-fields .cta-field-section .preview-form-field.error {
  border-color: #fff;
  background-color: #f5aaaa;
}
.cta .hidden-cta-fields .cta-field-section input {
  width: 100%;
}
.cta .hidden-cta-fields .cta-field-section select {
  width: 99%;
  height: 35px !important;
  line-height: 32px !important;
  margin-bottom: 6px;
}
.cta .hidden-cta-fields .cta-field-section.one-line {
  padding-top: 30px;
  padding-bottom: 10px;
}
.cta .hidden-cta-fields .cta-field-section.one-line .opt-in-required {
  display: none;
  float: left;
  background: #f5aaaa;
  border: 1px solid #fff;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  text-align: center;
  color: #000;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-right: 5px;
}
.cta .hidden-cta-fields .cta-field-section.one-line .opt-in-required.show-required-text {
  display: inline-block;
}
.cta .hidden-cta-fields .cta-field-section.one-line input {
  width: auto;
  margin: 0px 8px 0px 3px;
  vertical-align: top;
  height: auto;
  text-indent: auto;
  display: inline;
}
.cta .hidden-cta-fields .cta-field-section.one-line span {
  display: inline;
}
.cta .hidden-cta-fields .cta-button-container {
  height: 22%;
  padding-top: 14px;
  width: 88%;
  margin: 0 auto;
}
.cta .hidden-cta-fields .cta-activate-button-container {
  padding-top: 0;
  width: 100%;
}
.cta .cta-form-response {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.cta .cta-form-response.cta-form-error {
  background-color: white;
}
.cta .cta-form-response .cta-form-message-container {
  width: 75%;
  margin: 0 auto;
  margin-top: 28%;
  text-align: center;
}
.cta .cta-form-response .cta-form-message-container .cta-form-message-sprite {
  display: block;
  margin: 0 auto;
}
.cta .cta-form-response .cta-form-message-container .cta-form-message {
  padding-top: 30px;
}
.cta .cta-form-response .cta-form-message-container .cta-form-message span {
  font-size: 22px;
}
.cta .cta-form-response .cta-button-container {
  width: 90%;
  margin-left: 5%;
  position: absolute;
  bottom: 15px;
}
.cta .cta-button {
  font-family: Droid Sans, sans-serif;
  line-height: 40px;
  height: 40px;
  display: block;
  font-size: 17px;
  text-align: center;
  width: 100%;
  border: 0;
}
.cta .cta-button:hover {
  -webkit-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
}
.cta > img {
  width: 100%;
  position: absolute;
}
.cta p {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  padding: 20px;
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  line-height: normal;
}
.cta > a,
.cta.tile.single > a {
  position: absolute;
  top: auto;
  left: 0;
  bottom: 0;
  width: 68%;
  height: 1em;
  margin: 20px 8.5%;
  text-align: center;
  box-sizing: content-box;
}
.cta > a.accent-button {
  background-color: #a8a8a8;
  color: #fff;
  display: block;
  font-size: 20px;
  line-height: 1;
  margin-top: 20px;
  padding: 20px;
  text-decoration: none;
}
.cta > a.accent-button:hover {
  color: #fff;
}
.cta .ps-container:not(:hover) .ps-scrollbar-y-rail {
  filter: alpha(opacity=3000);
  opacity: 30;
}
.cta .ps-container .ps-scrollbar-y-rail,
.cta .ps-container .ps-scrollbar-y-rail.hover {
  background: transparent;
}
.cta .ps-container .ps-scrollbar-y-rail .ps-scrollbar-y,
.cta .ps-container .ps-scrollbar-y-rail.hover .ps-scrollbar-y {
  background-color: #fff;
}
.cta.setValues {
  background-image: none !important;
}
.cta.setValues > .run-away {
  top: -300px;
}
.cta.setValues > .fields-revealed {
  top: 25px;
}
.cta.setValues > .hide-preview-cancel {
  display: block;
}
.cta .accent-button {
  text-decoration: none;
}
.cta ::-webkit-scrollbar {
  width: 16px !important;
  padding-right: 1px !important;
}
.cta ::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3) !important;
  border-radius: 40px !important;
  border: 4px solid rgba(0, 0, 0, 0) !important;
  background-clip: padding-box !important;
}
.cta ::-webkit-scrollbar-thumb:hover {
  background: #ffffff !important;
  border-radius: 40px !important;
  border: 4px solid rgba(0, 0, 0, 0) !important;
  background-clip: padding-box !important;
}
.cta ::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
